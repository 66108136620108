import { ActionConstants } from "constants";
import {
  PROPERTY_LOADING,
  PROPERTY_GET_ALL_SUCCESS,
  PROPERTY_ERROR,
  JOB_LOADING,
  JOB_GET_ALL_SUCCESS,
  JOB_ERROR,
  TASK_LOADING,
  TASK_GET_ALL_SUCCESS,
  TASK_ERROR,
  PROJECT_ADD_WARRANTY,
  PROJECT_ADD_WARRANTY_SUCCESS,
  PROJECT_ADD_WARRANTY_ERROR,
  PROJECT_ADD_ADS,
  PROJECT_ADD_ADS_ERROR,
  PROJECT_ADD_ADS_SUCCESS,
  PROJECT_GET_ADS_LOADING,
  PROJECT_GET_ADS,
  PROJECT_ADS_STATUS_ERROR,
  SETTINGS_ADD_PROJECT_STATUS,
  SETTINGS_ADD_JOB_STATUS,
  SETTINGS_ADD_SUBSCRIPTION,
  SETTINGS_ADD_JOBTYPE,
  SETTINGS_GET_PROJECT_STATUS_LOADING,
  SETTINGS_GET_JOBTYPE_LOADING,
  SETTINGS_GET_JOB_STATUS_LOADING,
  SETTINGS_GET_SUBSCRIPTION_LOADING,
  SETTINGS_ADD_PROJECT_STATUS_SUCCESS,
  SETTINGS_ADD_JOB_STATUS_SUCCESS,
  SETTINGS_ADD_SUBSCRIPTION_SUCCESS,
  SETTINGS_ADD_JOBTYPE_SUCCESS,
  SETTINGS_GET_PROJECT_STATUS,
  SETTINGS_GET_JOB_STATUS,
  SETTINGS_GET_SUBSCRIPTION,
  SETTINGS_GET_JOBTYPE,
  SETTINGS_ADD_JOBTYPE_ERROR,
  SETTINGS_ADD_PROJECT_STATUS_ERROR,
  SETTINGS_ADD_JOB_STATUS_ERROR,
  SETTINGS_ADD_SUBSCRIPTION_ERROR,
} from "constants/types";

const initialState = {
  loading: false,
  loginError: { status: "", message: "" },
  userDetails: { role: "" },
  userCompanyDetails: {},
  dashboardData: {},
  appContext: {},
  appConfig: {
    configId: 0,
    dateFormat: "stringDate",
    prodUrlUi: "string1",
    devUrlUi: "string2",
    prodUrlAppl: "string3",
    devUrlAppl: "string4",
    startOfWeek: "string5",
    voiceAssistantEnabled: true,
    createdBy: "",
    createdDate: "stringDate",
    modifiedBy: null,
    modifiedDate: null,
  },
  voiceAssistantConfig: {
    commands: [],
    questions: [],
  },
  voiceAssistantMicrophoneAccess: false,
  globalSearchResults: {},
  fetchingGlobalSearch: false,
  status: "",
  error: "",
  emailFromGuid: "",
  alertNotifications: [],
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionConstants.REGISTRATION:
    case ActionConstants.SIGNUP_EMAIL:
    case ActionConstants.BUSINESS_PROFILE:
    case ActionConstants.ADD_NEW_PROPERTY:
    case ActionConstants.ADD_NEW_JOB:
    case ActionConstants.GET_COMPANIES:
    case ActionConstants.ADD_NEW_PROJECT:
    case ActionConstants.GET_PROJECTS:
    case ActionConstants.ADD_NEW_TASK:
    case ActionConstants.GET_PROJECT_BY_ID:
    case ActionConstants.GET_DASHBOARD_DATA:
    case ActionConstants.GET_USERS:
    case ActionConstants.DELETE_PROJECT_BY_ID:
    case ActionConstants.GET_PROJECT_USERS_ROLES:
    case TASK_LOADING:
    case JOB_LOADING:
    case PROPERTY_LOADING:
    case ActionConstants.LOADING_APP:
    case ActionConstants.PROPERTY_BY_ID_LOADING:
    case ActionConstants.GET_GUID_INVITE_DETAILS:
    case ActionConstants.GET_PROJECT_OVERVIEW:
    case ActionConstants.GET_PROJECT_STATUS:
    case ActionConstants.GET_JOB_STATUS:
    case ActionConstants.GET_JOB_TYPE:
    case ActionConstants.PREVIEW_FILE_REQUEST:
    case ActionConstants.DOWNLOAD_FILE_REQUEST:
    case ActionConstants.GET_TASK_STATUS:
    case ActionConstants.SEND_RESET_PASSWORD_EMAIL:
    case ActionConstants.GET_TEMPLATES_REQUEST:
    case ActionConstants.GET_TEMPLATE_FIELDS_REQUEST:
    case ActionConstants.GET_MARKET_SECTORS:
    case PROJECT_ADD_WARRANTY:
    case PROJECT_ADD_ADS:
    case PROJECT_GET_ADS_LOADING:
    case SETTINGS_ADD_PROJECT_STATUS:
    case SETTINGS_GET_PROJECT_STATUS_LOADING:
    case SETTINGS_ADD_JOB_STATUS:
    case SETTINGS_GET_JOB_STATUS_LOADING:
    case SETTINGS_ADD_JOBTYPE:
    case SETTINGS_GET_JOBTYPE_LOADING:
    case SETTINGS_ADD_SUBSCRIPTION:
    case SETTINGS_GET_SUBSCRIPTION_LOADING:
    case ActionConstants.APP_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case ActionConstants.REGISTRATION_SUCCESS:
    case ActionConstants.SIGNUP_EMAIL_SUCCESS:
    case ActionConstants.ADD_NEW_PROPERTY_SUCCESS:
    case ActionConstants.ADD_NEW_JOB_SUCCESS:
    case ActionConstants.GET_CONNECTIONS_SUCCESS:
    case ActionConstants.ADD_NEW_PROJECT_SUCCESS:
    case ActionConstants.GET_PROJECTS_SUCCESS:
    case ActionConstants.ADD_NEW_TASK_SUCCESS:
    case ActionConstants.GET_PROJECT_BY_ID_SUCCESS:
    case ActionConstants.GET_USERS_SUCCESS:
    case ActionConstants.DELETE_PROJECT_BY_ID_SUCCESS:
    case ActionConstants.GET_PROJECT_USERS_ROLES_SUCCESS:
    case PROPERTY_GET_ALL_SUCCESS:
    case JOB_GET_ALL_SUCCESS:
    case TASK_GET_ALL_SUCCESS:
    case ActionConstants.LOADING_APP_SUCCESS:
    case ActionConstants.PROPERTY_BY_ID_SUCCESS:
    case ActionConstants.GET_GUID_INVITE_DETAILS_SUCCESS:
    case ActionConstants.GET_PROJECT_OVERVIEW_SUCCESS:
    case ActionConstants.GET_PROJECT_STATUS_SUCCESS:
    case ActionConstants.GET_JOB_STATUS_SUCCESS:
    case ActionConstants.GET_JOB_TYPE_SUCCESS:
    case ActionConstants.PREVIEW_FILE_SUCCESS:
    case ActionConstants.DOWNLOAD_FILE_SUCCESS:
    case ActionConstants.GET_TASK_STATUS_SUCCESS:
    case ActionConstants.SEND_RESET_PASSWORD_EMAIL_SUCCESS:
    case ActionConstants.GET_TEMPLATES_SUCCESS:
    case ActionConstants.GET_TEMPLATE_FIELDS_SUCCESS:
    case ActionConstants.GET_MARKET_SECTORS_SUCCESS:
    case ActionConstants.GET_COMPANIES_SUCCESS:
    case PROJECT_ADD_WARRANTY_SUCCESS:
    case PROJECT_ADD_ADS_SUCCESS:
    case PROJECT_GET_ADS:
    case SETTINGS_ADD_PROJECT_STATUS_SUCCESS:
    case SETTINGS_GET_PROJECT_STATUS:
    case SETTINGS_ADD_JOB_STATUS_SUCCESS:
    case SETTINGS_GET_JOB_STATUS:
    case SETTINGS_ADD_SUBSCRIPTION_SUCCESS:
    case SETTINGS_GET_SUBSCRIPTION:
    case SETTINGS_ADD_JOBTYPE_SUCCESS:
    case SETTINGS_GET_JOBTYPE:
    case ActionConstants.APP_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    case ActionConstants.REGISTRATION_ERROR:
    case ActionConstants.SIGNUP_EMAIL_ERROR:
    case ActionConstants.ADD_NEW_PROPERTY_ERROR:
    case ActionConstants.ADD_NEW_JOB_ERROR:
    case ActionConstants.GET_CONNECTIONS_ERROR:
    case ActionConstants.ADD_NEW_PROJECT_ERROR:
    case ActionConstants.GET_PROJECTS_ERROR:
    case ActionConstants.ADD_NEW_TASK_ERROR:
    case ActionConstants.GET_PROJECT_BY_ID_ERROR:
    case ActionConstants.GET_USERS_ERROR:
    case ActionConstants.DELETE_PROJECT_BY_ID_ERROR:
    case ActionConstants.GET_PROJECT_USERS_ROLES_ERROR:
    case ActionConstants.LOGIN_EXTERNAL:
    case TASK_ERROR:
    case PROPERTY_ERROR:
    case JOB_ERROR:
    case ActionConstants.LOADING_APP_ERROR:
    case ActionConstants.PROPERTY_BY_ID_ERROR:
    case ActionConstants.GET_GUID_INVITE_DETAILS_ERROR:
    case ActionConstants.GET_PROJECT_OVERVIEW_ERROR:
    case ActionConstants.GET_PROJECT_STATUS_ERROR:
    case ActionConstants.GET_JOB_STATUS_ERROR:
    case ActionConstants.GET_JOB_TYPE_ERROR:
    case ActionConstants.PREVIEW_FILE_FAILURE:
    case ActionConstants.DOWNLOAD_FILE_FAILURE:
    case ActionConstants.GET_TASK_STATUS_ERROR:
    case ActionConstants.SEND_RESET_PASSWORD_EMAIL_ERROR:
    case ActionConstants.GET_TEMPLATES_ERROR:
    case ActionConstants.GET_TEMPLATE_FIELDS_ERROR:
    case ActionConstants.GET_MARKET_SECTORS_ERROR:
    case PROJECT_ADD_WARRANTY_ERROR:
    case PROJECT_ADD_ADS_ERROR:
    case PROJECT_ADS_STATUS_ERROR:
    case SETTINGS_ADD_JOBTYPE_ERROR:
    case SETTINGS_ADD_PROJECT_STATUS_ERROR:
    case SETTINGS_ADD_JOB_STATUS_ERROR:
    case SETTINGS_ADD_SUBSCRIPTION_ERROR:
    case ActionConstants.GET_COMPANIES_ERROR:
      return {
        ...state,
        loading: false,
        status: action?.payload?.status,
        error: action?.payload?.message,
      };
    case ActionConstants.LOGIN:
      return {
        ...state,
        loading: true,
      };
    case ActionConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ActionConstants.LOGIN_EXTERNAL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ActionConstants.LOGIN_EXTERNAL_ERROR:
    case ActionConstants.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        loginError: action.payload,
      };
    case ActionConstants.USER_DETAILS:
    case ActionConstants.PAYMENT:
      return {
        ...state,
        loading: true,
      };
    case ActionConstants.USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: action.payload,
      };
    case ActionConstants.USER_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        userDetails: action.payload,
        error: action.payload,
      };
    case ActionConstants.PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentDetails: action.payload,
      };
    case ActionConstants.PAYMENT_ERROR:
      return {
        ...state,
        loading: false,
        paymentDetails: action.payload,
      };
    case ActionConstants.GET_ROLE:
      return {
        ...state,
        loading: true,
      };
    case ActionConstants.GET_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: {
          ...state.userDetails,
          role: action.payload,
        },
      };
    case ActionConstants.GET_ROLE_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case ActionConstants.BUSINESS_PROFILE_SUCCESS:
      return {
        ...state,
        businessProfile: action.payload,
        loading: false,
      };
    case ActionConstants.BUSINESS_PROFILE_ERROR:
      return {
        ...state,
        businessProfile: action.payload,
        loading: false,
      };
    case ActionConstants.GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        dashboardData: action.payload,
        loading: false,
      };
    case ActionConstants.GET_DASHBOARD_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ActionConstants.GLOBAL_PROJECT_CONTENT: {
      return {
        ...state,
        appContext: {
          selectedProject: action.payload.projectId,
        },
      };
    }
    case ActionConstants.GET_GLOBAL_SEARCH:
      return {
        ...state,
        fetchingGlobalSearch: true,
      };
    case ActionConstants.GET_GLOBAL_SEARCH_SUCCESS:
      return {
        ...state,
        globalSearchResults: action.payload,
        fetchingGlobalSearch: false,
      };
    case ActionConstants.GET_GLOBAL_SEARCH_ERROR:
      return {
        ...state,
        fetchingGlobalSearch: false,
      };
    case ActionConstants.RECEIVE_ALERT_NOTIFICATIONS:
      return {
        ...state,
        alertNotifications: [action.payload, ...state.alertNotifications],
      };
    case ActionConstants.FETCH_ALERT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        alertNotifications: action.payload.items,
        alertsCount: action.payload.total,
      };
    case ActionConstants.GET_GUID_DETAILS: {
      return {
        ...state,
        loading: true,
        emailFromGuid: "",
      };
    }
    case ActionConstants.GET_GUID_DETAILS_SUCCESS: {
      return {
        ...state,
        emailFromGuid: action.payload,
        loading: false,
      };
    }
    case ActionConstants.GET_GUID_DETAILS_ERROR: {
      return {
        ...state,
        emailFromGuid: "",
        guidError: action.payload.error,
        loading: false,
      };
    }
    case ActionConstants.APP_CONFIG: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionConstants.APP_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        appConfig: {
          ...action.payload,
        },
      };
    }
    case ActionConstants.APP_CONFIG_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case ActionConstants.USER_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        userCompanyDetails: action.payload,
      };
    case ActionConstants.SHOW_APP_API_ALERT:
      return {
        ...state,
        apiAlert: action.payload,
      };
    case ActionConstants.GET_BUILDER_AI_COMMANDS_DATA: {
      return {
        ...state,
        loading: true,
        voiceAssistantConfig: {
          ...state.voiceAssistantConfig,
          isFetchingQuestions: true,
        },
      };
    }
    case ActionConstants.GET_BUILDER_AI_COMMANDS_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        voiceAssistantConfig: {
          ...state.voiceAssistantConfig,
          isFetchingQuestions: false,
          commands: action.payload,
        },
      };
    }
    case ActionConstants.GET_BUILDER_AI_COMMANDS_DATA_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case ActionConstants.GET_BUILDER_AI_MICROPHONE_ACCESS: {
      return {
        ...state,
        loading: false,
        voiceAssistantMicrophoneAccess: action.payload,
      };
    }
    case ActionConstants.GET_BUILDER_AI_COMMANDS_QUESTIONS_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        voiceAssistantConfig: {
          ...state.voiceAssistantConfig,
          isFetchingQuestions: false,
          questions: action.payload,
        },
      };
    }
    case ActionConstants.SET_BUILDER_AI_COMMANDS_QUESTIONS_INITIAL_DATA: {
      return {
        ...state,
        loading: false,
        voiceAssistantConfig: {
          ...state.voiceAssistantConfig,
          questions: [],
        },
      };
    }

    default:
      return state;
  }
};

export default appReducer;
