import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.connectionStatus;

export const getConnectionStatus = createSelector(selectState, (connectionStatus) => ({
  statusName: get(connectionStatus, "statusName", []),
  totalCount: get(connectionStatus, "totalCount", 0),
  loading: get(connectionStatus, "loading", false),
  showSnackbar: get(connectionStatus, "showSnackbar", false),
  status: get(connectionStatus, "status", "success"),
  statusText: get(connectionStatus, "text", ""),
}));
