import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.taskStatus;

export const getTaskStatus = createSelector(selectState, (taskStatus) => ({
  statusName: get(taskStatus, "statusName", []),
  totalCount: get(taskStatus, "totalCount", 0),
  loading: get(taskStatus, "loading", false),
  showSnackbar: get(taskStatus, "showSnackbar", false),
  status: get(taskStatus, "status", "success"),
  statusText: get(taskStatus, "text", ""),
}));
