import axios from "axios";
import {
  SETTINGS_DOCUMENT_TYPE_ERROR,
  SETTINGS_DOCUMENT_TYPE_CLEAR_SNACKBAR,
  SETTINGS_DELETE_DOCUMENT_TYPE_SUCCESS,
  SETTINGS_GET_DOCUMENT_TYPE_LOADING,
  SETTINGS_ADD_DOCUMENT_TYPE_ERROR,
  SETTINGS_ADD_DOCUMENT_TYPE_SUCCESS,
  SETTINGS_ADD_DOCUMENT_TYPE,
  SETTINGS_GET_DOCUMENT_TYPE,
  SETTINGS_COMPANY_TYPE_CLEAR_SNACKBAR,
  SETTINGS_COMPANY_TYPE_ERROR,
  SETTINGS_DELETE_COMPANY_TYPE_SUCCESS,
  SETTINGS_GET_COMPANY_TYPE_LOADING,
  SETTINGS_ADD_COMPANY_TYPE_ERROR,
  SETTINGS_ADD_COMPANY_TYPE_SUCCESS,
  SETTINGS_ADD_COMPANY_TYPE,
  SETTINGS_GET_COMPANY_TYPE,
} from "../constants/types";
import queryString from "query-string";

import { server_url } from "../constants/types";
import request from "config/api";

export const fetchCompanyType = (params = {}) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: SETTINGS_GET_COMPANY_TYPE_LOADING,
        payload: {
          loading: true,
        },
      });
      const newParams = {
        PageNumber: params.page ? params.page + 1 : 1,
        PageSize: params.pageSize ? params.pageSize : 10,
      };
      const queryParams = queryString.stringify(newParams);

      request({
        url: `${server_url}/companyTypes`,
        method: "GET",
      })
        .then((response) => {
          dispatch({
            type: SETTINGS_GET_COMPANY_TYPE,
            payload: {
              companyType: response.data,
              totalCount: response.data.length,
              loading: false,
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: SETTINGS_COMPANY_TYPE_ERROR,
            payload: {
              status: "error",
              text: "Error occured during getting all CompanyType.",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};

export const addUpdateCompanyType = ({ formData, onSuccess, payload }) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: SETTINGS_ADD_COMPANY_TYPE,
        payload: {
          loading: true,
        },
      });

      let data = {
        ...formData,
      };

      request({
        method: formData.companyTypeId ? "PUT" : "POST",
        url: `${server_url}/companyTypes${
          formData.companyTypeId ? `/${formData.companyTypeId}` : ""
        }`,
        body: { ...data },
      })
        .then(async (response) => {
          onSuccess();

          dispatch({
            type: SETTINGS_ADD_COMPANY_TYPE_SUCCESS,
            payload: {
              response: response.data,
              status: "success",
              text: formData.companyTypeId
                ? "CompanyType updated Successfully"
                : "CompanyType Created Successfully",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: SETTINGS_ADD_COMPANY_TYPE_ERROR,
            payload: {
              text: "Error occurred during creating companyType data.",
              status: "error",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};

export const companyTypeDelete = ({
  companyTypeId,
  pageModel,
  onSuccess = () => {},
  showSnackbar = true,
}) => {
  console.log("Check the companyTypeDelete Action", companyTypeId);
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: SETTINGS_GET_COMPANY_TYPE_LOADING,
        payload: {
          loading: true,
        },
      });
      request({
        url: `${server_url}/companyTypes/${companyTypeId}`,
        method: "DELETE",
      })
        .then((response) => {
          dispatch(fetchCompanyType({ ...pageModel }));
          onSuccess();

          dispatch({
            type: SETTINGS_DELETE_COMPANY_TYPE_SUCCESS,
            payload: {
              response: response.data,
              status: "success",
              text: "companyType Deleted Successfully",
              loading: false,
              showSnackbar: true,
            },
          });

          resolve();
        })
        .catch((e) => {
          dispatch({
            type: SETTINGS_COMPANY_TYPE_ERROR,
            payload: {
              text: "Error occurred during deleting companyType data.",
              status: "error",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};

export const companyTypeClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: SETTINGS_COMPANY_TYPE_CLEAR_SNACKBAR,
    });
  };
};
