import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IconButton,
  Typography,
  TextField,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Button } from "react-bootstrap";

import DataGridTable from "components/Table/DataGrid";

import { useParams } from "react-router-dom";
import get from "lodash/get";
import NoResultsFound from "components/NoResults/NoResultsFound";
import AppRoleContext from "context/AppRoleContext";
import HiddenOn from "components/Wrappers/HiddenOn";
import { hasAppRole, isJobManager, roleConfigKeys } from "core/utils/roleUtils";
import { ModelDialog } from "components/Model";
import { CloseOutlined } from "@mui/icons-material";
import JobTeamList from "pages/projects/tabComponents/jobs/JobTeamList";
import {
  warrantyDelete,
  fetchWarranties,
  warrantyClearShowSnackbar,
} from "actions/warrantyActions";
import AddNewAds from "./AddNewAds";
import { adsListColumns } from "./AdsUtils";
// import { getWarranties } from "selectors/warrantySelectors";
import { adsDelete, fetchAds } from "actions/adsActions.js";
import { getAds } from "selectors/adsSelectors";
import {
  PROJECT_ADS_STATUS_ERROR,
  PROJECT_DELETE_ADS_SUCCESS,
} from "constants/types";

export const Ads = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { ads, totalCount, loading, showSnackbar, status, statusText } =
    useSelector(getAds);

  const [addNewJob, setAddNewJob] = useState(false);

  const [pageModel, setPageModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [editJob, setEditJob] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [userManagement, setUserManagement] = useState(false);
  const [jobActionsContext, setJobActionsContext] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [makeDelete, setMakeDelete] = useState(false);

  const appRoleDetails = useContext(AppRoleContext);

  const promptDelete = () => {
    setShowConfirmModal(true);
  };

  useEffect(() => {
    setShowConfirmModal(false);
    if (makeDelete) {
      bulkDelete(selectedRows);
      setMakeDelete(false);
    }
  }, [makeDelete]);

  useEffect(() => {dispatch(fetchAds({...pageModel}));
  }, [dispatch, pageModel]);

  useEffect(() => {
    console.log("selected", selectedJob);
  }, [selectedJob]);

  const bulkDelete = async (selectedFiles) => {
    try {
      const results = await Promise.allSettled(
        selectedFiles.map((fileId) =>
          dispatch(adsDelete({ advertiseId: fileId, showSnackbar: false }))
        )
      );

      const failedDeletions = results.filter(
        (result) => result.status === "rejected"
      );
      setSelectedJob(null);

      if (failedDeletions.length > 0) {
        console.error("Failed to delete the following Ads:", failedDeletions);
        dispatch({
          type: PROJECT_ADS_STATUS_ERROR,
          payload: {
            response: null,
            status: "error",
            message: "Some Ads failed to delete",
            loading: false,
            showSnackbar: true,
          },
        });
      } else {
        dispatch({
          type: PROJECT_DELETE_ADS_SUCCESS,
          payload: {
            response: null,
            status: "success",
            message: "Ads Deleted Successfully",
            loading: false,
            showSnackbar: true,
          },
        });
      }

      await dispatch(
        fetchAds({
          ...pageModel,
        })
      );
    } catch (error) {
      console.error("Error during bulk delete:", error);
      showSnackbar("error", "Error deleting files");
    }
  };

  return (
    <div className="jobs-page">
      <Dialog
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        fullWidth={true}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure to delete selected records ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmModal(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => setMakeDelete(true)} variant="danger">
            Sure
          </Button>
        </DialogActions>
      </Dialog>
      {addNewJob ? (
        <AddNewAds
          onCancel={() => setAddNewJob(false)}
          selectedJob={selectedJob}
          title={get(selectedJob, "data.productName", null)}
          params={params}
          onSave={() => {
            dispatch(
              fetchAds({
                ...pageModel,
              })
            );
          }}
        />
      ) : (
        <>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={showSnackbar}
            autoHideDuration={6000}
            onClose={() => warrantyClearShowSnackbar()}
          >
            <Alert
              onClose={() => warrantyClearShowSnackbar()}
              severity={status}
            >
              {statusText}
            </Alert>
          </Snackbar>

          <div className="projects-header-section d-flex justify-content-between mt-3">
            <div>
              <Typography className="h1">Ads</Typography>
              {/* <BreadCrumb /> */}
            </div>
            <HiddenOn
              isHidden={!hasAppRole(roleConfigKeys.CREATE_JOB, appRoleDetails)}
            >
              {!addNewJob && (
                <div className="d-flex justify-content-end ">
                  <button
                    className="primaryButton addProjectButton"
                    style={{ width: "8rem" }}
                    onClick={() => {
                      setSelectedJob(null);
                      setAddNewJob(!addNewJob);
                    }}
                  >
                    {"+ Add New Item"}
                  </button>
                </div>
              )}
            </HiddenOn>
          </div>
          <div className="projects-content-section">
            {totalCount > 0 ? (
              <DataGridTable
                pagination={totalCount > 10}
                totalCount={totalCount}
                isLoading={loading}
                columns={adsListColumns({
                  setEditJob,
                  setAddNewJob,
                  setSelectedJob,
                  appRoleDetails,
                  onCompanyDelete: (advertiseId) => {
                    dispatch(adsDelete({ advertiseId, pageModel }));
                    setSelectedJob(null);
                  },
                  setUserManagement,
                  setJobActionsContext,
                })}
                data={ads}
                defineRowId={(row) => {
                  return row.advertiseId;
                }}
                paginationModel={pageModel}
                onPaginationChange={(props) => {
                  setPageModel(props);
                }}
                onSelect={(props) => {
                  setSelectedRows(props);
                }}
                selectedRows={selectedRows}
                onDeleteSelection={() => {
                  if (selectedRows.length > 0) {
                    promptDelete();
                  }
                }}
                onRowClick={(row) => {
                  setAddNewJob(!addNewJob);
                  setSelectedJob({ data: row.row });
                  //navigate(`/settings/companies/view/${row.id}`);
                }}
              />
            ) : (
              <NoResultsFound />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Ads;
