import React from "react";
import { isJobManager } from "core/utils/roleUtils";
import { MenuItem, Typography } from "@mui/material";
import { Delete, EditOutlined } from "@mui/icons-material";
import startCase from "lodash/startCase";
import StatusIndicator from "components/StatusIndicators/StatusIndicator_Circle";

import BurgerMenu from "components/Menu/BurgerMenu";

import HiddenOn from "components/Wrappers/HiddenOn";

import * as yup from "yup";
import { getText } from "core/utils/getContentText";
const getTextContent = (key) => getText(`taskStatus.addTaskStatus.${key}`);

const validationSchema = yup.object({
  statusName: yup
    .string("Enter status Name")
    .required("statusName is required"),
});

let d = [];
for (var i = 1; i < 48; i++) {
  d.push(i.toString());
}
export const durationOptions = d;

export const AddTaskStatusForm = {
  steps: Array.from(
    { length: 1 },
    (_, index) => `profile.contractor.step${index + 1}`
  ),
  stepDescription: {
    step0: {
      subHeading:
        "Please enter all the details of the taskStatus that are required to be filled.",
    },
  },
  formFields: {
    step0: [
      {
        key: "statusName",
        placeholder: "statusName",
        label: getTextContent("statusName"),
        type: "text",
        required: true,
        width: "100%",
      },
    ],
  },
  validationSchema: validationSchema,
  defaultState: (defaultState) => {
    if (defaultState) {
      return defaultState;
    } else {
      const dynamicInitialState = Object.fromEntries(
        Object.keys(validationSchema.fields).map((key) => {
          return [key, ""];
        })
      );

      return {
        ...dynamicInitialState,
      };
    }
  },
};

export const taskStatusListColumns = (props = {}) => [
  {
    field: "taskStatusId",
    headerName: "taskStatusId",
    width: 150,
  },
  {
    field: "statusName",
    headerName: "statusName",
    width: 200,
  },

  { field: "createdBy", headerName: "Created By", width: 150 },
  {
    field: "createdDate",
    headerName: "Created Date",
    width: 150,
    renderCell: (params) => {
      return params.value ? formatDateToReadable(params.value) : "NA";
    },
  },
  { field: "modifiedBy", headerName: "Modified By", width: 150 },

  {
    field: "modifiedDate",
    headerName: "Modified Date",
    width: 150,
    renderCell: (params) => {
      return params.value ? formatDateToReadable(params.value) : "NA";
    },
  },

  {
    field: "actions",
    type: "actions",
    flex: 1,
    align: "right",
    getActions: (params) => [
      <BurgerMenu>
        <MenuItem
          onClick={() => {
            console.log("Check the Params : ", params);
            props.setEditJob(true);
            props.setAddNewJob(true);
            props.setSelectedJob({
              id: params.taskStatusId,
              data: params.row,
            });
          }}
        >
          <EditOutlined /> <p className="m-0">Edit</p>
        </MenuItem>

        <HiddenOn
          isHidden={!isJobManager(props.appRoleDetails, props?.projectData)}
        >
          <MenuItem
            onClick={() => {
              props.onCompanyDelete(params.id);
            }}
          >
            <Delete /> <p className="m-0">Delete</p>
          </MenuItem>
        </HiddenOn>
      </BurgerMenu>,
    ],
  },
];

export const WarrantyStatus = ({ status }) => {
  const statusColors = {
    "Expiring soon": "#FFBA08",
    Expired: "#FF5630",
    "Under Warranty": "#36B37E",
    Active: "#36B37E",
  };
  return (
    <>
      <div
        style={{
          border: `1px solid ${statusColors[status]}`,
          width: "8rem",
          height: "2rem",
          borderRadius: "5px",
        }}
        className="d-flex align-items-center "
      >
        <StatusIndicator
          color={statusColors[status]}
          className="ms-2 me-2 mb-1"
        />
        <Typography
          variant="body1"
          className="ms-2"
          style={{ color: "#0B2240", fontSize: "11px" }}
        >
          {startCase(status)}
        </Typography>
      </div>
    </>
  );
};

function formatDateToReadable(dateString) {
  const date = new Date(dateString);

  // Function to get the correct day suffix
  const getDaySuffix = (day) => {
    if (day % 10 === 1 && day !== 11) return `${day}st`;
    if (day % 10 === 2 && day !== 12) return `${day}nd`;
    if (day % 10 === 3 && day !== 13) return `${day}rd`;
    return `${day}th`;
  };

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();

  return `${getDaySuffix(day)} ${month} ${year}`;
}

export const dataURLtoFile = (dataurl, filename) => {
  const d = "data:image/png;base64," + dataurl;
  var arr = d.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
