import * as yup from "yup";
import { InputAdornment } from "@mui/material";
import { DATE_FORMAT } from "constants/appConstants";
import { Room } from "@mui/icons-material";
import { isHomeOwner } from "core/utils/roleUtils";

export const addPunchListValidationSchema = yup.object({
	title: yup
		.string("Enter Title For Punch List")
		.required("PunchList title is required"),
	location: yup.string("Enter Location").required("Location is required"),
	punchListStatusTypeId: yup
		.string("Select Status")
		.required("Status is required"),
	punchListPriorityId: yup
		.string("Select Priority")
		.required("Priority is required"),
	dueDate: yup.string("Enter Due Start Date").required("Due Date is required"),
});

export const addPunchListFields = [
	{
		key: "title",
		label: "Title",
		placeholder: "Punch List Name",
		required: true,
		type: "text",
		width: "50%",
	},
	{
		key: "punchListTypeId",
		label: "Type",
		placeholder: "Select Type",
		type: "dynamicField",
		value: "",
		select: true,
		options: [""],
		width: "50%",
	},

	{
		key: "location",
		label: "Location",
		placeholder: "Enter Location of Punch",
		type: "text",
		required: true,
		width: "50%",
		extraInputProps: {
			InputProps: {
				startAdornment: (
					<InputAdornment position="start">
						<Room style={{ fontSize: "16px" }} />
					</InputAdornment>
				),
			},
		},
	},
	{
		key: "punchListStatusTypeId",
		label: "Status",
		placeholder: "Select Status",
		type: "select",
		width: "50%",
		select: true,
		required: true,
		options: [""],
	},
	{
		key: "dueDate",
		placeholder: DATE_FORMAT,
		label: "Due Date",
		type: "dateField",
		required: true,
		width: "50%",
	},

	{
		key: "punchListPriorityId",
		label: "Priority",
		placeholder: "Select Priority",
		type: "select",
		select: true,
		options: ["New Build", "Renovation", "Remodel", "Tear Down Rebuild"],
		required: true,
		width: "50%",
	},
	{
		key: "companyId",
		label: "Assigned To",
		dynamicField: true,
		type: "select",
		select: true,
		options: [],
		// required: true,
		width: "50%",
		shouldHide: ({ appRoleDetails }) => {
			return isHomeOwner(appRoleDetails);
		},
	},
	{
		key: "assignedTeamMember",
		label: "Assigned Team Member",
		type: "text",
		// select: true,
		// options: [],
		// required: true,
		width: "50%",
		shouldHide: ({ appRoleDetails }) => isHomeOwner(appRoleDetails),
	},
	{
		key: "scheduleImpact",
		label: "Schedule Impact",
		placeholder: "Enter Schedule impact details here",
		type: "text",
		width: "50%",
	},
	{
		key: "costImpact",
		placeholder: "Enter Cost impact details here",
		label: "Cost Impact",
		type: "text",
		width: "50%",
	},
	{
		key: "description",
		placeholder: "Enter Punch Description here",
		label: "Description",
		type: "textArea",
		width: "100%",
		extraInputProps: {
			multiline: true,
			className:
				"registration-formField project-notes property-details-textarea",
		},
	},
];

export const addPunchListForm = {
	validationSchema: addPunchListValidationSchema,
	defaultState: (defaultState) => {
		const initialState = Object.fromEntries(
			Object.keys(addPunchListValidationSchema.fields).map((key) => {
				return [key, ""];
			})
		);
		if (defaultState) {
			return { ...initialState, ...defaultState };
		}
		return {
			...initialState,
		};
	},
};
