import RenderTabs from "components/Tabs/Tabs";
import React, { useCallback, useContext, useEffect } from "react";
import { ProjectOverview, TaskManager, UserManagement } from "./tabComponents";
import { fetchProjectById } from "actions/projectActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getProjectData } from "selectors/projectSelector";
import { Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import {
	isAssignee,
	isHomeOwner,
	isProjectManager,
} from "core/utils/roleUtils";
import AppRoleContext from "context/AppRoleContext";
import { CustomBreadCrumb } from "components/BreadCrumb";
import ProjectDetails from "./tabComponents/ProjectDetails";
import UnderConstruction from "pages/app/UnderDevelopment";
import { FileManager } from "./tabComponents/FileManager";
import get from "lodash/get";
import ProjectSchedule from "./tabComponents/ProjectSchedule";
import Warranty from "./tabComponents/warranty/Warranty";
import ProjectSuppliers from "./tabComponents/ProjectSuppliers";
import PunchListPage from "./tabComponents/PunchList";

const tabs = [
	{
		key: "overview",
		label: "Overview",
		TabComponent: ProjectOverview,
		hide: ({ appRoleDetails, projectData }) => {
			const projectUserRole = projectData?.projUserRole;
			return (
				!isProjectManager(appRoleDetails, projectUserRole) ||
				isAssignee(projectData.accessType)
			);
		},
	},
	{
		key: "jobs",
		label: "Jobs",
		TabComponent: TaskManager,
		hide: ({ appRoleDetails }) => isHomeOwner(appRoleDetails),
	},
	{
		key: "schedules",
		label: "Schedule",
		TabComponent: ProjectSchedule,
	},
	{
		key: "suppliers",
		label: "Suppliers",
		// TabComponent: Analysis,
		TabComponent: ProjectSuppliers,
		hide: ({ appRoleDetails, projectData }) => {
			const projectUserRole = projectData?.projUserRole;
			return (
				!isProjectManager(appRoleDetails, projectUserRole) ||
				isAssignee(projectData.accessType) ||
				isHomeOwner(appRoleDetails)
			);
		},
	},
	{
		key: "files",
		label: "Files",
		TabComponent: FileManager,
	},
	{
		key: "invoices",
		label: "Invoices",
		// TabComponent: Analysis,
		TabComponent: UnderConstruction,

		hide: ({ appRoleDetails, projectData }) => {
			const projectUserRole = projectData?.projUserRole;
			return (
				!isProjectManager(appRoleDetails, projectUserRole) ||
				isAssignee(projectData.accessType) ||
				isHomeOwner(appRoleDetails)
			);
		},
	},
	{
		key: "team",
		label: "Team",
		TabComponent: UserManagement,
		hide: ({ appRoleDetails, projectData }) => {
			const projectUserRole = projectData?.projUserRole;
			return (
				!isProjectManager(appRoleDetails, projectUserRole) ||
				isAssignee(projectData.accessType) ||
				isHomeOwner(appRoleDetails)
			);
		},
	},
	{
		key: "projectDetails",
		label: "Project Details",
		TabComponent: ProjectDetails,
	},
	{
		key: "punchList",
		label: "Punch List",
		TabComponent: PunchListPage,
	},
	{
		key: "warranty",
		label: "Warranty",
		TabComponent: Warranty,
	},
];

export const ViewProject = () => {
	const appRoleDetails = useContext(AppRoleContext);
	const navigate = useNavigate();
	const { state = {} } = useLocation();
	// const  = location
	const [selectedTab, updateTab] = React.useState("overview");

	const params = useParams();

	const dispatch = useDispatch();
	const projectData = useSelector(getProjectData);

	const getProjectDetails = useCallback(() => {
		dispatch(fetchProjectById(params.projectId));
	}, [dispatch, params.projectId]);

	useEffect(() => {
		if (params.tab) {
			updateTab(params.tab);
		}
	}, [params]);

	useEffect(() => {
		getProjectDetails();
	}, [dispatch, getProjectDetails]);

	useEffect(() => {
		if (get(projectData, "accessType", false) && selectedTab === "overview") {
			updateTab(isAssignee(projectData.accessType) ? "jobs" : "overview");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectData]);

	return (
		<>
			<div className="d-flex align-items-center">
				<ArrowBack
					style={{ height: "20px", width: "23px", color: "#345DAE" }}
					className="pointer mt-2"
					onClick={() => {
						navigate(`/properties/${params.propertyId}`, {
							...state,
						});
					}}
				/>
				<Typography
					className="h3 mt-3"
					style={{
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
						width: "15rem",
					}}
				>
					{projectData?.projectName}
				</Typography>
			</div>
			<CustomBreadCrumb
				breadCrumbItems={["properties", "propertyName", "projectName"]}
			/>
			<div className="project-tabs tabs" id="tab-container">
				<RenderTabs
					tabs={tabs.filter((tab) =>
						tab.hide ? !tab.hide({ appRoleDetails, projectData }) : true
					)}
					selectedTab={selectedTab}
					onTabClick={(newTab) => {
						updateTab(newTab);
						navigate(
							`/properties/${params.propertyId}/projects/${params.projectId}/${newTab}`,
							{
								state,
							}
						);
					}}
					tabParams={{
						projectId: params.projectId,
						propertyId: params.propertyId,
						projectData,
						getProjectDetails,
						appRoleDetails,
					}}
				/>
			</div>
		</>
	);
};

export default ViewProject;
