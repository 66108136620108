import { combineReducers } from "redux";
import appReducer from "./appReducer";
import companyReducer from "./companyReducer";
import propertyReducer from "./propertyReducer";
import projectReducer from "./projectReducer";
import estimatesReducer from "./estimatesReducer";
import connectionReducer from "./connectionReducer";
import customerReducer from "./customerReducer";
import employeeReducer from "./employeeReducer";
import paymentReducer from "./paymentReducer";
import jobReducer from "./jobReducer";
import taskReducer from "./taskReducer";
import userReducer from "./userReducer";
import documentsReducer from "./documentsReducer";
import marketPlaceReducer from "./marketPlaceReducer";
import appConfigReducer from "./appConfigReducer";
import customer_typeReducer from "./customer_typeReducer";
import customer_statusReducer from "./customer_statusReducer";
import warrantyReducer from "./warrantyReducers";
import fileManagerReducer from "./fileManagerReducer";
import adsReducer from "./adsReducer";
import punchListReducer from "./punchListReducer";
import projectStatusReducer from "./projectStatusReducer";
import jobTypeReducer from "./jobTypeReducer";
import jobStatusReducer from "./jobStatusReducer";
import Subscription from "pages/settings/MasterData/Subscription/Subscription";
import subscriptionReducer from "./subscriptionReducer";
import documentTypeReducer from "./documentTypeReducer";
import companyTypeReducer from "./companyTypeReducer";
import marketSectorReducer from "./marketSectorReducer";
import punchListTypeReducer from "./punchListTypeReducer";
import punchListStatusTypeReducer from "./punchListStatusTypeReducer";
import taskStatusReducer from "./taskStatusReducer";
import punchListPriorityReducer from "./punchListPriorityReducer";
import connectionStatusReducer from "./connectionStatusReducer";

// import activityReducer from "./activityReducer";
// import allocationReducer from "./allocationReducer";
// import change_orderReducer from "./change_orderReducer";
// import client_selectionReducer from "./client_selectionReducer";
// import connection_statusReducer from "./connection_statusReducer";
// import contactReducer from "./contactReducer";
// import contract_typeReducer from "./contract_typeReducer";
// import daily_logReducer from "./daily_logReducer";
// import invoiceReducer from "./invoiceReducer";
// import job_employeeReducer from "./job_employeeReducer";
// import leadReducer from "./leadReducer";
// import lead_activityReducer from "./lead_activityReducer";
// import lead_statusReducer from "./lead_statusReducer";
// import organizationReducer from "./organizationReducer";
// import organization_statusReducer from "./organization_statusReducer";
// import organization_typeReducer from "./organization_typeReducer";
// import purchase_orderReducer from "./purchase_orderReducer";
// import roleReducer from "./roleReducer";
// import warranty_supportReducer from "./warranty_supportReducer";

// import task_statusReducer from "./task_statusReducer";
// import purchase_order_statusReducer from "./purchase_order_statusReducer";
// import warranty_statusReducer from "./warranty_statusReducer";
// import change_order_statusReducer from "./change_order_statusReducer";
// import invoice_statusReducer from "./invoice_statusReducer";

// Combine all reducers as root reducer
export default combineReducers({
  app: appReducer,
  company: companyReducer,

  // activity: activityReducer,
  // allocation: allocationReducer,
  // change_order: change_orderReducer,
  // client_selection: client_selectionReducer,
  connection: connectionReducer,
  // connection_status: connection_statusReducer,
  // contact: contactReducer,
  // contract_type: contract_typeReducer,
  customer: customerReducer,
  // daily_log: daily_logReducer,
  employee: employeeReducer,
  // invoice: invoiceReducer,
  job: jobReducer,
  // job_employee: job_employeeReducer,
  // lead: leadReducer,
  // lead_activity: lead_activityReducer,
  // lead_status: lead_statusReducer,
  // organization: organizationReducer,
  // organization_status: organization_statusReducer,
  // organization_type: organization_typeReducer,
  payment: paymentReducer,
  // purchase_order: purchase_orderReducer,
  // purchase_order_status: purchase_order_statusReducer,
  // role: roleReducer,
  task: taskReducer,
  // warranty_support: warranty_supportReducer,
  customer_type: customer_typeReducer,
  customer_status: customer_statusReducer,
  property: propertyReducer,
  project: projectReducer,
  user: userReducer,
  documents: documentsReducer,
  appConfig: appConfigReducer,
  marketPlaceCompanies: marketPlaceReducer,
  estimates: estimatesReducer,
  warranty: warrantyReducer,
  fileManager: fileManagerReducer,
  projectStatus: projectStatusReducer,
  advertisement: adsReducer,
  punchList: punchListReducer,
  jobType: jobTypeReducer,
  jobStatus: jobStatusReducer,
  subscription: subscriptionReducer,
  documentType: documentTypeReducer,
  companyType: companyTypeReducer,
  marketSector: marketSectorReducer,
  punchListType: punchListTypeReducer,
  punchListStatusType: punchListStatusTypeReducer,
  taskStatus: taskStatusReducer,
  punchListPriority:punchListPriorityReducer,
  connectionStatus: connectionStatusReducer,
  // task_status: task_statusReducer,
  // warranty_status: warranty_statusReducer,
  // change_order_status: change_order_statusReducer,
  // invoice_status: invoice_statusReducer,
});
