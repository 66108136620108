import React, { useEffect } from "react";
import { Box, Typography, Card } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptionPlans } from "selectors/paymentSelectors";
import {
	fetchSubscriptionPlans,
	updateSubscriptionPlanAction,
} from "actions/paymentActions";
import RenderDataFields from "components/DataFields/RenderDataFields";

export const MySubscriptions = ({ userData }) => {
	console.log("Check the User Data : ", userData);
	const dispatch = useDispatch();
	const subscriptionPlans = useSelector(getSubscriptionPlans);
	// const paymentIcons = { visa, mastercard, americanExpress, jcb, discover };

	const [planSelected, setPlanSelected] = React.useState({});

	useEffect(() => {
		dispatch(fetchSubscriptionPlans());
	}, [dispatch]);

	const renderPlansData = [
		{
			key: "planName",
			label: "Plan",
			dataPath: "subscriptionPlan",
			width: "45%",
			formatter: (value) => {
				return <Typography className="h3">{value}</Typography>;
			},
		},
		{
			key: "actualPrice",
			label: "Actual Price",
			dataPath: "price",
			width: "45%",
			formatter: (_, data) => {
				return (
					<>
						<Typography className="body1">
							<span
								className="ms-2"
								style={{
									textDecoration: "line-through",
									fontWeight: "600",
								}}
							>
								$ {data.price}
							</span>
						</Typography>
					</>
				);
			},
		},
		{
			key: "discPrice",
			label: "Discount Price",
			dataPath: "discPrice",
			width: "100%",

			formatter: (_, data) => {
				return (
					<>
						<Typography className="h3">
							<span
								className="ms-2"
								style={{ fontWeight: "600", color: "green" }}
							>
								${data.discPrice}
							</span>
							<span
								className="ms-2"
								style={{ fontSize: "12px", color: "green" }}
							>
								({data.discountPct}% Off)
							</span>
						</Typography>
					</>
				);
			},
		},
	];

	const isSamePlan = (planId) => planId === planSelected.subscriptionId;

	const upgradePlanSelection = () => {
		const onSuccess = () => {
			setPlanSelected({});
		};
		dispatch(
			updateSubscriptionPlanAction(planSelected.subscriptionId, onSuccess)
		);
	};

	return (
		<Box className="space-y-6">
			<Typography className="h3 mt-3">Current Plan Details</Typography>
			<div className="d-flex mt-3">
				<Typography className="body1 mb-2 col-4">Plan Name </Typography>
				<Typography className="h5 mb-4 ms-3" color="primary">
					- {userData.subscription.subscriptionPlan}
				</Typography>
			</div>
			<div className="d-flex mt-2">
				<Typography className="body1 mb-2 col-4">Price </Typography>
				<Typography className="h5 mb-4 ms-3" color="primary">
					- $ {userData.subscription.discPrice}
				</Typography>
			</div>
			<Typography className="mb-2">
				Next billing date: {userData.nextBilling}
			</Typography>
			<div className="col-12 mt-3 p-2">
				<Typography className="h4 mb-4">Available Plans</Typography>
				<Box className="pe-4">
					{subscriptionPlans
						.filter((plan) => plan.subscriptionId !== userData.subscriptionId)
						.map((plan) => {
							return (
								<Card
									key={plan}
									className={`p-4 ${
										isSamePlan(plan.subscriptionId)
											? ""
											: "border border-gray-200"
									} m-2 mt-2 mb-2 col-12`}
									style={
										isSamePlan(plan.subscriptionId)
											? { border: "1px solid #345DAE" }
											: {}
									}
								>
									<RenderDataFields data={plan} fields={renderPlansData} />
									<div className="text-center">
										<button
											onClick={() => setPlanSelected(plan)}
											className={
												isSamePlan(plan.subscriptionId)
													? "secondaryButton"
													: "primaryButton"
											}
										>
											{isSamePlan(plan.subscriptionId)
												? "Plan Selected"
												: "Select Plan"}
										</button>
									</div>
								</Card>
							);
						})}
				</Box>
			</div>
			<div className="mt-3 mb-3">
				<button
					className={`primaryButton full-width`}
					color="primary"
					disabled={!planSelected.subscriptionId}
					style={planSelected.subscriptionId ? {} : { backgroundColor: "gray" }}
					onClick={() => upgradePlanSelection()}
				>
					Upgrade Plan
				</button>
			</div>
		</Box>
	);
};

export default MySubscriptions;
