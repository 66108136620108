import React from "react";
import { Link } from "react-router-dom";
import "./notfound.scss";

const PageNotFound = () => {
	return (
		<div className="not-found">
			<h2>Oops! Page Not Found</h2>
			<p>Sorry, the page you're looking for doesn't exist.</p>
			<Link to="/properties" className="home-link primaryButton">
				Go Home
			</Link>
		</div>
	);
};

export default PageNotFound;
