import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import "./dataGridTable.scss";
import { IconButton } from "@mui/material";
import Delete from "@mui/icons-material/Delete";
/**
 * Default values for MUI X Data Grid column properties.

const defaultColumnProperties = {
	field: undefined, // (required) No default value
	headerName: "", // Default value is an empty string
	align: "left", // Default value is 'left'
	width: 100, // Default value is 100
	flex: undefined, // Default value is undefined
	sortable: true, // Default value is true
	filterable: true, // Default value is true
	editable: false, // Default value is false
	resizable: true, // Default value is true
	hide: false, // Default value is false
	headerAlign: "center", // Default value is 'center'
	renderHeader: undefined, // Default value is undefined
	valueGetter: undefined, // Default value is undefined
	valueFormatter: (params) => params.value, // Default function returns the raw value
	cellClassName: undefined, // Default value is undefined
	headerClassName: undefined, // Default value is undefined
	filterOperators: undefined, // Default value is undefined
	sortComparator: (v1, v2) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0), // Default function sorts alphabetically
	disableColumnMenu: false, // Default value is false
	renderCell: undefined, // Default value is undefined
	editCellProps: undefined, // Default value is undefined
	valueParser: (newValue) => newValue, // Default function returns the parsed value
	valueSetter: (params, newValue) => params.setValue(newValue), // Default function sets the new value
	editableCellProps: undefined, // Default value is undefined
	footerName: undefined, // Default value is undefined
};

 */

export const DataGridTable = ({
  totalCount,
  columns,
  data,
  showToolbar = true,
  defineRowId = (row) => row.id,
  pageSizeOptions = [10, 25, 50, 100],
  paginationModel = { page: 0, pageSize: 10 },
  onPaginationChange,
  paginationMode = "server",
  visibleSelection = true,
  checkboxSelection = true,
  CustomToolbar = null /* Custom Toolbar Function or Component*/,
  isLoading,
  onSelect,
  selectedRows,
  onDeleteSelection,
  pagination = true /* send pagination=false to hide pagination */,
  disableRowSelectionOnClick = true,
  disableBulkDelete = false,
  ...rest
}) => {
  const [classElementValue, setClassElementValue] = useState("");

  const updateValue = useCallback(() => {
    const classElement = document.querySelector(
      ".basic-data-grid .MuiTablePagination-displayedRows"
    );
    if (classElement) {
      const newValue = classElement.textContent || classElement.innerText;
      setClassElementValue(newValue);
    }
  }, []);

  useEffect(() => {
    // Initial update
    updateValue();

    // Set up a MutationObserver to watch for changes
    const observer = new MutationObserver(updateValue);
    const targetNode = document.querySelector(".basic-data-grid");

    if (targetNode) {
      observer.observe(targetNode, { childList: true, subtree: true });
    }

    // Clean up the observer on component unmount
    return () => observer.disconnect();
  }, [updateValue]);

  const dataGridToolbar = () => (
    <div className="mt-2 mb-2">
      <GridToolbarContainer>
        {/* <GridToolbarColumnsButton /> */}
        <GridToolbarFilterButton className="dataGrid-filter-button" />
        <IconButton onClick={onDeleteSelection} disabled={disableBulkDelete}>
          <Delete />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: "Export data" },
            button: { variant: "outlined" },
          }}
        />
      </GridToolbarContainer>
    </div>
  );
  const customSlots = { columnMenuColumnsItem: null };
  if (showToolbar) {
    customSlots.toolbar = CustomToolbar || dataGridToolbar;
  }
  if (!pagination) {
    customSlots.pagination = false;
  }
  return (
    <div
      // style={{ height: "25rem", width: "99%", padding: "0.75rem" }}
      className="basic-data-grid"
    >
      {showToolbar && classElementValue && (
        <>
          <div className="records-count-text m-0 mt-3">
            <p>{`Displaying: ${classElementValue}`}</p>
          </div>
          <div className="divider" />
        </>
      )}
      <DataGrid
        autoHeight
        rowCount={totalCount || -1}
        loading={isLoading}
        columns={columns}
        rows={data}
        slots={customSlots}
        disableColumnMenu
        pagination={pagination}
        paginationMode={paginationMode}
        pageSizeOptions={pageSizeOptions}
        paginationModel={paginationModel}
        onPaginationModelChange={(newModel) => {
          if (onPaginationChange) {
            onPaginationChange(newModel);
          }
          // Update the value after pagination changes
          setTimeout(updateValue, 0);
        }}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        checkboxSelection={checkboxSelection}
        checkboxSelectionVisibleOnly={visibleSelection}
        onRowSelectionModelChange={onSelect}
        rowSelectionModel={selectedRows}
        keepNonExistentRowsSelected
        components={{
          Toolbar: showToolbar ? CustomToolbar || dataGridToolbar : null,
        }}
        getRowId={defineRowId}
        {...rest}
      />
    </div>
  );
};

export default DataGridTable;
