import React, { useEffect } from "react";

import NoResultsFound from "components/NoResults/NoResultsFound";
import { useDispatch } from "react-redux";
import { fetchBillingHistory } from "actions/paymentActions";
// Mock user data

export const BillingInformation = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchBillingHistory());
	}, [dispatch]);
	return (
		<div className="align-content-center" style={{ height: "100%" }}>
			<NoResultsFound headerType="h4" message={"No Data Available Currently"} />
		</div>
	);
};
export default BillingInformation;
