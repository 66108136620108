import { Box } from "@mui/material";
// import RenderTabs from "components/Tabs/Tabs";
import React, { useState } from "react";
import ProjectStatus from "../ProjectStatus/ProjectStatus";
import JobType from "../JobType/JobType";
import JobStatus from "../JobStatus/JobStatus";
import Subscription from "./Subscription/Subscription";
import DocumentType from "./DocumentType/DocumentType";
import CompanyType from "./CompanyType/CompanyType";
import MarketSectors from "./MarketSectors/MarketSectors";
import PunchListType from "./PunchListType/PunchListType";
import PunchListStatusType from "./PunchListStatusType/PunchListStatusType";
import TaskStatus from "./TaskStatus/TaskStatus";
import RenderVerticalTabs from "components/VerticalTabs/VerticalTabs";
import PunchListPriority from "./PunchListPriority/PunchListPriority";
import ConnectionStatus from "./ConnectionStatus/ConnectionStatus";

const tabs = [
  {
    key: "Project Status",
    label: "Project Status",
    TabComponent: ProjectStatus,
  },
  {
    key: "JobType",
    label: "JobType",
    TabComponent: JobType,
  },
  {
    key: "JobStatus",
    label: "JobStatus",
    TabComponent: JobStatus,
  },
  {
    key: "Subscription",
    label: "Subscription",
    TabComponent: Subscription,
  },
  {
    key: "DocumentType",
    label: "DocumentType",
    TabComponent: DocumentType,
  },
  {
    key: "CompanyType",
    label: "CompanyType",
    TabComponent: CompanyType,
  },
  {
    key: "MarketSector",
    label: "MarketSector",
    TabComponent: MarketSectors,
  },
  {
    key: "PunchListType",
    label: "PunchListType",
    TabComponent: PunchListType,
  },
  {
    key: "PunchListStatusType",
    label: "PunchListStatusType",
    TabComponent: PunchListStatusType,
  },
  {
    key: "TaskStatus",
    label: "TaskStatus",
    TabComponent: TaskStatus,
  },
  {
    key: "PunchListPriority",
    label: "PunchListPriority",
    TabComponent: PunchListPriority,
  },
  {
    key: "ConnectionStatus",
    label: "ConnectionStatus",
    TabComponent: ConnectionStatus,
  },
];

const MasterData = () => {
  const [value, setValue] = useState("Project Status");

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <RenderVerticalTabs
          tabs={tabs}
          orientation="vertical"
          selectedTab={value}
          onTabClick={(newValue) => {
            setValue(newValue);
          }}
        />
      </Box>
    </Box>
  );
};

export default MasterData;
