import * as Icons from "@mui/icons-material";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import // faShop,
// faFileInvoiceDollar,
// faDollarSign,
"@fortawesome/free-solid-svg-icons";

export const systemAdminMenu = [
	// {
	// 	id: `sysAdmin1`,
	// 	label: "Dashboard",
	// 	link: "/admin/dashboard",
	// 	icon: <Icons.SpaceDashboardOutlined />,
	// },

	// {
	// 	id: `sysAdmin2`,
	// 	label: "Projects",
	// 	link: "/projects",
	// 	icon: <Icons.EngineeringOutlined />,
	// },
	// {
	// 	id: `sysAdmin3`,
	// 	label: "Jobs",
	// 	link: "/jobs",
	// 	icon: <Icons.WorkHistoryOutlined />,
	// },
	// {
	// 	id: `sysAdmin4`,
	// 	label: "Tasks",
	// 	link: "/tasks",
	// 	icon: <Icons.AssignmentOutlined />,
	// },

	{
		id: `sysAdmin5`,
		label: "Properties",
		link: "/properties",
		icon: <Icons.MapsHomeWorkOutlined />,
	},
	{
		id: `sysAdmin6`,
		label: "Companies",
		link: "/companies",
		icon: <Icons.BusinessOutlined />,
	},
	{
		id: `sysAdmin7`,
		label: "Reports",
		link: "/reports",
		icon: <Icons.Summarize />,
	},
	// {
	// 	id: `sysAdmin8`,
	// 	label: "Orders History",
	// 	icon: <Icons.PaymentsOutlined />,
	// 	children: [
	// 		{
	// 			id: 211,
	// 			label: "Purchase Orders",
	// 			link: "/purchaseOrders",
	// 			icon: (
	// 				<FontAwesomeIcon
	// 					icon={faShop}
	// 					style={{ fontSize: "1.7rem", marginLeft: "1rem", color: "white" }}
	// 				/>
	// 			),
	// 		},
	// 		{
	// 			id: 212,
	// 			label: "Invoices",
	// 			link: "/invoiceOrders",
	// 			icon: (
	// 				<FontAwesomeIcon
	// 					icon={faFileInvoiceDollar}
	// 					style={{ fontSize: "1.7rem", marginLeft: "1rem", color: "white" }}
	// 				/>
	// 			),
	// 		},
	// 		{
	// 			id: 213,
	// 			label: "Payments",
	// 			link: "/payments",
	// 			icon: (
	// 				<FontAwesomeIcon
	// 					icon={faDollarSign}
	// 					style={{ fontSize: "1.7rem", marginLeft: "1rem", color: "white" }}
	// 				/>
	// 			),
	// 		},
	// 	],
	// },

	// {
	// 	id: `sysAdmi9`,
	// 	label: "Timelines",
	// 	link: "/timelines",
	// 	icon: <Icons.TimelineOutlined />,
	// },
	// {
	// 	id: `sysAdmin10`,
	// 	label: "TimeSheets",
	// 	link: "/timesheet",
	// 	icon: <Icons.PunchClockOutlined />,
	// },
	{
		id: `sysAdmin11`,
		label: "Users",
		link: "/users",
		icon: <Icons.PeopleOutlineOutlined />,
	},
	// {
	// 	id: `sysAdmin12`,
	// 	label: "Estimates",
	// 	// link: "/estimatesApprovals",
	// 	link: "/estimations",
	// 	icon: <Icons.ApprovalOutlined />,
	// },
	// {
	// 	id: `sysAdmin13`,
	// 	label: "Change Requests",
	// 	// link: "/changeRequests",
	// 	link: "/page/underDevelopment",
	// 	icon: <Icons.BorderAllOutlined />,
	// },
	{
		id: `sysAdmin14`,
		label: "Settings",
		// link: "/settings",
		link: "/settings",
		icon: <Icons.SettingsOutlined />,
	},
	{
		id: `sysAdmin15`,
		label: "File Manager",
		// link: "/settings",
		link: "/filemanager",
		icon: <Icons.FolderOutlined />,
	},
	{
		id: "menu_signout",
		label: "Signout",
		link: "/login",
		hiddenOn: { mdUp: true },
		icon: <Icons.LogoutOutlined />,
		onClick: () => {
			localStorage.removeItem("token");
		},
	},

	// {
	// 	id: 37,
	// 	label: "Options",
	// 	icon: <TableIcon />,
	// 	children: [
	// 		{
	// 			id: 31,
	// 			label: "ORGANIZATION_TYPE",
	// 			link: "//organization_type",
	// 			icon: <TableIcon />,
	// 		},
	// 		{
	// 			id: 32,
	// 			label: "CUSTOMER_TYPE",
	// 			link: "//customer_type",
	// 			icon: <TableIcon />,
	// 		},
	// 		{
	// 			id: 33,
	// 			label: "CUSTOMER_STATUS",
	// 			link: "//customer_status",
	// 			icon: <TableIcon />,
	// 		},
	// 		{
	// 			id: 20,
	// 			label: "ORGANIZATION_STATUS",
	// 			link: "//organization_status",
	// 			icon: <TableIcon />,
	// 		},
	// 		{
	// 			id: 35,
	// 			label: "TASK_STATUS",
	// 			link: "//task_status",
	// 			icon: <TableIcon />,
	// 		},
	// 		{
	// 			id: 36,
	// 			label: "PURCHASE_ORDER_STATUS",
	// 			link: "//purchase_order_status",
	// 			icon: <TableIcon />,
	// 		},
	// 		{
	// 			id: 7,
	// 			label: "CONNECTION_STATUS",
	// 			link: "//connection_status",
	// 			icon: <TableIcon />,
	// 		},
	// 		{ id: 23, label: "ROLE", link: "//role", icon: <TableIcon /> },
	// 		{
	// 			id: 9,
	// 			label: "CONTRACT_TYPE",
	// 			link: "//contract_type",
	// 			icon: <TableIcon />,
	// 		},
	// 		{
	// 			id: 33,
	// 			label: "WARRANTY_STATUS",
	// 			link: "//warranty_status",
	// 			icon: <TableIcon />,
	// 		},
	// 		{
	// 			id: 42,
	// 			label: "CHANGE_ORDER_STATUS",
	// 			link: "//change_order_status",
	// 			icon: <TableIcon />,
	// 		},
	// 		{
	// 			id: 42,
	// 			label: "INVOICE_STATUS",
	// 			link: "//invoice_status",
	// 			icon: <TableIcon />,
	// 		},
	// 	],
	// },
];
