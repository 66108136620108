import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.subscription;

export const getSubscription = createSelector(selectState, (subscription) => ({
  subscription: get(subscription, "subscription", []),
  totalCount: get(subscription, "totalCount", 0),
  loading: get(subscription, "loading", false),
  showSnackbar: get(subscription, "showSnackbar", false),
  status: get(subscription, "status", "success"),
  statusText: get(subscription, "text", ""),
}));
