import { Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { statusColors } from "../PunchList";
import StatusIndicator from "components/StatusIndicators/StatusIndicator_Circle";
import {
	Add,
	Attachment,
	KeyboardArrowDown,
	KeyboardArrowUp,
	Room,
} from "@mui/icons-material";
import { TaskStatus } from "pages/task/addTaskUtils";
import get from "lodash/get";
import "./punchList.scss";
import noPunchListsImg from "assets/project/noPunchLists.png";
import HiddenOn from "components/Wrappers/HiddenOn";

export const MobilePunchListView = (props) => {
	return (
		<>
			<Paper className="d-flex justify-content-between align-items-center">
				<Typography className="h4 p-3">Punch List</Typography>
				<div className="m-2">
					<button
						className="secondaryButton"
						style={{
							border: "1px solid #345DAE",
							width: "auto",
							height: "auto",
							borderRadius: 0,
						}}
						onClick={() => {
							console.log("on CLick of Add");
							props.createPunchItem();
						}}
					>
						<Add size="small" style={{ fontSize: "16px" }} />
					</button>
				</div>
			</Paper>
			<div className="PL-sm-page" id="punchlist-sm-page">
				<div>
					<RenderPunchCard {...props} />
				</div>
			</div>
		</>
	);
};

export const RenderPunchCard = ({
	punchListItems,
	onRowClick,
	totalCount,
	pageModel,
	setPageModel,
}) => {
	return punchListItems.length > 0 ? (
		<>
			{punchListItems.map((punchItem) => {
				return (
					<div className="PL-card m-3">
						<Paper
							className="pointer hoverEffect"
							onClick={() => {
								onRowClick(punchItem);
							}}
						>
							<div className="PL-card-priority card-tags d-flex">
								<div
									style={{
										background: "lightgray",
										padding: "3px",
										alignItems: "center",
										width: "5rem",
									}}
									className="d-flex m-2 punchItem-type-tag"
								>
									<Typography
										variant="body1"
										style={{ fontSize: "12px", margin: "auto" }}
									>
										{get(punchItem, "punchListType.punchListType", "N/A")}
									</Typography>
								</div>
								<div
									style={{
										background:
											statusColors[
												get(
													punchItem,
													"punchListPriority.punchListPriority",
													"Medium"
												)
											],
										color: "white",
										padding: "3px",
										alignItems: "center",
										width: "5rem",
										margin: "auto",
									}}
									className="m-2 d-flex priority-tag"
								>
									<StatusIndicator
										color="white"
										width={"10px"}
										height={"10px"}
										style={{
											margin: "0 5px",
											position: "relative",
										}}
									/>
									<Typography
										variant="body1"
										style={{ fontSize: "12px", color: "white" }}
									>
										{get(
											punchItem,
											"punchListPriority.punchListPriority",
											"Medium"
										)}
									</Typography>
								</div>
							</div>
							<Typography className="h3  mb-2 p-2 col-9">
								{punchItem.title}
							</Typography>
							<div className="PL-card-footer d-flex justify-content-between align-items-center mt-3 p-2">
								<div className="PL-card-footer-location d-flex align-items-center">
									<Room style={{ fontSize: "16px", marginRight: "5px" }} />
									<Typography variant="body1" style={{ fontSize: "16px" }}>
										{punchItem.location}
									</Typography>
								</div>
								<div className="PL-card-footer-docs d-flex align-items-center">
									<Attachment
										style={{ fontSize: "16px", marginRight: "5px" }}
									/>
									<Typography variant="body1" style={{ fontSize: "16px" }}>
										{punchItem.totalPunchListDocuments || 0}
									</Typography>
								</div>
								<div className="PL-card-footer-status d-flex">
									<TaskStatus
										status={get(
											punchItem,
											"punchListStatusType.punchListStatusType",
											"New"
										)}
									/>
								</div>
							</div>
						</Paper>
					</div>
				);
			})}
			<div className="text-center">
				<HiddenOn
					isHidden={
						totalCount === 0 || punchListItems.length === 0 || totalCount <= 10
					}
				>
					{pageModel.pageSize < totalCount ? (
						<button
							className="secondaryButton"
							onClick={() => {
								setPageModel((prevState) => ({
									page: 0,
									pageSize: prevState.pageSize + 10,
								}));
							}}
						>
							Load More <KeyboardArrowDown fontSize="1rem" />
						</button>
					) : (
						<button
							className="secondaryButton"
							onClick={() => {
								setPageModel((prevState) => ({
									page: 0,
									pageSize: 10,
								}));
							}}
						>
							Show Less <KeyboardArrowUp fontSize="1rem" />
						</button>
					)}
				</HiddenOn>
			</div>
		</>
	) : (
		<div>
			<img
				src={noPunchListsImg}
				alt="No Data"
				style={{ placeSelf: "center" }}
				className="d-flex mt-2 mb-2"
			/>
			<Typography className="h3" style={{ textAlign: "center" }}>
				No Punch List Items
			</Typography>
		</div>
	);
};

export default MobilePunchListView;
