import {
  SETTINGS_COMPANY_TYPE_ERROR,
  SETTINGS_ADD_COMPANY_TYPE_ERROR,
  SETTINGS_ADD_COMPANY_TYPE_SUCCESS,
  SETTINGS_DELETE_COMPANY_TYPE_SUCCESS,
  SETTINGS_GET_COMPANY_TYPE_LOADING,
  SETTINGS_GET_COMPANY_TYPE,
  SETTINGS_COMPANY_TYPE_CLEAR_SNACKBAR,
} from "../constants/types";

const initialState = {
  companyType: [],
  loading: true,
  status: "success",
  text: "",
  showSnackbar: false,
};

const companyTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_COMPANY_TYPE_CLEAR_SNACKBAR:
      return {
        ...state,
        showSnackbar: false,
      };
    case SETTINGS_GET_COMPANY_TYPE:
      return {
        ...state,
        ...action.payload,
        showSnackbar: false,
      };
    case SETTINGS_GET_COMPANY_TYPE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SETTINGS_DELETE_COMPANY_TYPE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SETTINGS_ADD_COMPANY_TYPE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SETTINGS_ADD_COMPANY_TYPE_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    case SETTINGS_COMPANY_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };

    default:
      return state || {};
  }
};

export default companyTypeReducer;
