import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.documentType;

export const getDocumentType = createSelector(selectState, (documentType) => ({
  documentType: get(documentType, "documentType", []),
  totalCount: get(documentType, "totalCount", 0),
  loading: get(documentType, "loading", false),
  showSnackbar: get(documentType, "showSnackbar", false),
  status: get(documentType, "status", "success"),
  statusText: get(documentType, "text", ""),
}));
