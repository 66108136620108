import {
  SETTINGS_DOCUMENT_TYPE_ERROR,
  SETTINGS_ADD_DOCUMENT_TYPE_ERROR,
  SETTINGS_ADD_DOCUMENT_TYPE_SUCCESS,
  SETTINGS_DELETE_DOCUMENT_TYPE_SUCCESS,
  SETTINGS_GET_DOCUMENT_TYPE_LOADING,
  SETTINGS_GET_DOCUMENT_TYPE,
  SETTINGS_DOCUMENT_TYPE_CLEAR_SNACKBAR,
} from "../constants/types";

const initialState = {
  documentType: [],
  loading: true,
  status: "success",
  text: "",
  showSnackbar: false,
};

const documentTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_DOCUMENT_TYPE_CLEAR_SNACKBAR:
      return {
        ...state,
        showSnackbar: false,
      };
    case SETTINGS_GET_DOCUMENT_TYPE:
      return {
        ...state,
        ...action.payload,
        showSnackbar: false,
      };
    case SETTINGS_GET_DOCUMENT_TYPE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SETTINGS_DELETE_DOCUMENT_TYPE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SETTINGS_ADD_DOCUMENT_TYPE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SETTINGS_ADD_DOCUMENT_TYPE_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    case SETTINGS_DOCUMENT_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };

    default:
      return state || {};
  }
};

export default documentTypeReducer;
