import {
	hasSubscription,
	roleConstants as roles,
	rolesConfig,
	subscriptionConstants as plans,
} from "core/utils/roleUtils";
import {
	AddNewProperty,
	UnderDevelopment,
	ViewProject,
	ViewProperty,
} from "./loadables";
import Dashboard from "pages/dashboard/DashboardNew";
import EditProperty from "pages/property/EditProperty";
import AlertNotifications from "pages/alerts/AlertNotifications";
import { RedirectToProperty } from "pages/property/ViewProperty";
import VoiceAssistantLayout from "components/VoiceAssistantLayout/VoiceAssistantLayout";
import { voiceAssistantPath } from "constants/appConstants";
import ViewCompanyDetails from "pages/companies/ViewCompanyDetails";
import ProfilePage from "pages/profile/MyProfile";

export const new_routes = [
	{
		path: "/property/addNew/",
		component: <AddNewProperty />,
		roles: [
			roles.BUILDER_ADMIN,
			roles.BUILDER_GENERAL_MANAGER,
			roles.HOME_OWNER,
		],
	},
	{
		path: "/property/edit/:propertyId",
		component: <EditProperty />,
		roles: [
			roles.BUILDER_ADMIN,
			roles.BUILDER_GENERAL_MANAGER,
			roles.HOME_OWNER,
		],
	},
	{
		path: "/properties/:propertyId",
		component: <ViewProperty />,
	},
	{
		path: "/properties/:propertyId/projects",
		component: <RedirectToProperty />,
	},
	// {
	// 	path: "/properties/:propertyId/projects/:projectId",
	// 	component: <ViewProject />,
	// 	roles: [
	// 		roles.BUILDER_ADMIN,
	// 		roles.BUILDER_GENERAL_MANAGER,
	// 		...rolesConfig.projectViewRole,
	// 	],
	// },
	{
		path: "/properties/:propertyId/projects/:projectId/:tab?/:jobId?",
		component: <ViewProject />,
		roles: [
			roles.BUILDER_ADMIN,
			roles.BUILDER_GENERAL_MANAGER,
			roles.HOME_OWNER,
			...rolesConfig.projectViewRole,
		],
		shouldHide: (profile) => hasSubscription(profile, plans.FREE_SUBSCRIPTION),
	},
	{
		path: "builder/crm",
		component: <UnderDevelopment />,
		roles: [roles.BUILDER_ADMIN, roles.BUILDER_GENERAL_MANAGER],
		shouldHide: (profile) => hasSubscription(profile, plans.FREE_SUBSCRIPTION),
	},
	{
		path: "/builder/financials",
		component: <UnderDevelopment />,
		roles: [roles.BUILDER_ADMIN, roles.BUILDER_GENERAL_MANAGER],
	},
	{
		path: "/alerts",
		component: <AlertNotifications />,
	},
	{
		path: `/${voiceAssistantPath}`,
		component: <VoiceAssistantLayout />,
	},
	{
		path: "/companies/viewCompany/:companyId",
		component: <ViewCompanyDetails />,
		// shouldHide: (profile) => hasSubscription(profile, plans.FREE_SUBSCRIPTION),
	},
	{
		path: "/app/profile",
		component: <ProfilePage />,
	},
	{
		path: "/applelogin",
		component: <Dashboard />,
		redirect: "/dashboard",
	},
];
