import ActionConstants from "constants";
import {
	PAYMENT_LOADING,
	PAYMENT_GET_ALL_SUCCESS,
	PAYMENT_ERROR,
	PAYMENT_CLEAR_SNACKBAR,
} from "../constants/types";

const initialState = {
	paymentList: [],
	loading: true,
	status: "success",
	text: "",
	showSnackbar: false,
	subscriptionPlans: [],
};

const paymentReducer = (state = initialState, action) => {
	switch (action.type) {
		case PAYMENT_CLEAR_SNACKBAR:
			return {
				...state,
				showSnackbar: false,
			};
		case PAYMENT_LOADING:
			return {
				...state,
				loading: true,
			};
		case PAYMENT_ERROR:
			return {
				...state,
				paymentList: [],
				loading: false,
				status: action.payload.status,
				text: action.payload.text,
				showSnackbar: true,
			};
		case PAYMENT_GET_ALL_SUCCESS:
			return {
				...state,
				paymentList: action.payload.paymentList,
				loading: false,
				status: action.payload.status,
				text: action.payload.text,
				showSnackbar: true,
			};
		case ActionConstants.FETCH_SUBSCRIPTION_PLANS_SUCCESS:
			return {
				...state,
				subscriptionPlans: action.payload,
			};
		default:
			return state || {};
	}
};

export default paymentReducer;
