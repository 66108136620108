import * as yup from "yup";
import { statesList } from "core/utils/statesList";
import { getText } from "core/utils/getContentText";
import {
	formatNumberChange,
	formatNumberKeyDown,
} from "components/TextFields/MobileUtils";
import { InputAdornment } from "@mui/material";
import { isBuilderAdmin, isHomeOwner } from "core/utils/roleUtils";
import { phoneRegExp } from "constants/appConstants";

const getTextContent = (key) => getText(`projects.addProperty.${key}`);

const addPropertyValidationSchema = yup.object({
	address: yup.string("Enter address").required("address is required"),
	city: yup.string("Enter city").required("city is required"),
	state: yup.string("Enter state").required("state is required"),
	zip: yup.string("Enter zipcode").required("ZipCode is required"),
	propertyLocation: yup
		.string("Enter Property Location")
		.required("Property Location is required"),
	// homeOwnerName: yup
	// 	.string("Enter Homeowner Name")
	// 	.required("Homeowner Name is required"),
	homeOwnerMobile: yup
		.string("Enter your Mobile")
		.matches(phoneRegExp, "Phone number is not valid"),
	preliminarySalesPrice: yup
		.number("Enter Valid Sale Price")
		.typeError("Please enter a valid Sale Price")
		.required("Enter a Sale Price")
		.positive("Sales Price must be a positive number")
		.test(
			"is-valid",
			"Sales Price count must be a valid number",
			(value) => (value) =>
				value === undefined ||
				value === null ||
				/^[0-9]*\.?[0-9]+$/.test(value.toString())
		),
	plotArea: yup
		.number("Enter Plot Area (Sq.Ft.)")
		.typeError("Please enter a valid number")
		.integer("Plot Area must be an integer")
		.positive("Area must be a positive number")
		.nullable()
		.test(
			"is-valid",
			"Plot Area must be a valid value",
			(value) =>
				!value || value === "" || /^[0-9]*\.?[0-9]+$/.test(value.toString())
		),
});

export const addPropertyForm = {
	steps: Array.from({ length: 1 }, (_, index) =>
		getText(`profile.contractor.step${index + 1}`)
	),
	formFields: {
		step0: [
			{
				key: "propertyName",
				placeholder: "Property Name",
				label: getTextContent("propertyName"),
				type: "text",
				width: "100%",
			},
			{
				key: "address",
				placeholder: "Property Address",
				label: getTextContent("propertyAddress"),
				type: "textArea",
				required: true,
				width: "100%",
			},
			{
				key: "city",
				placeholder: "City",
				label: getTextContent("city"),
				type: "text",
				required: true,
				width: "33%",
			},
			{
				key: "state",
				placeholder: "state",
				label: getTextContent("state"),
				type: "select",
				select: true,
				options: statesList,
				required: true,
				width: "33%",
			},
			{
				key: "zip",
				placeholder: "Zip",
				label: getTextContent("zip"),
				type: "text",
				required: true,
				width: "33%",
			},
			// {
			// 	key: "homeOwnerName",
			// 	placeholder: "Homeowner Name",
			// 	label: getTextContent("homeownerName"),
			// 	type: "text",
			// 	required: true,
			// 	width: "50%",
			// },
			{
				key: "userId",
				placeholder: "Select Homeowner",
				label: getTextContent("homeownerName"),
				type: "select",
				// required: true,
				width: "50%",
				select: true,
				options: [],
				dynamicField: true,
				renderCondition: (_, profile) => {
					return !isHomeOwner(profile);
				},
			},
			{
				key: "companyId",
				placeholder: "Select Builder",
				label: "Select Builder",
				type: "select",
				// required: true,
				width: "50%",
				select: true,
				options: [],
				dynamicField: true,
				renderCondition: (_, profile) => {
					return !isBuilderAdmin(profile);
				},
			},
			{
				key: "homeOwnerMobile",
				placeholder: "(xxx) xxx-xxxx",
				label: getTextContent("homeownerMobile"),
				type: "mobile",
				// required: true,
				width: "50%",
				// renderCondition: (_, profile) => isHomeOwner(profile),
				extraInputProps: (formik) => ({
					onChange: (e) => {
						const onValidation = (validatedValue) => {
							formik.setFieldValue("homeOwnerMobile", validatedValue);
						};
						formatNumberChange(e, onValidation);
					},
					onKeyDown: (e) => {
						const onValidation = (validatedValue) => {
							formik.setFieldValue("homeOwnerMobile", validatedValue);
						};
						formatNumberKeyDown(e, onValidation);
					},
					InputProps: {
						startAdornment: (
							<InputAdornment position="start">
								<span class="flag-icon flag-icon-us"></span> +1
							</InputAdornment>
						),
					},
				}),
			},
			{
				key: "preliminarySalesPrice",
				placeholder: "Enter Sale Price",
				label: "Preliminary Sales Price",
				required: true,
				width: "50%",
				extraInputProps: (formik) => ({
					InputProps: {
						startAdornment: <InputAdornment position="start">$</InputAdornment>,
					},
				}),
			},
			{
				key: "plotArea",
				placeholder: "Enter Plot Area in Sq.Ft",
				label: "Plot Area (Sq.Ft.)",
				width: "50%",
			},
			{
				key: "propertyNotes",
				placeholder: "Enter notes/details related to property here",
				label: "Property Notes",
				type: "textArea",
				width: "100%",
				extraInputProps: {
					multiline: true,
					className: "registration-formField property-details-textarea",
				},
			},
		],
	},
	validationSchema: addPropertyValidationSchema,
	defaultState: (defaultState) => {
		if (defaultState) {
			return defaultState;
		}
		const initialState = Object.fromEntries(
			Object.keys(addPropertyValidationSchema.fields).map((key) => {
				return [key, ""];
			})
		);
		return {
			...initialState,
		};
	},
};
