import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.marketSector;

export const getMarketSector = createSelector(selectState, (marketSector) => ({
  marketSector: get(marketSector, "marketSector", []),
  totalCount: get(marketSector, "totalCount", 0),
  loading: get(marketSector, "loading", false),
  showSnackbar: get(marketSector, "showSnackbar", false),
  status: get(marketSector, "status", "success"),
  statusText: get(marketSector, "text", ""),
}));
