import React, { useEffect } from "react";
import get from "lodash/get";
import { MenuItem, Typography, useMediaQuery } from "@mui/material";
import DataGridTable from "components/Table/DataGrid";
import HiddenOn from "components/Wrappers/HiddenOn";
import { MOBILE_MAX_WIDTH_768 } from "constants/appConstants";
import { getMomentDate } from "core/utils/appUtils";
import { TaskStatus } from "pages/task/addTaskUtils";
import { Delete, EditOutlined } from "@mui/icons-material";
import BurgerMenu from "components/Menu/BurgerMenu";
import MobilePunchListView from "./punchList/MobilePunchListView";
import NewPuchItem from "./punchList/CreatePunchList";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPunchListsAction } from "actions/punchListActions";
import { getPunchLists } from "selectors/punchListSelector";
import ViewPunchItem from "./punchList/ViewPunchItem";
import ViewPunchItemMobile from "./punchList/ViewPunchItem_Mobile";
import CreatePunchListMobile from "./punchList/CreatePunchList_Mobile";

export const PunchListPage = ({ propertyId, projectId }) => {
	const dispatch = useDispatch();
	const isMobile = useMediaQuery(MOBILE_MAX_WIDTH_768);

	const { totalCount, punchListItems } = useSelector(getPunchLists);

	const [pageModel, setPageModel] = React.useState({ page: 0, pageSize: 10 });

	const [addEditPunchItem, setAddEditPunchItem] = React.useState(false);
	const [viewPunchItem, setViewPunchItem] = React.useState(false);
	const [selectedPunchItem, setSelectedPunchItem] = React.useState(null);

	const punchListColumns = [
		{
			field: "title",
			headerName: "Title",
			width: 150,
		},
		{
			field: "punchListCode",
			headerName: "Punch List #",
			width: 100,
		},
		{
			field: "description",
			headerName: "Description",
			width: 200,
		},
		{
			field: "priority",
			headerName: "Priority",
			width: 150,
			renderCell: ({ row }) => {
				const priority = get(row, "punchListPriority.punchListPriority", "");
				return (
					<TaskStatus
						status={priority || "Medium"}
						statusColors={statusColors}
					/>
				);
			},
		},
		{
			field: "dueDate",
			headerName: "Due Date",
			width: 100,
			valueFormatter: ({ row }) => {
				const dueDate = new Date().toISOString();
				return getMomentDate(dueDate);
			},
		},
		{
			field: "status",
			headerName: "Status",
			width: 100,
			renderCell: ({ row }) => {
				const priority = get(
					row,
					"punchListStatusType.punchListStatusType",
					""
				);
				return priority;
			},
		},
		{
			field: "assignedMember",
			headerName: "Assigned Member",
			width: 150,
		},
		{
			field: "assigedTo",
			headerName: "Assigned To",
			width: 150,
		},
		{
			field: "actions",
			type: "actions",
			flex: 1,
			align: "right",
			getActions: (rowData) => [
				<BurgerMenu>
					<MenuItem
						onClick={() => {
							setSelectedPunchItem({ id: rowData.id, data: rowData.row });
							setAddEditPunchItem(true);
						}}
					>
						<EditOutlined /> <p className="m-0">Edit</p>
					</MenuItem>
					<MenuItem onClick={() => {}}>
						<Delete /> Delete
					</MenuItem>
				</BurgerMenu>,
			],
		},
	];

	const fetchAllPunchLists = useCallback(() => {
		dispatch(
			fetchPunchListsAction({
				projectId,
				propertyId,
				pageNumber: pageModel.page + 1,
				pageSize: pageModel.pageSize,
			})
		);
	}, [dispatch, projectId, propertyId, pageModel]);

	useEffect(() => {
		fetchAllPunchLists();
	}, [fetchAllPunchLists]);

	const onPunchItemClick = (rowData) => {
		setViewPunchItem(true);
		setSelectedPunchItem({ id: rowData.punchListId, data: rowData });
	};

	const onViewCancel = () => {
		setViewPunchItem(false);
		setSelectedPunchItem(null);
	};

	const onAddEditCancel = () => {
		setAddEditPunchItem(false);
		if (!viewPunchItem) {
			setSelectedPunchItem(null);
		}
	};

	if (addEditPunchItem) {
		const create_punch_payload = {
			selectedPunchItem: selectedPunchItem,
			title: get(selectedPunchItem, "data.title", ""),
			onCancel: onAddEditCancel,
			propertyId: propertyId,
			projectId: projectId,
			params: {
				projectId,
				propertyId,
				pageNumber: pageModel.page + 1,
				pageSize: pageModel.pageSize,
			},
		};
		if (isMobile) {
			return <CreatePunchListMobile {...create_punch_payload} />;
		}
		return <NewPuchItem {...create_punch_payload} />;
	}

	if (viewPunchItem) {
		const viewPayload = {
			punchItemId: get(selectedPunchItem, "id", null),
			selectedPunchItem: selectedPunchItem,
			title: get(selectedPunchItem, "data.title", ""),
			onCancel: onViewCancel,
			onEditPunchItem: () => {
				setAddEditPunchItem(true);
			},
			propertyId,
			projectId,
		};
		if (isMobile) {
			return <ViewPunchItemMobile {...viewPayload} />;
		}
		return <ViewPunchItem {...viewPayload} />;
	}

	return (
		<>
			<div className="PL-tab-page" id="punchlist-tab-page">
				<div className="PL-page-content p-md-3 p-1" id="punchlist-page-content">
					<HiddenOn
						isHidden={isMobile}
						fallback={
							<MobilePunchListView
								pageModel={pageModel}
								setPageModel={setPageModel}
								totalCount={totalCount}
								punchListItems={punchListItems}
								onRowClick={(punchItem) => {
									onPunchItemClick(punchItem);
								}}
								createPunchItem={() => {
									console.log("on Click of CreatePunchItem");
									setAddEditPunchItem(true);
								}}
							/>
						}
						id="punchlist-desktop"
					>
						<div
							className="PL-page-header d-flex justify-content-between align-center m-3"
							id="punchlist-page-header"
						>
							<Typography className="h3">Punch List</Typography>
							<button
								className="primaryButton"
								id="create-punchlist-button"
								onClick={() => {
									setAddEditPunchItem(true);
								}}
							>
								Create Punch List
							</button>
						</div>
						<DataGridTable
							columns={punchListColumns}
							data={punchListItems}
							showToolbar={false}
							getRowId={(row) => row.punchListId}
							checkboxSelection={false}
							onRowClick={({ row }) => {
								onPunchItemClick(row);
							}}
							pagination={totalCount > 10}
							totalCount={totalCount}
							paginationModel={pageModel}
							onPaginationChange={(props) => {
								setPageModel(props);
							}}
							// onRowClick={(rowData) => {
							// 	setViewPunchItem(true);
							// 	setSelectedPunchItem({ id: rowData.id, data: rowData.row });
							// }}
						/>
					</HiddenOn>
				</div>
			</div>
		</>
	);
};

export default PunchListPage;

export const statusColors = {
	High: "#FF5630",
	Medium: "#FF9500",
	Low: "#36B37E",
};
export const mockPunchData = [
	{
		title: "Electrical Wiring Issue",
		punchItemId: "PL-001",
		description: "Fix faulty electrical wiring in Room 101.",
		priority: "High",
		dueDate: "2025-02-15",
		status: "InProgress",
		assignedMember: "John Doe",
		assigedTo: "Jane Smith",
		type: "Electrical",
		location: "Bedroom",
	},
	{
		title: "Plumbing Leak",
		punchItemId: "PL-002",
		description: "Repair the leaking pipe in the kitchen.",
		priority: "Medium",
		dueDate: "2025-02-10",
		status: "OnHold",
		assignedMember: "Alice Johnson",
		assigedTo: "Bob Brown",
		type: "Plumbing",
		location: "Kitchen",
	},
	{
		title: "Window Installation",
		punchItemId: "PL-003",
		description: "Install new windows in conference room.",
		priority: "Low",
		dueDate: "2025-03-01",
		status: "Completed",
		assignedMember: "Michael Lee",
		assigedTo: "Sarah Wilson",
		type: "Carpentry",
		location: "Hall",
	},
	{
		title: "Painting Touch-Up",
		punchItemId: "PL-004",
		description: "Touch up painting in the hallway.",
		priority: "High",
		dueDate: "2025-02-20",
		status: "New",
		assignedMember: "Daniel Harris",
		assigedTo: "Laura Clark",
		type: "Painting",
		location: "Bathroom",
	},
	{
		title: "Drywall Repair",
		punchItemId: "PL-005",
		description: "Fix holes in the drywall in the lounge area.",
		priority: "Medium",
		dueDate: "2025-01-30",
		status: "OnHold",
		assignedMember: "David Moore",
		assigedTo: "Olivia Taylor",
		type: "Drywall",
		location: "Lounge",
	},
	{
		title: "HVAC Maintenance",
		punchItemId: "PL-006",
		description: "Conduct maintenance on HVAC system.",
		priority: "Low",
		dueDate: "2025-02-12",
		status: "InProgress",
		assignedMember: "Liam Miller",
		assigedTo: "Emma Davis",
		type: "HVAC",
		location: "Basement",
	},
	{
		title: "Flooring Repair",
		punchItemId: "PL-007",
		description: "Fix the damaged flooring in the lobby.",
		priority: "High",
		dueDate: "2025-02-18",
		status: "New",
		assignedMember: "William Brown",
		assigedTo: "Sophia Martinez",
		type: "Flooring",
		location: "Lobby",
	},
	{
		title: "Door Lock Installation",
		punchItemId: "PL-008",
		description: "Install new locks on the office doors.",
		priority: "Medium",
		dueDate: "2025-03-10",
		status: "Completed",
		assignedMember: "James Wilson",
		assigedTo: "Grace Lee",
		type: "Security",
		location: "Office",
	},
	{
		title: "Ceiling Fan Repair",
		punchItemId: "PL-009",
		description: "Repair the broken ceiling fan in Room 203.",
		priority: "Low",
		dueDate: "2025-02-25",
		status: "OnHold",
		assignedMember: "Ella Harris",
		assigedTo: "Mason Clark",
		type: "Electrical",
		location: "Bedroom",
	},
	{
		title: "Exterior Painting",
		punchItemId: "PL-010",
		description: "Repaint the exterior of the building.",
		priority: "High",
		dueDate: "2025-04-01",
		status: "New",
		assignedMember: "Lucas Walker",
		assigedTo: "Amelia Robinson",
		type: "Painting",
		location: "Exterior",
	},
	{
		title: "Kitchen Appliances Installation",
		punchItemId: "PL-011",
		description: "Install new kitchen appliances.",
		priority: "Medium",
		dueDate: "2025-02-05",
		status: "Completed",
		assignedMember: "Mia Lee",
		assigedTo: "Ethan King",
		type: "Appliances",
		location: "Kitchen",
	},
	{
		title: "Fire Alarm Testing",
		punchItemId: "PL-012",
		description: "Test fire alarms across all floors.",
		priority: "Low",
		dueDate: "2025-02-08",
		status: "New",
		assignedMember: "Matthew Scott",
		assigedTo: "Isabella Adams",
		type: "Safety",
		location: "All Floors",
	},
	{
		title: "Security System Update",
		punchItemId: "PL-013",
		description: "Update the building's security system.",
		priority: "High",
		dueDate: "2025-02-22",
		status: "InProgress",
		assignedMember: "Alexander Evans",
		assigedTo: "Charlotte Young",
		type: "Security",
		location: "Security Room",
	},
	{
		title: "Fence Repair",
		punchItemId: "PL-014",
		description: "Fix broken sections of the perimeter fence.",
		priority: "Medium",
		dueDate: "2025-03-15",
		status: "OnHold",
		assignedMember: "Daniel Thomas",
		assigedTo: "Benjamin Moore",
		type: "Carpentry",
		location: "Perimeter",
	},
	{
		title: "Waterproofing Basement",
		punchItemId: "PL-015",
		description: "Apply waterproofing to the basement walls.",
		priority: "Low",
		dueDate: "2025-02-17",
		status: "InProgress",
		assignedMember: "Sophia Harris",
		assigedTo: "Jack Walker",
		type: "Waterproofing",
		location: "Basement",
	},
	{
		title: "Signage Replacement",
		punchItemId: "PL-016",
		description: "Replace old signage in the parking lot.",
		priority: "High",
		dueDate: "2025-02-28",
		status: "Completed",
		assignedMember: "Ryan Lewis",
		assigedTo: "Ava White",
		type: "Signage",
		location: "Parking Lot",
	},
	{
		title: "Fire Exit Clearances",
		punchItemId: "PL-017",
		description: "Ensure all fire exits are clear of obstruction.",
		priority: "Medium",
		dueDate: "2025-02-11",
		status: "OnHold",
		assignedMember: "Zoe Davis",
		assigedTo: "Oliver Hall",
		type: "Safety",
		location: "All Floors",
	},
	{
		title: "Wall Insulation",
		punchItemId: "PL-018",
		description: "Install additional insulation in outer walls.",
		priority: "Low",
		dueDate: "2025-03-05",
		status: "New",
		assignedMember: "Ella Wright",
		assigedTo: "Lucas Carter",
		type: "Insulation",
		location: "Outer Walls",
	},
	{
		title: "Elevator Inspection",
		punchItemId: "PL-019",
		description: "Perform inspection and servicing of the elevators.",
		priority: "High",
		dueDate: "2025-02-25",
		status: "Completed",
		assignedMember: "James Carter",
		assigedTo: "Mia Clark",
		type: "Safety",
		location: "Elevators",
	},
];
