import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.punchListPriority;

export const getPunchListPriority = createSelector(selectState, (punchListPriority) => ({
    punchListPriority: get(punchListPriority, "punchListPriority", []),
  totalCount: get(punchListPriority, "totalCount", 0),
  loading: get(punchListPriority, "loading", false),
  showSnackbar: get(punchListPriority, "showSnackbar", false),
  status: get(punchListPriority, "status", "success"),
  statusText: get(punchListPriority, "text", ""),
}));
