import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.punchListType;

export const getPunchListType = createSelector(
  selectState,
  (punchListType) => ({
    punchListType: get(punchListType, "punchListType", []),
    totalCount: get(punchListType, "totalCount", 0),
    loading: get(punchListType, "loading", false),
    showSnackbar: get(punchListType, "showSnackbar", false),
    status: get(punchListType, "status", "success"),
    statusText: get(punchListType, "text", ""),
  })
);
