import { PhotoCamera } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import React, { useState, useRef } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const ProfilePictureUpload = () => {
	const [image, setImage] = useState(null);
	const [crop, setCrop] = useState({
		unit: "%",
		width: 100,
		height: 100,
		x: 0,
		y: 0,
		aspect: 1,
	});
	const [completedCrop, setCompletedCrop] = useState(null);
	const imageRef = useRef(null);
	const previewCanvasRef = useRef(null);

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = () => {
				setImage(reader.result);
			};
			reader.readAsDataURL(file);
		}
	};

	const onLoad = (img) => {
		imageRef.current = img;
	};

	const generateCrop = async () => {
		if (!completedCrop || !imageRef.current) return;

		const canvas = document.createElement("canvas");
		const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
		const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
		const ctx = canvas.getContext("2d");

		canvas.width = completedCrop.width;
		canvas.height = completedCrop.height;

		ctx.drawImage(
			imageRef.current,
			completedCrop.x * scaleX,
			completedCrop.y * scaleY,
			completedCrop.width * scaleX,
			completedCrop.height * scaleY,
			0,
			0,
			completedCrop.width,
			completedCrop.height
		);

		return new Promise((resolve) => {
			canvas.toBlob(
				(blob) => {
					resolve(blob);
				},
				"image/jpeg",
				1
			);
		});
	};

	const handleSave = async () => {
		try {
			const croppedImage = await generateCrop();
			if (!croppedImage) return;

			const formData = new FormData();
			formData.append("profile_picture", croppedImage, "profile.jpg");

			const response = await fetch("YOUR_API_ENDPOINT", {
				method: "POST",
				body: formData,
				headers: {
					Authorization: "Bearer YOUR_AUTH_TOKEN",
				},
			});

			if (response.ok) {
				// Handle successful upload
				alert("Profile picture updated successfully!");
			} else {
				throw new Error("Failed to upload image");
			}
		} catch (error) {
			console.error("Error uploading image:", error);
			alert("Failed to upload image");
		}
	};

	return (
		<div className="flex flex-col items-center space-y-4">
			{/* Avatar Container */}
			<div className="relative w-32 h-32 rounded-full overflow-hidden bg-gray-200">
				{image ? (
					<div className="w-full h-full">
						<ReactCrop
							crop={crop}
							onChange={(c) => {
								console.log("Check on Change");
								setCrop(c);
							}}
							onComplete={(c) => {
								console.log("Check on Complete ");
								setCompletedCrop(c);
							}}
							aspect={1}
							circularCrop
						>
							<Avatar
								src={image}
								alt={"Profile"}
								sx={{ width: 120, height: 120 }}
							/>
						</ReactCrop>
					</div>
				) : (
					<div className="w-full h-full flex items-center justify-center pointer">
						<button className="primaryButton">
							<PhotoCamera /> Upload Image
						</button>
						{/* <Avatar
							onClick={() => document.getElementById("fileInput").click()}
							sx={{ width: 120, height: 120 }}
						/> */}
					</div>
				)}
			</div>

			{/* Buttons */}
			<div className="flex space-x-2">
				<button
					// onClick={() => document.getElementById("fileInput").click()}
					className="secondaryButton w-auto"
					style={{ alignSelf: "end", width: "auto" }}
				>
					<PhotoCamera style={{ fontSize: "14px" }} /> Change Photo
				</button>
				{image && (
					<button
						onClick={() => handleSave()}
						className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
					>
						Save
					</button>
				)}
			</div>

			{/* Hidden file input */}
			<input
				type="file"
				id="fileInput"
				className="hidden"
				style={{ display: "none" }}
				accept="image/*"
				onChange={handleFileChange}
			/>
		</div>
	);
};

export default ProfilePictureUpload;
