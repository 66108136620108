import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./tabs.scss";

export default function RenderTabs({
  tabs,
  selectedTab,
  onTabClick,
  tabParams = {},
  orientation = "horizontal",
}) {
  const handleChange = (event, newValue) => {
    onTabClick(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            variant="scrollable"
            scrollButtons="auto"
            className="tab-list"
            orientation={orientation}
          >
            {tabs.map((tab, index) => {
              if (tab.hide && tab.hide(tabParams)) {
                return null;
              }
              return (
                <Tab
                  label={tab.label}
                  value={tab.key}
                  className="tab-button"
                  id={`tab-${index}`}
                />
              );
            })}
          </TabList>
        </Box>
        {tabs.map((TC) => {
          return (
            <TabPanel value={TC.key}>
              <TC.TabComponent tabConfig={TC} {...tabParams} {...TC.tabProps} />
            </TabPanel>
          );
        })}
      </TabContext>
    </Box>
  );
}
