export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";

export const ADD_NEW_USER = "ADD_NEW_USER";
export const ADD_NEW_USER_SUCCESS = "ADD_NEW_USER_SUCCESS";
export const ADD_NEW_USER_ERROR = "ADD_NEW_USER_ERROR";

export const INVITE_NEW_USER = "INVITE_NEW_USER";
export const INVITE_NEW_USER_SUCCESS = "INVITE_NEW_USER_SUCCESS";
export const INVITE_NEW_USER_ERROR = "INVITE_NEW_USER_ERROR";

export const SAVE_TIME_SHEET = "SAVE_TIME_SHEET";
export const SAVE_TIME_SHEET_SUCCESS = "SAVE_TIME_SHEET_SUCCESS";
export const SAVE_TIME_SHEET_ERROR = "SAVE_TIME_SHEET_ERROR";

export const GET_TIME_SHEET = "GET_TIME_SHEET";
export const GET_TIME_SHEET_SUCCESS = "GET_TIME_SHEET_SUCCESS";
export const GET_TIME_SHEET_ERROR = "GET_TIME_SHEET_ERROR";

export const GET_PROJECT_ROLES = "GET_PROJECT_ROLES";
export const GET_PROJECT_ROLES_SUCCESS = "GET_PROJECT_ROLES_SUCCESS";
export const GET_PROJECT_ROLES_ERROR = "GET_PROJECT_ROLES_ERROR";

export const GET_PROJECT_USERS_ROLES = "GET_PROJECT_USERS_ROLES";
export const GET_PROJECT_USERS_ROLES_SUCCESS =
	"GET_PROJECT_USERS_ROLES_SUCCESS";
export const GET_PROJECT_USERS_ROLES_ERROR = "GET_PROJECT_USERS_ROLES_ERROR";

export const DELETE_PROJECT_USER_ROLE = "DELETE_PROJECT_USER_ROLE";
export const DELETE_PROJECT_USER_ROLE_SUCCESS =
	"DELETE_PROJECT_USER_ROLE_SUCCESS";
export const DELETE_PROJECT_USER_ROLE_ERROR = "DELETE_PROJECT_USER_ROLE_ERROR";

export const GET_GUID_INVITE_DETAILS = "GET_GUID_INVITE_DETAILS";
export const GET_GUID_INVITE_DETAILS_SUCCESS =
	"GET_GUID_INVITE_DETAILS_SUCCESS";
export const GET_GUID_INVITE_DETAILS_ERROR = "GET_GUID_INVITE_DETAILS_ERROR";

export const UPDATE_USER_PICTURE = "UPDATE_USER_PICTURE";
export const UPDATE_USER_PICTURE_SUCCESS = "UPDATE_USER_PICTURE_SUCCESS";
export const UPDATE_USER_PICTURE_ERROR = "UPDATE_USER_PICTURE_ERROR";
