import React, { useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
	Typography,
	IconButton,
	Grid,
	Box,
	LinearProgress,
} from "@mui/material";
import { addPunchListForm, addPunchListFields } from "./addPunchListUtils";
import get from "lodash/get";
import { CloseOutlined } from "@mui/icons-material";
import "./punchList.scss";
import FormInputField from "components/TextFields/FormInputField";

import FileUpload from "components/FileUpload/FileUpload";
import FilesList from "components/FileList/FileList";
import { fetchFiles } from "actions/documentsActions";
import {
	selectAllFiles,
	selectIsUploading,
} from "selectors/documentsSelectors";

import {
	addUpdatePunchListAction,
	fetchPunchPriorityListAction,
	fetchPunchStatusListAction,
	fetchPunchTypeListAction,
} from "actions/punchListActions";
import { getDynamicPunchOptions } from "selectors/punchListSelector";
import { fetchJobAssigneesListAction } from "actions/jobActions";
import { getAssigneeList } from "selectors/jobSelectors";
import { isSystemAdmin } from "core/utils/roleUtils";
import AppRoleContext from "context/AppRoleContext";

// const filter = createFilterOptions();

export const NewPuchItem = ({
	propertyId,
	onCancel,
	projectId,
	selectedPunchItem = {},
	title,
	params,
	fromProperty,
}) => {
	const dispatch = useDispatch();
	const punchItemId = get(selectedPunchItem, "data.punchListId", null);
	const appRoleDetails = useContext(AppRoleContext);
	const { validationSchema } = addPunchListForm;
	const initialState = addPunchListForm.defaultState(
		get(selectedPunchItem, "data", null)
	);
	const formik = useFormik({
		initialValues: initialState,
		validationSchema: validationSchema,
		validateOnMount: false,
		validateOnChange: true,
		validateOnBlur: true,
		onSubmit: (event) => {
			console.log("Check the Event : ", formik.values);
			dispatch(
				addUpdatePunchListAction({
					formData: {
						...formik.values,
						projectId: projectId,
						propertyId: propertyId,
					},
					filesData: { filesState, fileLabelsState, documentTypeState },
					onSuccess: onCancel,
					params,
				})
			);
		},
		// initialTouched: get(selectedProject, "data", null),
	});

	//Files Upload and Retrival
	const [filesState, setFilesState] = React.useState([]);
	const [fileLabelsState, setFileLabelsState] = React.useState({});
	const [documentTypeState, setDocumentTypeState] = React.useState("");

	const existingFiles = useSelector(selectAllFiles);

	const isUploading = useSelector(selectIsUploading);

	const punchOptions = useSelector(getDynamicPunchOptions);

	const { assignedTo } = useSelector(getAssigneeList);

	const dynamicOptions = {
		...punchOptions,
		companyId: isSystemAdmin(appRoleDetails)
			? []
			: assignedTo.map((company) => ({
					key: company.companyId,
					text: company.companyName,
			  })),
		// companyId: assignedTo,
	};

	const fetchExistingDocs = useCallback(() => {
		if (punchItemId) {
			dispatch(fetchFiles("PunchList", punchItemId));
		}
	}, [dispatch, punchItemId]);

	useEffect(() => {
		fetchExistingDocs();
	}, [fetchExistingDocs]);

	useEffect(() => {
		dispatch(fetchPunchTypeListAction());
		dispatch(fetchPunchPriorityListAction());
		dispatch(fetchPunchStatusListAction());
		dispatch(fetchJobAssigneesListAction());
	}, [dispatch, projectId]);

	const handleSubmit = (e) => {
		e.preventDefault();
		formik.handleSubmit();
		// Touch all fields on submit to show validation errors
		const allFields = addPunchListFields.reduce(
			(acc, field) => ({
				...acc,
				[field.key]: true,
			}),
			{}
		);
		formik.setTouched(allFields, true);
	};

	return (
		<div className="mt-0 p-1" style={{ position: "relative" }}>
			<>
				<div className="d-flex justify-content-between">
					<Typography className="text-left m-2 h1" id="header">
						{title || "Create New Punch"}
					</Typography>
					<IconButton onClick={() => onCancel()}>
						<CloseOutlined />
					</IconButton>
				</div>
				<div id="create-project" className="mt-2">
					<form
						onKeyDown={(e) => {
							if (e.key === "Enter") e.preventDefault(); // Prevent "Enter" key submission
						}}
						onSubmit={handleSubmit}
					>
						<div id="container row">
							<div id="section-1 mt-3">
								<div className="form-fields col-12 ">
									<div className="container row justify-content-between">
										{addPunchListFields.map((field) => {
											const extraInputProps = get(field, "extraInputProps", {});

											const extraProps =
												typeof extraInputProps === "function"
													? extraInputProps(formik)
													: extraInputProps;

											return (
												<FormInputField
													field={{
														...field,
														options: dynamicOptions[field.key] || field.options,
													}}
													formik={formik}
													value={formik.values[field.key]}
													key={field.key}
													id={field.id}
													appRoleDetails={appRoleDetails}
													dynamicOptions={dynamicOptions}
													{...extraProps}
												/>
											);
										})}
									</div>
								</div>
							</div>
							<div data-testid="document-upload" className="mt-3">
								<div className="d-flex flex-wrap col-12 pt-5 mb-1">
									<div className={"col-12 "}>
										{/* <div className="d-flex flex-wrap col-12"> */}
										<h5>Documents</h5>

										<FileUpload
											setFilesState={setFilesState}
											setFileLabelsState={setFileLabelsState}
											setDocumentTypeState={setDocumentTypeState}
										/>
										{/* Pass the files, labels, upload status, and existing files to FilesList */}
										{punchItemId && (
											<FilesList
												files={existingFiles || []}
												fileLabels={fileLabelsState}
												uploadStatus={{}}
											/>
										)}
										{isUploading && (
											<Grid
												container
												spacing={0}
												direction="column"
												alignItems="center"
												justifyContent="center"
											>
												<Box sx={{ width: "100%" }}>
													<Typography variant="body1">
														Uploading Documents
													</Typography>
													<LinearProgress size="3rem" />
												</Box>
											</Grid>
										)}
									</div>
								</div>
							</div>
							<div>
								<Typography variant="body1" className="mt-1 ">
									<sup style={{ color: "red", fontSize: "1em", top: 0 }}>*</sup>
									<strong>Required Fields</strong>
								</Typography>
							</div>
							<div className="mt-2">
								<div className="d-flex justify-content-center align-self-center">
									<button
										// type="submit"
										className={`secondaryButton m-3`}
										onClick={() => {
											onCancel();
										}}
									>
										{"Cancel"}
									</button>
									<button
										// type="submit"
										className={`primaryButton m-3`}
										style={{ width: "auto" }}
									>
										{punchItemId ? "Save Changes" : "Create Punch"}
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</>
		</div>
	);
};

export default NewPuchItem;
