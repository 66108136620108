import {
  SETTINGS_SUBSCRIPTION_CLEAR_SNACKBAR,
  SETTINGS_GET_SUBSCRIPTION,
  SETTINGS_GET_SUBSCRIPTION_LOADING,
  SETTINGS_DELETE_SUBSCRIPTION_SUCCESS,
  SETTINGS_ADD_SUBSCRIPTION_SUCCESS,
  SETTINGS_ADD_SUBSCRIPTION_ERROR,
  SETTINGS_SUBSCRIPTION_ERROR,
} from "../constants/types";

const initialState = {
  subscription: [],
  loading: true,
  status: "success",
  text: "",
  showSnackbar: false,
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_SUBSCRIPTION_CLEAR_SNACKBAR:
      return {
        ...state,
        showSnackbar: false,
      };
    case SETTINGS_GET_SUBSCRIPTION:
      return {
        ...state,
        ...action.payload,
        showSnackbar: false,
      };
    case SETTINGS_GET_SUBSCRIPTION_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SETTINGS_DELETE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SETTINGS_ADD_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SETTINGS_ADD_SUBSCRIPTION_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    case SETTINGS_SUBSCRIPTION_ERROR:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };

    default:
      return state || {};
  }
};

export default subscriptionReducer;
