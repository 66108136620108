import React from "react";
import {
	TextField,
	MenuItem,
	Box,
	Chip,
	Typography,
	FormHelperText,
	Autocomplete,
	CircularProgress,
} from "@mui/material";
import { isUndefined } from "lodash";
import DatePickerField from "components/DateComponets/DatePicker";
import dayjs from "dayjs";

export const FormInputField = ({
	field,
	value,
	formik,
	className = "",
	renderOptions = null,
	...rest
}) => {
	if (
		field?.shouldHide &&
		field.shouldHide({ field, value, formik, renderOptions, ...rest })
	) {
		console.log(
			"Inside shouldHide condition : ",
			field.shouldHide({ field, value, formik, renderOptions, ...rest })
		);
		return null;
	}
	if (field.type === "dateField") {
		return <FormDateField field={field} formik={formik} {...rest} />;
	}
	if (field.dynamicField) {
		return <FormAutoCompleteField field={field} formik={formik} {...rest} />;
	}
	return (
		<div
			className={`form-field-wrapper ${field.className}`}
			style={{ width: field.width }}
		>
			<FormLabel text={field.label} required={field.required} />
			<TextField
				key={field.key}
				id={field.key}
				type={field.type}
				select={field.select}
				name={field.key}
				fullWidth
				variant="outlined"
				placeholder={field.placeholder}
				className={`registration-formField ${className || field.className}`}
				value={value}
				disabled={field.disabled}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={formik.touched[field.key] && Boolean(formik.errors[field.key])}
				helperText={formik.touched[field.key] && formik.errors[field.key]}
				SelectProps={{
					displayEmpty: true,
					multiple: field.multiple,
					renderValue: (selected) => {
						if (field.multiple) {
							if (selected && selected.length === 0) {
								return <em>{field.placeholder}</em>;
							}
							return (
								<Box
									sx={{
										display: "flex",
										flexWrap: "wrap",
										gap: 0.5,
										marginTop: "-0.75rem",
									}}
								>
									{selected &&
										selected.length > 0 &&
										selected.map(
											(value) =>
												value && (
													<Chip
														key={value}
														label={value}
														style={{ fontSize: "12px" }}
														variant="outlined"
														onDelete={() => {
															formik.setFieldValue(
																field.key,
																selected.filter((item) => item !== value)
															);
														}}
													/>
												)
										)}
								</Box>
							);
						} else {
							return (
								<Typography>
									{typeof field.options[0] === "string"
										? selected
										: field.options.find((option) => option.key === selected)
												?.text}
								</Typography>
							);
						}
					},
				}}
				{...rest}
			>
				<MenuItem value={field?.placeholder || null} disabled>
					{field.placeholder}
				</MenuItem>
				{renderOptions
					? renderOptions()
					: field.options &&
					  field.options.map((option) => {
							return !isUndefined(option.key) ? (
								<MenuItem
									key={option.key}
									value={option.key}
									selected={formik.values[field.key] === option.key}
								>
									{option.text}
								</MenuItem>
							) : (
								<MenuItem value={option}>{option}</MenuItem>
							);
					  })}
			</TextField>
		</div>
	);
};

export const FormDateField = ({ field, formik, extraProps }) => {
	return (
		<div
			className={`form-field-wrapper ${field.className}`}
			style={{ width: field.width }}
		>
			<FormLabel text={field.label} required={field.required} />
			<DatePickerField
				onChange={(date) => {
					if (
						!date ||
						dayjs(date).isValid() === false ||
						dayjs(date).year() === 1970
					) {
						formik.setFieldValue(field.key, null); // Explicitly set to null
					} else {
						formik.setFieldValue(field.key, date);
					}
				}}
				onClear={() => {
					formik.setFieldValue(field.key, null);
				}}
				value={formik.values[field.key] || null}
				field={field}
				slotProps={{
					field: {
						clearable: !field.required,
						onClear: () => {
							formik.setFieldValue(field.key, null);
						},
					},
				}}
				{...extraProps}
			/>
			{formik.errors[field.key] && (
				<FormHelperText
					style={{ fontSize: "12px" }}
					error
					text={formik.errors[field.key]}
				>
					{formik.errors[field.key]}
				</FormHelperText>
			)}
		</div>
	);
};

export const FormAutoCompleteField = ({
	field,
	formik,
	extraProps,
	dynamicOptions,
	...rest
}) => {
	const [autoCompleteOpen, setAutoCompleteOpen] = React.useState(false);
	return (
		<div
			className={`form-field-wrapper ${field.className}`}
			style={{ width: field.width }}
		>
			<FormLabel text={field.label} required={field.required} />
			<Autocomplete
				freeSolo
				onChange={(_, value) => {
					formik.setFieldValue(field.key, value?.key);
				}}
				id="companies-autocomplete"
				value={
					dynamicOptions[field.key].find(
						(option) => option.key === formik.values[field.key]
					) || null
				}
				className="form-field autocomplete-field"
				open={autoCompleteOpen}
				onOpen={() => setAutoCompleteOpen(true)}
				onClose={() => setAutoCompleteOpen(false)}
				isOptionEqualToValue={(option, value) => {
					return option.key === value.companyId;
				}}
				getOptionLabel={(option) => {
					return option.text;
				}}
				options={dynamicOptions[field.key]}
				renderInput={(params) => (
					<TextField
						{...params}
						className="form-field"
						onChange={(event, value) => {
							const textLen = event.target.value.length;
							if (textLen >= 3) {
								console.log("Fetching the record");
							}
						}}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{false ? (
										<CircularProgress color="inherit" size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
			/>
		</div>
	);
};

export const RequiredSup = () => {
	return <sup style={{ color: "red", fontSize: "1em", top: 0 }}>*</sup>;
};

export const FormLabel = ({ className, required, text }) => {
	return (
		<p className={`form-label ${className}`}>
			<label>
				{text}
				{required && <RequiredSup />}
			</label>
		</p>
	);
};

export default FormInputField;
