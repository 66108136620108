import axios from "axios";
import {
  SETTINGS_SUBSCRIPTION_CLEAR_SNACKBAR,
  SETTINGS_SUBSCRIPTION_ERROR,
  SETTINGS_DELETE_SUBSCRIPTION_SUCCESS,
  SETTINGS_GET_SUBSCRIPTION_LOADING,
  SETTINGS_ADD_SUBSCRIPTION_ERROR,
  SETTINGS_ADD_SUBSCRIPTION_SUCCESS,
  SETTINGS_ADD_SUBSCRIPTION,
  SETTINGS_GET_SUBSCRIPTION,
} from "../constants/types";
import queryString from "query-string";

import { server_url } from "../constants/types";
import request from "config/api";

export const fetchSubscription = (params = {}) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: SETTINGS_GET_SUBSCRIPTION_LOADING,
        payload: {
          loading: true,
        },
      });
      const newParams = {
        PageNumber: params.page ? params.page + 1 : 1,
        PageSize: params.pageSize ? params.pageSize : 10,
      };
      const queryParams = queryString.stringify(newParams);

      request({
        url: `${server_url}/Subscription`,
        method: "GET",
      })
        .then((response) => {
          dispatch({
            type: SETTINGS_GET_SUBSCRIPTION,
            payload: {
              subscription: response.data.filter(
                (subs) => subs.deleted !== "Y"
              ),
              totalCount: response.data.length,
              loading: false,
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: SETTINGS_SUBSCRIPTION_ERROR,
            payload: {
              status: "error",
              text: "Error occured during getting all Subscription.",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};

export const addUpdateSubscription = ({ formData, onSuccess, payload }) => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: SETTINGS_ADD_SUBSCRIPTION,
        payload: {
          loading: true,
        },
      });

      let data = {
        ...formData,
      };

      request({
        method: formData.subscriptionId ? "PUT" : "POST",
        url: `${server_url}/Subscription${
          formData.subscriptionId ? `/${formData.subscriptionId}` : ""
        }`,
        body: { ...data },
      })
        .then(async (response) => {
          onSuccess();

          dispatch({
            type: SETTINGS_ADD_SUBSCRIPTION_SUCCESS,
            payload: {
              response: response.data,
              status: "success",
              text: formData.subscriptionId
                ? "Subscription updated Successfully"
                : "Subscription Created Successfully",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        })
        .catch((e) => {
          dispatch({
            type: SETTINGS_ADD_SUBSCRIPTION_ERROR,
            payload: {
              text: "Error occurred during creating subscription data.",
              status: "error",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};

export const subscriptionDelete = ({
  subscriptionId,
  pageModel,
  onSuccess = () => {},
  showSnackbar = true,
}) => {
  console.log("Check the subscriptionDelete Action", subscriptionId);
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      dispatch({
        type: SETTINGS_GET_SUBSCRIPTION_LOADING,
        payload: {
          loading: true,
        },
      });
      request({
        url: `${server_url}/Subscription/${subscriptionId}`,
        method: "DELETE",
      })
        .then((response) => {
          dispatch(fetchSubscription({ ...pageModel }));
          onSuccess();

          dispatch({
            type: SETTINGS_DELETE_SUBSCRIPTION_SUCCESS,
            payload: {
              response: response.data,
              status: "success",
              text: "subscription Deleted Successfully",
              loading: false,
              showSnackbar: true,
            },
          });

          resolve();
        })
        .catch((e) => {
          dispatch({
            type: SETTINGS_SUBSCRIPTION_ERROR,
            payload: {
              text: "Error occurred during deleting subscription data.",
              status: "error",
              loading: false,
              showSnackbar: true,
            },
          });
          resolve();
        });
    });
  };
};

export const subscriptionClearShowSnackbar = () => {
  return (dispatch, getState) => {
    dispatch({
      type: SETTINGS_SUBSCRIPTION_CLEAR_SNACKBAR,
    });
  };
};
