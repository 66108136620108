import {
  SETTINGS_CONNECTION_STATUS_CLEAR_SNACKBAR,
  SETTINGS_GET_CONNECTION_STATUS,
  SETTINGS_GET_CONNECTION_STATUS_LOADING,
  SETTINGS_DELETE_CONNECTION_STATUS_SUCCESS,
  SETTINGS_ADD_CONNECTION_STATUS_SUCCESS,
  SETTINGS_ADD_CONNECTION_STATUS_ERROR,
  SETTINGS_CONNECTION_STATUS_ERROR,
} from "../constants/types";

const initialState = {
  statusName: [],
  loading: true,
  status: "success",
  text: "",
  showSnackbar: false,
};

const connectionStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_CONNECTION_STATUS_CLEAR_SNACKBAR:
      return {
        ...state,
        showSnackbar: false,
      };
    case SETTINGS_GET_CONNECTION_STATUS:
      return {
        ...state,
        ...action.payload,
        showSnackbar: false,
      };
    case SETTINGS_GET_CONNECTION_STATUS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SETTINGS_DELETE_CONNECTION_STATUS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SETTINGS_ADD_CONNECTION_STATUS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SETTINGS_ADD_CONNECTION_STATUS_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    case SETTINGS_CONNECTION_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };

    default:
      return state || {};
  }
};

export default connectionStatusReducer;
