import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IconButton,
  Typography,
  TextField,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import DataGridTable from "components/Table/DataGrid";

import { useParams } from "react-router-dom";
import get from "lodash/get";
import NoResultsFound from "components/NoResults/NoResultsFound";
import AppRoleContext from "context/AppRoleContext";
import {
  warrantyDelete,
  fetchWarranties,
  warrantyClearShowSnackbar,
} from "actions/warrantyActions";
import AddNewWarranty from "./AddNewWarranty";
import { warrantyListColumns } from "./warrantyUtils";
import { getWarranties } from "selectors/warrantySelectors";
import {
  PROJECT_DELETE_WARRANTY_SUCCESS,
  PROJECT_WARRANTY_STATUS_ERROR,
} from "constants/types";
import { Button } from "react-bootstrap";

export const Warranty = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { warranties, totalCount, loading, showSnackbar, status, statusText } =
    useSelector(getWarranties);

  const [addNewJob, setAddNewJob] = useState(false);

  const [pageModel, setPageModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [editJob, setEditJob] = useState(false);
  const [addCompany, setAddCompany] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [userManagement, setUserManagement] = useState(false);
  const [jobActionsContext, setJobActionsContext] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [makeDelete, setMakeDelete] = useState(false);

  const appRoleDetails = useContext(AppRoleContext);

  useEffect(() => {
    dispatch(
      fetchWarranties({
        ...pageModel,
        PropertyId: params.propertyId,
        ProjectId: params.projectId,
      })
    );
  }, [dispatch, pageModel]);

  useEffect(() => {
    console.log("selected", selectedJob);
  }, [selectedJob]);

  const promptDelete = () => {
    setShowConfirmModal(true);
  };
  useEffect(() => {
    setShowConfirmModal(false);
    if (makeDelete) {
      bulkDelete(selectedRows);
      setMakeDelete(false);
    }
  }, [makeDelete]);

  const bulkDelete = async (selectedFiles) => {
    try {
      const results = await Promise.allSettled(
        selectedFiles.map((fileId) =>
          dispatch(warrantyDelete({ warrantyId: fileId, showSnackbar: false }))
        )
      );

      const failedDeletions = results.filter(
        (result) => result.status === "rejected"
      );

      if (failedDeletions.length > 0) {
        console.error(
          "Failed to delete the following warranties:",
          failedDeletions
        );
        dispatch({
          type: PROJECT_WARRANTY_STATUS_ERROR,
          payload: {
            response: null,
            status: "error",
            message: "Some warranties failed to delete",
            loading: false,
            showSnackbar: true,
          },
        });
      } else {
        dispatch({
          type: PROJECT_DELETE_WARRANTY_SUCCESS,
          payload: {
            response: null,
            status: "success",
            message: "Warranty Deleted Successfully",
            loading: false,
            showSnackbar: true,
          },
        });
      }

      await dispatch(
        fetchWarranties({
          ...pageModel,
          PropertyId: params.propertyId,
          ProjectId: params.projectId,
        })
      );
    } catch (error) {
      console.error("Error during bulk delete:", error);
      showSnackbar("error", "Error deleting files");
    }
  };

  if (addCompany) {
    return (
      <AddNewWarranty
        onCancel={() => setAddCompany(false)}
        title={get(selectedJob, "data.productName", null)}
        params={params}
        onSave={() => {
          dispatch(
            fetchWarranties({
              ...pageModel,
              PropertyId: params.propertyId,
              ProjectId: params.projectId,
            })
          );
        }}
      />
    );
  }

  return (
    <div className="jobs-page">
      <Dialog
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        fullWidth={true}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure to delete selected records ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmModal(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => setMakeDelete(true)} variant="danger">
            Sure
          </Button>
        </DialogActions>
      </Dialog>
      {addNewJob ? (
        <AddNewWarranty
          onCancel={() => setAddNewJob(false)}
          selectedJob={selectedJob}
          title={get(selectedJob, "data.productName", null)}
          params={params}
          onSave={() => {
            dispatch(
              fetchWarranties({
                ...pageModel,
                PropertyId: params.propertyId,
                ProjectId: params.projectId,
              })
            );
          }}
        />
      ) : (
        <>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={showSnackbar}
            autoHideDuration={6000}
            onClose={() => warrantyClearShowSnackbar()}
          >
            <Alert
              onClose={() => warrantyClearShowSnackbar()}
              severity={status}
            >
              {statusText}
            </Alert>
          </Snackbar>

          <div className="projects-header-section d-flex justify-content-between mt-3">
            <div>
              <Typography className="h1">Warranty</Typography>
              {/* <BreadCrumb /> */}
            </div>
            {/* <HiddenOn
              isHidden={!hasAppRole(roleConfigKeys.CREATE_JOB, appRoleDetails)}
            > */}
            {!addNewJob && (
              <div className="d-flex justify-content-end ">
                <button
                  className="primaryButton addProjectButton"
                  style={{ width: "8rem" }}
                  onClick={() => {
                    setAddNewJob(!addNewJob);
                    setSelectedJob(null);
                  }}
                >
                  {"+ Add New Item"}
                </button>
              </div>
            )}
            {/* </HiddenOn> */}
          </div>
          <div className="projects-content-section">
            {totalCount > 0 ? (
              <DataGridTable
                pagination={totalCount > 10}
                totalCount={totalCount}
                isLoading={loading}
                disableBulkDelete={true}
                columns={warrantyListColumns(
                  {
                    setEditJob,
                    setAddCompany,
                    setAddNewJob,
                    setSelectedJob,
                    appRoleDetails,
                    onCompanyDelete: (warrantyId) => {
                      dispatch(
                        warrantyDelete({
                          warrantyId,
                          pageModel,
                          showSnackbar: true,
                          onSuccess: () => {
                            dispatch(
                              fetchWarranties({
                                ...pageModel,
                                PropertyId: params.propertyId,
                                ProjectId: params.projectId,
                              })
                            );
                          },
                        })
                      );
                    },
                    setUserManagement,
                    setJobActionsContext,
                  },
                  dispatch
                )}
                data={warranties}
                defineRowId={(row) => {
                  return row.warrantyId;
                }}
                paginationModel={pageModel}
                onPaginationChange={(props) => {
                  setPageModel(props);
                }}
                onSelect={(props) => {
                  setSelectedRows(props);
                }}
                selectedRows={selectedRows}
                onDeleteSelection={() => {
                  if (selectedRows.length > 0) {
                    promptDelete();
                  }
                }}
                onRowClick={(row) => {
                  setAddNewJob(!addNewJob);
                  setSelectedJob({ data: row.row });
                  //navigate(`/settings/companies/view/${row.id}`);
                }}
              />
            ) : (
              <NoResultsFound />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Warranty;
