import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  TextField,
  MenuItem,
  Box,
  Chip,
  Typography,
  IconButton,
} from "@mui/material";
import pick from "lodash/pick";
import keys from "lodash/keys";
import get from "lodash/get";
import { CloseOutlined } from "@mui/icons-material";
import isUndefined from "lodash/isUndefined";

import { AddTaskStatusForm } from "./TaskStatusUtils";
import { addUpdateTaskStatus } from "actions/taskStatusActions";

export const AddNewTaskStatus = ({
  onCancel = () => {},
  selectedJob,
  title,
  propData,
  params,
  onSave = () => {},
}) => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);

  const { steps, formFields, validationSchema, stepDescription } =
    AddTaskStatusForm;
  const initialState = {
    ...AddTaskStatusForm.defaultState(get(selectedJob, "data", null)),
  };
  const updatedState = { ...initialState };

  const formik = useFormik({
    initialValues: updatedState,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: () => {
      console.log("Check the Onsubmit : ");
    },
  });
  const handleNext = () => {
    const currentFields = formFields[`step${activeStep}`]
      .filter((field) => field.required)
      .map((field) => field.key);
    const currentTouchedFields = pick(formik.touched, currentFields);
    const currentErrorFields = pick(formik.errors, currentFields);
    if (activeStep + 1 < steps.length) {
      if (
        currentFields.length === keys(currentTouchedFields).length &&
        keys(currentErrorFields).length === 0
      ) {
        setActiveStep(activeStep + 1);
      }
    }
    if (activeStep + 1 === steps.length) {
      if (keys(formik.errors).length === 0) {
        dispatch(
          addUpdateTaskStatus({
            formData: {
              ...formik.values,
            },
            onSuccess: (payload, taskStatusId) => {
              onCancel(false);
              if (onSave) {
                onSave(payload);
              }
            },
          })
        );
      }
    }
  };

  return (
    <div className="mt-2 p-2">
      <div className="d-flex justify-content-between">
        <Typography className="text-left m-2 h1" id="header">
          {title || "Add a TaskStatus"}
        </Typography>
        <IconButton onClick={() => onCancel(false)}>
          <CloseOutlined />
        </IconButton>
      </div>

      <div className="">
        <div>
          <Typography variant="subtitle1" className="text-left m-1">
            {stepDescription[`step${activeStep}`].heading
              ? `${activeStep + 1}. ${
                  stepDescription[`step${activeStep}`].heading
                }`
              : ""}
          </Typography>
          <div>
            <p className="m-1">
              <sup style={{ color: "red", fontSize: "1em", top: 0 }}>*</sup>
              <b>{stepDescription[`step${activeStep}`].subHeading}</b>
            </p>
          </div>
        </div>
        <div>
          <form onSubmit={formik.handleSubmit}>
            {/* {JSON.stringify(formik)} */}
            <div className="stepContent container row">
              {formFields[`step${activeStep}`].map((field, index) => {
                const extraProps = get(field, "extraInputProps", {});
                return (
                  <div
                    className={`${field.className} mt-3`}
                    style={{ width: field.width }}
                  >
                    {field.type !== "hidden" && (
                      <p style={{ marginBottom: "0.5rem" }}>
                        <label className="form-label">
                          {field.label}
                          {field.required && (
                            <sup
                              style={{ color: "red", fontSize: "1em", top: 0 }}
                            >
                              *
                            </sup>
                          )}
                        </label>
                      </p>
                    )}

                    {field.type === "hidden" ? (
                      <input
                        type="hidden"
                        value={formik.values[field.key]}
                        key={field.key}
                        id={field.key}
                      />
                    ) : (
                      <TextField
                        key={field.key}
                        id={field.key}
                        type={field.type}
                        select={field.select}
                        name={field.key}
                        fullWidth
                        variant="outlined"
                        placeholder={field.placeholder}
                        className={`registration-formField `}
                        value={formik.values[field.key]}
                        onChange={(e) => {
                          if (field.formatter) {
                            const formattedValue = field.formatter(
                              e.target.value
                            );
                            formik.setFieldValue(field.key, formattedValue);
                          } else {
                            formik.handleChange(e);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched[field.key] &&
                          Boolean(formik.errors[field.key])
                        }
                        helperText={
                          formik.touched[field.key] && formik.errors[field.key]
                        }
                        SelectProps={{
                          displayEmpty: true,
                          multiple: field.multiple,
                          renderValue: (selected, x, y, z) => {
                            if (field.multiple) {
                              if (selected && selected.length === 0) {
                                return <em>{field.placeholder}</em>;
                              }
                              return (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                    marginTop: "-0.75rem",
                                  }}
                                >
                                  {selected &&
                                    selected.length > 0 &&
                                    selected.map((value) => (
                                      <Chip
                                        key={value}
                                        label={value}
                                        style={{ fontSize: "12px" }}
                                        variant="outlined"
                                        onDelete={() => {
                                          formik.setFieldValue(
                                            field.key,
                                            selected.filter(
                                              (item) => item !== value
                                            )
                                          );
                                        }}
                                      />
                                    ))}
                                </Box>
                              );
                            } else {
                              return (
                                <Typography>
                                  {typeof field.options[0] === "string"
                                    ? selected
                                    : field.options.find(
                                        (option) => option.key === selected
                                      )?.text}
                                </Typography>
                              );
                            }
                          },
                        }}
                        {...extraProps}
                      >
                        <MenuItem value={""} disabled>
                          {field.placeholder}
                        </MenuItem>

                        {field.options &&
                          field.options.map((option) => {
                            return !isUndefined(option.key) ? (
                              <MenuItem
                                key={option.key}
                                value={option.key}
                                selected={
                                  formik.values[field.key] === option.key
                                }
                              >
                                {option.text}
                              </MenuItem>
                            ) : (
                              <MenuItem value={option}>{option}</MenuItem>
                            );
                          })}
                      </TextField>
                    )}
                  </div>
                );
              })}
              <div style={{ textAlign: "right" }}>
                <div className="d-flex justify-content-between">
                  <div>
                    {activeStep !== 0 && (
                      <button
                        type="submit"
                        className={`save-details-button m-4 primaryButton`}
                        onClick={() => {
                          setActiveStep(activeStep - 1);
                        }}
                      >
                        {"< Previous"}
                      </button>
                    )}
                  </div>
                  <div className="float-right">
                    <button
                      type="submit"
                      className={`save-details-button m-4 primaryButton`}
                      onClick={() => {
                        handleNext();
                      }}
                    >
                      {selectedJob ? "Update" : "Save"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNewTaskStatus;
