import get from "lodash/get";
import camelCase from "lodash/camelCase";
import toUpper from "lodash/toUpper";

export const BUILDER_ADMIN = "BuilderAdmin";
export const BUILDER_GENERAL_MANAGER = "BuilderGeneralManager";
export const BUILDER_TEAM_MEMBER = "BuilderTeamMember";
export const BUILDER_PROJECT_MANAGER = "ProjectManager";
export const BUILDER_JOB_MANAGER = "BuilderJobManager";
export const BUILDER_PROJECT_MEMBER = "BuilderProjectMember";

export const SUPPLIER_ADMIN = "SupplierAdmin";
export const SUPPLIER_GENERAL_MANAGER = "SupplierGeneralManager";
export const SUPPLIER_PROJECT_MANAGER = "SupplierProjectManager";
export const SUPPLIER_JOB_MANAGER = "SupplierJobManager";
export const SUPPLIER_TEAM_MEMBER = "SupplierTeamMember";

export const FREE_SUBSCRIPTION = "FREE";
export const DEMO_SUBSCRIPTION = "DEMO";
export const PAID_SUBSCRIPTION = "PAID";

export const HOME_OWNER = "HomeOwner";
export const SYSTEM_ADMIN = "SystemAdmin";

export const subscriptionConstants = {
	FREE_SUBSCRIPTION,
	DEMO_SUBSCRIPTION,
	PAID_SUBSCRIPTION,
};

export const roleConstants = {
	BUILDER_ADMIN,
	BUILDER_GENERAL_MANAGER,
	BUILDER_TEAM_MEMBER,
	BUILDER_PROJECT_MANAGER,
	BUILDER_JOB_MANAGER,
	BUILDER_PROJECT_MEMBER,
	SUPPLIER_ADMIN,
	SUPPLIER_GENERAL_MANAGER,
	SUPPLIER_PROJECT_MANAGER,
	SUPPLIER_JOB_MANAGER,
	SUPPLIER_TEAM_MEMBER,
	HOME_OWNER,
	SYSTEM_ADMIN,
};

export const MANDATORY_ROLES = [
	SYSTEM_ADMIN,
	BUILDER_ADMIN,
	BUILDER_GENERAL_MANAGER,
];

export const JOB_ADMIN_ROLES = [
	BUILDER_JOB_MANAGER,
	BUILDER_PROJECT_MANAGER,
	...MANDATORY_ROLES,
];

export const SUPPLIER_ADMIN_ROLES = [SUPPLIER_ADMIN, SUPPLIER_GENERAL_MANAGER];

export const roleConfigKeys = {
	CREATE_PROPERTY: "propertyCreateRole",
	EDIT_PROPERTY: "propertyEditRole",
	VIEW_PROPERTY: "propertyViewRole",
	DELETE_PROPERTY: "propertyDeleteRole",
	CREATE_PROJECT: "projectCreateRole",
	EDIT_PROJECT: "projectEditRole",
	VIEW_PROJECT: "projectViewRole",
	DELETE_PROJECT: "projectDeleteRole",
	TEAM_MANAGEMENT_PROJECT: "projectTeamManagementRole",
	CREATE_JOB: "jobCreateRole",
	EDIT_JOB: "jobEditRole",
	VIEW_JOB: "jobViewRole",
	DELETE_JOB: "jobDeleteRole",
	CREATE_TASK: "taskCreateRole",
	EDIT_TASK: "taskEditRole",
	VIEW_TASK: "taskViewRole",
	DELETE_TASK: "taskDeleteRole",
};
export const rolesConfig = {
	adminRoles: MANDATORY_ROLES,
	propertyCreateRole: [],
	propertyEditRole: [BUILDER_PROJECT_MANAGER, "creator", HOME_OWNER],
	propertyViewRole: [
		BUILDER_PROJECT_MANAGER,
		BUILDER_JOB_MANAGER,
		BUILDER_PROJECT_MEMBER,
		BUILDER_TEAM_MEMBER,
		SUPPLIER_ADMIN,
		"assigned",
	],
	propertyDeleteRole: [],
	projectCreateRole: [],
	projectEditRole: [BUILDER_PROJECT_MANAGER, "creator"],
	projectViewRole: [
		BUILDER_PROJECT_MANAGER,
		BUILDER_JOB_MANAGER,
		BUILDER_PROJECT_MEMBER,
		BUILDER_TEAM_MEMBER,
		...SUPPLIER_ADMIN_ROLES,
		"assigned",
	],
	projectDeleteRole: [],
	projectTeamManagementRole: [BUILDER_PROJECT_MANAGER],
	jobCreateRole: [JOB_ADMIN_ROLES, ...SUPPLIER_ADMIN_ROLES],
	jobEditRole: [
		...JOB_ADMIN_ROLES,
		BUILDER_PROJECT_MEMBER,
		BUILDER_TEAM_MEMBER,
		...SUPPLIER_ADMIN_ROLES,
		SUPPLIER_PROJECT_MANAGER,
		SUPPLIER_TEAM_MEMBER,
		SUPPLIER_JOB_MANAGER,
	],
	jobViewRole: [
		...JOB_ADMIN_ROLES,
		BUILDER_PROJECT_MEMBER,
		BUILDER_TEAM_MEMBER,
		...SUPPLIER_ADMIN_ROLES,
		SUPPLIER_PROJECT_MANAGER,
		SUPPLIER_TEAM_MEMBER,
		SUPPLIER_JOB_MANAGER,
	],
	jobDeleteRole: [...JOB_ADMIN_ROLES, ...SUPPLIER_ADMIN_ROLES],
	taskCreateRole: [...JOB_ADMIN_ROLES, ...SUPPLIER_ADMIN_ROLES],
	taskEditRole: [
		...JOB_ADMIN_ROLES,
		BUILDER_PROJECT_MEMBER,
		BUILDER_TEAM_MEMBER,
		...SUPPLIER_ADMIN_ROLES,
		SUPPLIER_PROJECT_MANAGER,
		SUPPLIER_TEAM_MEMBER,
		SUPPLIER_JOB_MANAGER,
	],
	taskViewRole: [
		...JOB_ADMIN_ROLES,
		BUILDER_PROJECT_MEMBER,
		BUILDER_TEAM_MEMBER,
		...SUPPLIER_ADMIN_ROLES,
		SUPPLIER_PROJECT_MANAGER,
		SUPPLIER_TEAM_MEMBER,
		SUPPLIER_JOB_MANAGER,
	],
	taskDeleteRole: [...JOB_ADMIN_ROLES, ...SUPPLIER_ADMIN_ROLES],
};

export const appRoles = {
	builderAdmin: ["BuilderAdmin", "BuilderGeneralManager"],
	builderTeam: ["BuilderAdmin", "BuilderGeneralManager", "BuilderTeamMember"],
	supplierAdmin: ["SupplierAdmin", "SupplierGeneralManager"],
	supplierTeam: [
		"SupplierAdmin",
		"SupplierGeneralManager",
		"SupplierTeamMember",
	],
	houseOwner: ["HomeOwner"],
};

export const hasSubscription = (profile, plan = FREE_SUBSCRIPTION) => {
	const { subscriptionPlan } = get(profile, "subscription", {});

	const formattedPlan = toUpper(camelCase(subscriptionPlan));

	if (typeof plan === Array.isArray(plan)) {
		return plan.includes(formattedPlan);
	}

	return formattedPlan === toUpper(plan);
};

export const hasAdminRole = (profile) => {
	return MANDATORY_ROLES.includes(profile.roleName);
};

export const hasJobRole = (type, jobRole) => {
	const filteredRoles = rolesConfig[type];
	return filteredRoles.includes(jobRole);
};

export const hasAppRole = (type, profile) => {
	const filteredRoles = rolesConfig[type];

	return hasAdminRole(profile) || filteredRoles.includes(profile.roleName);
};

export const isHomeOwner = (profile) => {
	return profile.roleName === HOME_OWNER;
};

export const isSystemAdmin = (profile) => {
	return profile.roleName === SYSTEM_ADMIN;
};

export const isBuilderAdmin = (profile) => {
	return appRoles.builderAdmin.includes(profile.roleName);
};

export const isSupplierAdmin = (profile) => {
	return appRoles.supplierADmin.includes(profile.roleName);
};

export const isProjectManager = (appRole, projUserRole) => {
	return (
		hasAppRole(roleConfigKeys.EDIT_PROJECT, appRole) ||
		hasJobRole(
			roleConfigKeys.EDIT_PROJECT,
			projUserRole?.projrole?.projRoleName
		)
	);
};

export const isJobManager = (appRole, projUserRole = {}) => {
	return (
		isProjectManager(appRole, projUserRole) ||
		hasAppRole(roleConfigKeys.EDIT_JOB, appRole)
	);
};

export const isCreator = (role) => role === "creator";
export const isAssignee = (role) => role === "assigned";
