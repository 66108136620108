import React from "react";
import "./ListeningAnimation.css";
import { useVoiceAssistantState } from "context/VoiceAssistantContext";

const ListeningAnimation = ({isMobile  = true}) => {
  const { VoiceAssistantState, setVoiceAssistantState } =
    useVoiceAssistantState();
  return (
    <div className="listening-container">
      <div className={`circle-container ${isMobile ? "mobile" : "desktop"}`}
        onClick={() => {
          setVoiceAssistantState({
            ...VoiceAssistantState,
            isListening: false,
          });
        }}
      >
        <div className="circle"></div>
        <div className="circle delay-1"></div>
        <div className="circle delay-2"></div>
      </div>

      <p className={`listening-text ${isMobile ? "mobile" : "desktop"}`}>Listening...</p>
    </div>
  );
};

export default ListeningAnimation;
