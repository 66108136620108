import * as yup from "yup";
import { PASSWORD_REGEX, phoneRegExp } from "constants/appConstants";
import { getText } from "core/utils/getContentText";
import { InputAdornment } from "@mui/material";
import {
	formatNumberChange,
	formatNumberKeyDown,
} from "components/TextFields/MobileUtils";

export const userFields = {
	steps: Array.from({ length: 2 }, (_, index) =>
		getText(`contractorStep.step${index + 1}`)
	),
	formFields: {
		step0: [
			{
				key: "firstName",
				label: "firstName",
				type: "text",
				required: true,
				width: "100%",
			},
			{
				key: "lastName",
				label: "lastName",
				type: "text",
				required: true,
				width: "100%",
			},
			{
				key: "address1",
				label: "address1",
				type: "text",
				required: true,
				width: "100%",
			},
			{
				key: "city",
				label: "city",
				type: "select",
				required: true,
				width: "33%",
			},
			{
				key: "state",
				label: "state",
				type: "select",
				required: true,
				width: "33%",
			},
			{
				key: "zipCode",
				label: "zipCode",
				type: "text",
				required: true,
				width: "33%",
			},
			{
				key: "email",
				label: "email",
				type: "text",
				required: true,
				disabled: true,
				width: "50%",
			},
			{
				key: "mobileNumber",
				placeholder: "(xxx) xxx-xxxx",
				label: "mobile",
				type: "phone",
				required: true,
				width: "50%",
				extraInputProps: (formik) => ({
					onChange: (e) => {
						const onValidation = (validatedValue) => {
							formik.setFieldValue("mobileNumber", validatedValue);
						};
						formatNumberChange(e, onValidation);
					},
					onKeyDown: (e) => {
						const onValidation = (validatedValue) => {
							formik.setFieldValue("mobileNumber", validatedValue);
						};
						formatNumberKeyDown(e, onValidation);
					},
					InputProps: {
						startAdornment: (
							<InputAdornment position="start">
								<span class="flag-icon flag-icon-us"></span> +1
							</InputAdornment>
						),
					},
				}),
			},
			{
				key: "password",
				label: "password",
				type: "password",
				required: true,
				width: "50%",
			},
			{
				key: "confirmPassword",
				label: "confirmPassword",
				type: "password",
				required: true,
				width: "50%",
			},
		],
	},
	validationSchema: yup.object({
		firstName: yup
			.string("Enter First Name")
			.required("First Name is required"),
		lastName: yup.string("Enter Last Name").required("Last Name is required"),
		address1: yup.string("Enter Address").required("Address is required"),
		city: yup.string("Enter city").required("city is required"),
		state: yup.string("Enter state").required("state is required"),
		zipCode: yup.string("Enter ZipCode").required("ZipCode is required"),
		password: yup
			.string("Enter password")
			.required("Password is required")
			.matches(
				PASSWORD_REGEX,
				"Password must be at least 8 characters long, include at least one uppercase letter, one special character, and one number"
			),
		confirmPassword: yup
			.string("")
			.required("Confirm Password is required")
			.oneOf([yup.ref("password")], "Passwords must match"),
		landline: yup
			.string("Enter your landline")
			.matches(phoneRegExp, "Phone number is not valid"),
		mobileNumber: yup
			.string("Enter your Mobile")
			.required("Mobile is required")
			.matches(phoneRegExp, "Phone number is not valid"),
	}),
	defaultState: (defaultState) => ({
		firstName: "",
		lastName: "",
		address1: "",
		zipCode: "",
		city: "",
		state: "",
		mobileNumber: "",
		landLine: "",
		password: "",
		confirmPassword: "",
		...defaultState,
	}),
	mandatoryFieldsByStep: {
		0: [
			"firstName",
			"lastName",
			"address1",
			"zipCode",
			"state",
			"city",
			"password",
			"confirmPassword",
			"mobileNumber",
		],
	},
};

export const contractorFields = {
	steps: Array.from({ length: 2 }, (_, index) =>
		getText(`contractorStep.step${index + 1}`)
	),
	formFields: {
		step0: [
			{
				key: "firstName",
				label: "firstName",
				type: "text",
				required: true,
				width: "50%",
			},
			{
				key: "lastName",
				label: "lastName",
				type: "text",
				required: true,
				width: "50%",
			},
			{
				key: "email",
				label: "email",
				type: "text",
				required: true,
				disabled: true,
				width: "100%",
			},
			{
				key: "mobileNumber",
				placeholder: "(xxx) xxx-xxxx",
				label: "mobile",
				required: true,
				width: "50%",
				type: "phone",
				extraInputProps: (formik) => ({
					onChange: (e) => {
						const onValidation = (validatedValue) => {
							formik.setFieldValue("mobileNumber", validatedValue);
						};
						formatNumberChange(e, onValidation);
					},
					onKeyDown: (e) => {
						const onValidation = (validatedValue) => {
							formik.setFieldValue("mobileNumber", validatedValue);
						};
						formatNumberKeyDown(e, onValidation);
					},
					InputProps: {
						startAdornment: (
							<InputAdornment position="start">
								<span class="flag-icon flag-icon-us"></span> +1
							</InputAdornment>
						),
					},
				}),
			},
			{
				key: "landPhone",
				label: "landline",
				placeholder: "(xxx) xxx-xxxx",
				type: "phone",
				width: "50%",
				extraInputProps: (formik) => ({
					onChange: (e) => {
						const onValidation = (validatedValue) => {
							formik.setFieldValue("landPhone", validatedValue);
						};
						formatNumberChange(e, onValidation);
					},
					onKeyDown: (e) => {
						const onValidation = (validatedValue) => {
							formik.setFieldValue("landPhone", validatedValue);
						};
						formatNumberKeyDown(e, onValidation);
					},
					InputProps: {
						startAdornment: (
							<InputAdornment position="start">
								<span class="flag-icon flag-icon-us"></span> +1
							</InputAdornment>
						),
					},
				}),
			},
			{
				key: "password",
				label: "password",
				type: "password",
				required: true,
				width: "50%",
			},
			{
				key: "confirmPassword",
				label: "confirmPassword",
				type: "password",
				required: true,
				width: "50%",
			},
		],
		step1: [
			{
				key: "businessName",
				label: "businessName",
				type: "text",
				required: true,
			},
			{ key: "category", label: "category", type: "text" },

			{ key: "projectsCompleted", label: "projectsCompleted", type: "text" },

			{ key: "password", label: "password", type: "password", required: true },
			{
				key: "confirmPassword",
				label: "confirmPassword",
				type: "password",
				required: true,
			},
		],
	},
	validationSchema: yup.object({
		firstName: yup
			.string("Enter First Name")
			.required("First Name is required"),
		lastName: yup.string("Enter Last Name").required("Last Name is required"),
		password: yup
			.string("Enter password")
			.required("Password is required")
			.matches(
				PASSWORD_REGEX,
				"Password must be at least 8 characters long, include at least one uppercase letter, one special character, and one number"
			),
		confirmPassword: yup
			.string("")
			.required("Confirm Password is required")
			.oneOf([yup.ref("password")], "Passwords must match"),
		email: yup
			.string("Enter your email")
			.email("Enter a valid email")
			.required("Email is required"),

		mobileNumber: yup
			.string("Enter your Mobile")
			.required("Mobile is required")
			.matches(phoneRegExp, "Phone number is not valid"),
		landPhone: yup
			.string("Enter your landline")
			.matches(phoneRegExp, "Phone number is not valid"),
	}),
	defaultState: (defaultState) => ({
		firstName: "",
		lastName: "",
		password: "",
		address: "",
		zipCode: "",
		mobileNumber: "",
		landLine: "",
		username: "",
		businessName: "",
		...defaultState,
	}),
	mandatoryFieldsByStep: {
		0: ["firstName", "lastName", "password", "confirmPassword", "mobileNumber"],
		1: [
			"cardNumber",
			"expiryDate",
			"cvv",
			"address1",
			"address2",
			"city",
			"state",
			"zipCode",
		],
	},
};

export const supplierFields = {
	steps: Array.from({ length: 2 }, (_, index) =>
		getText(`contractorStep.step${index + 1}`)
	),
	formFields: {
		step0: [
			{
				key: "firstName",
				label: "firstName",
				type: "text",
				required: true,
				width: "50%",
			},
			{
				key: "lastName",
				label: "lastName",
				type: "text",
				required: true,
				width: "50%",
			},

			{
				key: "email",
				label: "email",
				type: "text",
				required: true,
				disabled: true,
				width: "100%",
			},
			{
				key: "mobileNumber",
				placeholder: "(xxx) xxx-xxxx",
				label: "mobile",
				required: true,
				width: "50%",
				type: "phone",
				extraInputProps: (formik) => ({
					onChange: (e) => {
						const onValidation = (validatedValue) => {
							formik.setFieldValue("mobileNumber", validatedValue);
						};
						formatNumberChange(e, onValidation);
					},
					onKeyDown: (e) => {
						const onValidation = (validatedValue) => {
							formik.setFieldValue("mobileNumber", validatedValue);
						};
						formatNumberKeyDown(e, onValidation);
					},
					InputProps: {
						startAdornment: (
							<InputAdornment position="start">
								<span class="flag-icon flag-icon-us"></span> +1
							</InputAdornment>
						),
					},
				}),
			},
			{
				key: "landPhone",
				label: "landline",
				width: "50%",
				extraInputProps: (formik) => ({
					onChange: (e) => {
						const onValidation = (validatedValue) => {
							formik.setFieldValue("landPhone", validatedValue);
						};
						formatNumberChange(e, onValidation);
					},
					onKeyDown: (e) => {
						const onValidation = (validatedValue) => {
							formik.setFieldValue("landPhone", validatedValue);
						};
						formatNumberKeyDown(e, onValidation);
					},
					InputProps: {
						startAdornment: (
							<InputAdornment position="start">
								<span class="flag-icon flag-icon-us"></span> +1
							</InputAdornment>
						),
					},
				}),
			},
			{
				key: "password",
				label: "password",
				type: "password",
				required: true,
				width: "50%",
			},
			{
				key: "confirmPassword",
				label: "confirmPassword",
				type: "password",
				required: true,
				width: "50%",
			},
		],
		step1: [
			{
				key: "businessName",
				label: "businessName",
				type: "text",
				required: true,
			},
			{ key: "category", label: "category", type: "text" },

			{ key: "projectsCompleted", label: "projectsCompleted", type: "text" },

			{ key: "password", label: "password", type: "password", required: true },
			{
				key: "confirmPassword",
				label: "confirmPassword",
				type: "password",
				required: true,
			},
		],
	},
	validationSchema: yup.object({
		firstName: yup
			.string("Enter Company Name")
			.required("Company Name is required"),
		email: yup
			.string("Enter your email")
			.email("Enter a valid email")
			.required("Email is required"),

		password: yup
			.string("Enter password")
			.required("Password is required")
			.matches(
				PASSWORD_REGEX,
				"Password must be at least 8 characters long, include at least one uppercase letter, one special character, and one number"
			),
		confirmPassword: yup
			.string("")
			.required("Confirm Password is required")
			.oneOf([yup.ref("password")], "Passwords must match"),

		mobileNumber: yup
			.string("Enter your Mobile")
			.required("Mobile is required")
			.matches(phoneRegExp, "Phone number is not valid"),
		landPhone: yup
			.string("Enter your landline")
			.matches(phoneRegExp, "Phone number is not valid"),
	}),
	defaultState: (defaultState) => ({
		firstName: "",
		password: "",
		address: "",
		zipCode: "",
		mobileNumber: "",
		landLine: "",
		username: "",
		businessName: "",
		...defaultState,
	}),
	mandatoryFieldsByStep: {
		0: ["firstName", "email", "password", "confirmPassword", "mobileNumber"],
		1: [
			"cardNumber",
			"expiryDate",
			"cvv",
			"address1",
			"address2",
			"city",
			"state",
			"zipCode",
		],
	},
};

export const getFormFieldsByUser = (userType) => {
	const allFields = {
		homeOwner: userFields,
		supplier: supplierFields,
		contractor: contractorFields,
	};
	return allFields[userType];
};
