import axios from "axios";
import {
	PAYMENT_LOADING,
	PAYMENT_GET_ALL_SUCCESS,
	PAYMENT_ERROR,
	PAYMENT_CLEAR_SNACKBAR,
} from "../constants/types";

import { server_url } from "../constants/types";
import { apiCallAction } from "./actionUtils";
import { ActionConstants } from "../constants";
import queryString from "query-string";
import { loadUserDetails } from "./login_registrationActions";

export const fetchSubscriptionPlans = () => {
	return apiCallAction({
		// requestMethod: formData.punchListId ? "PUT" : "POST",
		requestMethod: "GET",
		requestUrl: `${server_url}/Subscription`,
		requestData: {},
		actionType: ActionConstants.FETCH_SUBSCRIPTION_PLANS_REQUEST,
		successType: ActionConstants.FETCH_SUBSCRIPTION_PLANS_SUCCESS,
		errorType: ActionConstants.FETCH_SUBSCRIPTION_PLANS_ERROR,
		successCallback: (response, dispatch) => {
			dispatch({
				type: ActionConstants.FETCH_SUBSCRIPTION_PLANS_SUCCESS,
				payload: response.data,
			});
			// onSuccess(response.data);
		},
	});
};

export const paymentGetAll = () => {
	return (dispatch, getState) => {
		return new Promise((resolve) => {
			dispatch({
				type: PAYMENT_LOADING,
				payload: {
					loading: true,
				},
			});
			axios
				.get(`${server_url}/payment`, {})
				.then((response) => {
					dispatch({
						type: PAYMENT_GET_ALL_SUCCESS,
						payload: {
							paymentList: response.data,
							loading: false,
							status: "success",
							text: "Get All PAYMENT data successfully.",
						},
					});
					resolve();
				})
				.catch((e) => {
					dispatch({
						type: PAYMENT_ERROR,
						payload: {
							status: "error",
							text: "Error occured during getting PAYMENT data.",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const paymentDelete = (payment) => {
	return (dispatch, getState) => {
		return new Promise((resolve) => {
			dispatch({
				type: PAYMENT_LOADING,
				payload: {
					loading: true,
				},
			});
			axios
				.delete(`${server_url}/payment/${payment.PAYMENT_ID}`, {})
				.then((response) => {
					axios
						.get(`${server_url}/payment`, {})
						.then((response) => {
							dispatch({
								type: PAYMENT_GET_ALL_SUCCESS,
								payload: {
									paymentList: response.data,
									loading: false,
									status: "success",
									text: "Delete PAYMENT data successfully.",
								},
							});
							resolve();
						})
						.catch((e) => {
							dispatch({
								type: PAYMENT_ERROR,
								payload: {
									text: "Error occured during getting PAYMENT data.",
									status: "error",
									loading: false,
								},
							});
							resolve();
						});
				})
				.catch((e) => {
					dispatch({
						type: PAYMENT_ERROR,
						payload: {
							text: "Error occured during deleteing PAYMENT data.",
							status: "error",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const paymentAddOrUpdate = (payment, state) => {
	return (dispatch, getState) => {
		return new Promise((resolve) => {
			dispatch({
				type: PAYMENT_LOADING,
				payload: {
					loading: true,
				},
			});
			if (state === "add") {
				axios
					.post(`${server_url}/payment`, payment)
					.then((response) => {
						axios
							.get(`${server_url}/payment`)
							.then((response) => {
								dispatch({
									type: PAYMENT_GET_ALL_SUCCESS,
									payload: {
										paymentList: response.data,
										loading: false,
										status: "success",
										text: "Add PAYMENT data successfully.",
									},
								});
								resolve();
							})
							.catch((e) => {
								dispatch({
									type: PAYMENT_ERROR,
									payload: {
										text: "Error occured during getting PAYMENT data.",
										status: "error",
										loading: false,
									},
								});
								resolve();
							});
					})
					.catch((e) => {
						dispatch({
							type: PAYMENT_ERROR,
							payload: {
								text: "Error occured during adding PAYMENT data.",
								status: "error",
								loading: false,
							},
						});
						resolve();
					});
			} else if (state === "edit") {
				axios
					.put(`${server_url}/payment`, payment)
					.then((response) => {
						axios
							.get(`${server_url}/payment`)
							.then((response) => {
								dispatch({
									type: PAYMENT_GET_ALL_SUCCESS,
									payload: {
										paymentList: response.data,
										loading: false,
										status: "success",
										text: "Update PAYMENT data successfully.",
									},
								});
								resolve();
							})
							.catch((e) => {
								dispatch({
									type: PAYMENT_ERROR,
									payload: {
										text: "Error occured during getting PAYMENT data.",
										status: "error",
										loading: false,
									},
								});
								resolve();
							});
					})
					.catch((e) => {
						dispatch({
							type: PAYMENT_ERROR,
							payload: {
								text: "Error occured during updating PAYMENT data.",
								status: "error",
								loading: false,
							},
						});
						resolve();
					});
			}
		});
	};
};

export const paymentClearShowSnackbar = () => {
	return (dispatch, getState) => {
		dispatch({
			type: PAYMENT_CLEAR_SNACKBAR,
		});
	};
};

export const updateSubscriptionPlanAction = (
	subscriptionId,
	onSuccess = () => {}
) => {
	const queryParams = queryString.stringify({
		SubscriptionId: subscriptionId,
	});
	return apiCallAction({
		requestMethod: "PUT",
		requestUrl: `${server_url}/Company/Subscription?${queryParams}`,
		requestData: {},
		actionType: ActionConstants.UPDATE_SUBSCRIPTION_PLAN_REQUEST,
		successType: ActionConstants.UPDATE_SUBSCRIPTION_PLAN_SUCCESS,
		errorType: ActionConstants.UPDATE_SUBSCRIPTION_PLAN_ERROR,
		successCallback: (response, dispatch) => {
			dispatch(loadUserDetails());
			onSuccess(response);
		},
	});
};

export const fetchBillingHistory = () => {
	return apiCallAction({
		// requestMethod: formData.punchListId ? "PUT" : "POST",
		requestMethod: "GET",
		requestUrl: `${server_url}/PaymentsSubscription`,
		requestData: {},
		actionType: ActionConstants.FETCH_BILLING_HISTORY_REQUEST,
		successType: ActionConstants.FETCH_BILLING_HISTORY_SUCCESS,
		errorType: ActionConstants.FETCH_BILLING_HISTORY_ERROR,
		defaultSuccessAction: true,
	});
};
