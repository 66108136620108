export const ACTIVITY_LOADING = "ACTIVITY_GET_ALL_BIGIN";
export const ACTIVITY_GET_ALL_SUCCESS = "ACTIVITY_GET_ALL_SUCCESS";
export const ACTIVITY_ERROR = "ACTIVITY_ERROR";
export const ACTIVITY_CLEAR_SNACKBAR = "ACTIVITY_CLEAR_SNACKBAR";
export const ALLOCATION_LOADING = "ALLOCATION_GET_ALL_BIGIN";
export const ALLOCATION_GET_ALL_SUCCESS = "ALLOCATION_GET_ALL_SUCCESS";
export const ALLOCATION_ERROR = "ALLOCATION_ERROR";
export const ALLOCATION_CLEAR_SNACKBAR = "ALLOCATION_CLEAR_SNACKBAR";
export const CHANGE_ORDER_LOADING = "CHANGE_ORDER_GET_ALL_BIGIN";
export const CHANGE_ORDER_GET_ALL_SUCCESS = "CHANGE_ORDER_GET_ALL_SUCCESS";
export const CHANGE_ORDER_ERROR = "CHANGE_ORDER_ERROR";
export const CHANGE_ORDER_CLEAR_SNACKBAR = "CHANGE_ORDER_CLEAR_SNACKBAR";
export const CLIENT_SELECTION_LOADING = "CLIENT_SELECTION_GET_ALL_BIGIN";
export const CLIENT_SELECTION_GET_ALL_SUCCESS =
  "CLIENT_SELECTION_GET_ALL_SUCCESS";
export const CLIENT_SELECTION_ERROR = "CLIENT_SELECTION_ERROR";
export const CLIENT_SELECTION_CLEAR_SNACKBAR =
  "CLIENT_SELECTION_CLEAR_SNACKBAR";
export const CONNECTION_LOADING = "CONNECTION_GET_ALL_BIGIN";
export const CONNECTION_GET_ALL_SUCCESS = "CONNECTION_GET_ALL_SUCCESS";
export const CONNECTION_ERROR = "CONNECTION_ERROR";
export const CONNECTION_CLEAR_SNACKBAR = "CONNECTION_CLEAR_SNACKBAR";
export const CONNECTION_STATUS_LOADING = "CONNECTION_STATUS_GET_ALL_BIGIN";
export const CONNECTION_STATUS_GET_ALL_SUCCESS =
  "CONNECTION_STATUS_GET_ALL_SUCCESS";
export const CONNECTION_STATUS_ERROR = "CONNECTION_STATUS_ERROR";
export const CONNECTION_STATUS_CLEAR_SNACKBAR =
  "CONNECTION_STATUS_CLEAR_SNACKBAR";
export const CONTACT_LOADING = "CONTACT_GET_ALL_BIGIN";
export const CONTACT_GET_ALL_SUCCESS = "CONTACT_GET_ALL_SUCCESS";
export const CONTACT_ERROR = "CONTACT_ERROR";
export const CONTACT_CLEAR_SNACKBAR = "CONTACT_CLEAR_SNACKBAR";
export const CONTRACT_TYPE_LOADING = "CONTRACT_TYPE_GET_ALL_BIGIN";
export const CONTRACT_TYPE_GET_ALL_SUCCESS = "CONTRACT_TYPE_GET_ALL_SUCCESS";
export const CONTRACT_TYPE_ERROR = "CONTRACT_TYPE_ERROR";
export const CONTRACT_TYPE_CLEAR_SNACKBAR = "CONTRACT_TYPE_CLEAR_SNACKBAR";
export const CUSTOMER_LOADING = "CUSTOMER_GET_ALL_BIGIN";
export const CUSTOMER_GET_ALL_SUCCESS = "CUSTOMER_GET_ALL_SUCCESS";
export const CUSTOMER_ERROR = "CUSTOMER_ERROR";
export const CUSTOMER_CLEAR_SNACKBAR = "CUSTOMER_CLEAR_SNACKBAR";
export const DAILY_LOG_LOADING = "DAILY_LOG_GET_ALL_BIGIN";
export const DAILY_LOG_GET_ALL_SUCCESS = "DAILY_LOG_GET_ALL_SUCCESS";
export const DAILY_LOG_ERROR = "DAILY_LOG_ERROR";
export const DAILY_LOG_CLEAR_SNACKBAR = "DAILY_LOG_CLEAR_SNACKBAR";
export const EMPLOYEE_LOADING = "EMPLOYEE_GET_ALL_BIGIN";
export const EMPLOYEE_GET_ALL_SUCCESS = "EMPLOYEE_GET_ALL_SUCCESS";
export const EMPLOYEE_ERROR = "EMPLOYEE_ERROR";
export const EMPLOYEE_CLEAR_SNACKBAR = "EMPLOYEE_CLEAR_SNACKBAR";
export const INVOICE_LOADING = "INVOICE_GET_ALL_BIGIN";
export const INVOICE_GET_ALL_SUCCESS = "INVOICE_GET_ALL_SUCCESS";
export const INVOICE_ERROR = "INVOICE_ERROR";
export const INVOICE_CLEAR_SNACKBAR = "INVOICE_CLEAR_SNACKBAR";
export const JOB_LOADING = "JOB_GET_ALL_BIGIN";
export const JOB_GET_ALL_SUCCESS = "JOB_GET_ALL_SUCCESS";
export const JOB_ERROR = "JOB_ERROR";
export const JOB_CLEAR_SNACKBAR = "JOB_CLEAR_SNACKBAR";
export const JOB_EMPLOYEE_LOADING = "JOB_EMPLOYEE_GET_ALL_BIGIN";
export const JOB_EMPLOYEE_GET_ALL_SUCCESS = "JOB_EMPLOYEE_GET_ALL_SUCCESS";
export const JOB_EMPLOYEE_ERROR = "JOB_EMPLOYEE_ERROR";
export const JOB_EMPLOYEE_CLEAR_SNACKBAR = "JOB_EMPLOYEE_CLEAR_SNACKBAR";
export const LEAD_LOADING = "LEAD_GET_ALL_BIGIN";
export const LEAD_GET_ALL_SUCCESS = "LEAD_GET_ALL_SUCCESS";
export const LEAD_ERROR = "LEAD_ERROR";
export const LEAD_CLEAR_SNACKBAR = "LEAD_CLEAR_SNACKBAR";
export const LEAD_ACTIVITY_LOADING = "LEAD_ACTIVITY_GET_ALL_BIGIN";
export const LEAD_ACTIVITY_GET_ALL_SUCCESS = "LEAD_ACTIVITY_GET_ALL_SUCCESS";
export const LEAD_ACTIVITY_ERROR = "LEAD_ACTIVITY_ERROR";
export const LEAD_ACTIVITY_CLEAR_SNACKBAR = "LEAD_ACTIVITY_CLEAR_SNACKBAR";
export const LEAD_STATUS_LOADING = "LEAD_STATUS_GET_ALL_BIGIN";
export const LEAD_STATUS_GET_ALL_SUCCESS = "LEAD_STATUS_GET_ALL_SUCCESS";
export const LEAD_STATUS_ERROR = "LEAD_STATUS_ERROR";
export const LEAD_STATUS_CLEAR_SNACKBAR = "LEAD_STATUS_CLEAR_SNACKBAR";
export const ORGANIZATION_LOADING = "ORGANIZATION_GET_ALL_BIGIN";
export const ORGANIZATION_GET_ALL_SUCCESS = "ORGANIZATION_GET_ALL_SUCCESS";
export const ORGANIZATION_ERROR = "ORGANIZATION_ERROR";
export const ORGANIZATION_CLEAR_SNACKBAR = "ORGANIZATION_CLEAR_SNACKBAR";
export const ORGANIZATION_STATUS_LOADING = "ORGANIZATION_STATUS_GET_ALL_BIGIN";
export const ORGANIZATION_STATUS_GET_ALL_SUCCESS =
  "ORGANIZATION_STATUS_GET_ALL_SUCCESS";
export const ORGANIZATION_STATUS_ERROR = "ORGANIZATION_STATUS_ERROR";
export const ORGANIZATION_STATUS_CLEAR_SNACKBAR =
  "ORGANIZATION_STATUS_CLEAR_SNACKBAR";
export const ORGANIZATION_TYPE_LOADING = "ORGANIZATION_TYPE_GET_ALL_BIGIN";
export const ORGANIZATION_TYPE_GET_ALL_SUCCESS =
  "ORGANIZATION_TYPE_GET_ALL_SUCCESS";
export const ORGANIZATION_TYPE_ERROR = "ORGANIZATION_TYPE_ERROR";
export const ORGANIZATION_TYPE_CLEAR_SNACKBAR =
  "ORGANIZATION_TYPE_CLEAR_SNACKBAR";
export const PAYMENT_LOADING = "PAYMENT_GET_ALL_BIGIN";
export const PAYMENT_GET_ALL_SUCCESS = "PAYMENT_GET_ALL_SUCCESS";
export const PAYMENT_ERROR = "PAYMENT_ERROR";
export const PAYMENT_CLEAR_SNACKBAR = "PAYMENT_CLEAR_SNACKBAR";
export const PURCHASE_ORDER_LOADING = "PURCHASE_ORDER_GET_ALL_BIGIN";
export const PURCHASE_ORDER_GET_ALL_SUCCESS = "PURCHASE_ORDER_GET_ALL_SUCCESS";
export const PURCHASE_ORDER_ERROR = "PURCHASE_ORDER_ERROR";
export const PURCHASE_ORDER_CLEAR_SNACKBAR = "PURCHASE_ORDER_CLEAR_SNACKBAR";
export const ROLE_LOADING = "ROLE_GET_ALL_BIGIN";
export const ROLE_GET_ALL_SUCCESS = "ROLE_GET_ALL_SUCCESS";
export const ROLE_ERROR = "ROLE_ERROR";
export const ROLE_CLEAR_SNACKBAR = "ROLE_CLEAR_SNACKBAR";
export const TASK_LOADING = "TASK_GET_ALL_BIGIN";
export const TASK_GET_ALL_SUCCESS = "TASK_GET_ALL_SUCCESS";
export const TASK_ERROR = "TASK_ERROR";
export const TASK_CLEAR_SNACKBAR = "TASK_CLEAR_SNACKBAR";
export const WARRANTY_SUPPORT_LOADING = "WARRANTY_SUPPORT_GET_ALL_BIGIN";
export const WARRANTY_SUPPORT_GET_ALL_SUCCESS =
  "WARRANTY_SUPPORT_GET_ALL_SUCCESS";
export const WARRANTY_SUPPORT_ERROR = "WARRANTY_SUPPORT_ERROR";
export const WARRANTY_SUPPORT_CLEAR_SNACKBAR =
  "WARRANTY_SUPPORT_CLEAR_SNACKBAR";

export const CUSTOMER_TYPE_LOADING = "CUSTOMER_TYPE_GET_ALL_BIGIN";
export const CUSTOMER_TYPE_GET_ALL_SUCCESS = "CUSTOMER_TYPE_GET_ALL_SUCCESS";
export const CUSTOMER_TYPE_ERROR = "CUSTOMER_TYPE_ERROR";
export const CUSTOMER_TYPE_CLEAR_SNACKBAR = "CUSTOMER_TYPE_CLEAR_SNACKBAR";

export const CUSTOMER_STATUS_LOADING = "CUSTOMER_STATUS_GET_ALL_BIGIN";
export const CUSTOMER_STATUS_GET_ALL_SUCCESS =
  "CUSTOMER_STATUS_GET_ALL_SUCCESS";
export const CUSTOMER_STATUS_ERROR = "CUSTOMER_STATUS_ERROR";
export const CUSTOMER_STATUS_CLEAR_SNACKBAR = "CUSTOMER_STATUS_CLEAR_SNACKBAR";

export const PROPERTY_LOADING = "CUSTOMER_GET_ALL_BIGIN";
export const PROPERTY_GET_ALL_SUCCESS = "CUSTOMER_GET_ALL_SUCCESS";
export const PROPERTY_ERROR = "CUSTOMER_ERROR";
export const PROPERTY_CLEAR_SNACKBAR = "CUSTOMER_CLEAR_SNACKBAR";

export const TASK_STATUS_LOADING = "TASK_STATUS_GET_ALL_BIGIN";
export const TASK_STATUS_GET_ALL_SUCCESS = "TASK_STATUS_GET_ALL_SUCCESS";
export const TASK_STATUS_ERROR = "TASK_STATUS_ERROR";
export const TASK_STATUS_CLEAR_SNACKBAR = "TASK_STATUS_CLEAR_SNACKBAR";

export const PURCHASE_ORDER_STATUS_LOADING =
  "PURCHASE_ORDER_STATUS_GET_ALL_BIGIN";
export const PURCHASE_ORDER_STATUS_GET_ALL_SUCCESS =
  "PURCHASE_ORDER_STATUS_GET_ALL_SUCCESS";
export const PURCHASE_ORDER_STATUS_ERROR = "PURCHASE_ORDER_STATUS_ERROR";
export const PURCHASE_ORDER_STATUS_CLEAR_SNACKBAR =
  "PURCHASE_ORDER_STATUS_CLEAR_SNACKBAR";

export const WARRANTY_STATUS_LOADING = "WARRANTY_STATUS_GET_ALL_BIGIN";
export const WARRANTY_STATUS_GET_ALL_SUCCESS =
  "WARRANTY_STATUS_GET_ALL_SUCCESS";
export const WARRANTY_STATUS_ERROR = "WARRANTY_STATUS_ERROR";
export const WARRANTY_STATUS_CLEAR_SNACKBAR = "WARRANTY_STATUS_CLEAR_SNACKBAR";

export const CHANGE_ORDER_STATUS_LOADING = "CHANGE_ORDER_STATUS_GET_ALL_BIGIN";
export const CHANGE_ORDER_STATUS_GET_ALL_SUCCESS =
  "CHANGE_ORDER_STATUS_GET_ALL_SUCCESS";
export const CHANGE_ORDER_STATUS_ERROR = "CHANGE_ORDER_STATUS_ERROR";
export const CHANGE_ORDER_STATUS_CLEAR_SNACKBAR =
  "CHANGE_ORDER_STATUS_CLEAR_SNACKBAR";

export const INVOICE_STATUS_LOADING = "INVOICE_STATUS_GET_ALL_BIGIN";
export const INVOICE_STATUS_GET_ALL_SUCCESS = "INVOICE_STATUS_GET_ALL_SUCCESS";
export const INVOICE_STATUS_ERROR = "INVOICE_STATUS_ERROR";
export const INVOICE_STATUS_CLEAR_SNACKBAR = "INVOICE_STATUS_CLEAR_SNACKBAR";

export const SETTINGS_GET_COMPANIES_LOADING =
  "SETTINGS_GET_COMPANIES_GET_ALL)_BIGIN";
export const SETTINGS_GET_COMPANIES = "SETTINGS_GET_COMPANIES";
export const SETTINGS_STATUS_ERROR = "SETTINGS_STATUS_ERROR";
export const SETTINGS_ADD_COMPANY = "SETTINGS_ADD_COMPANY";
export const SETTINGS_ADD_COMPANY_SUCCESS = "SETTINGS_ADD_COMPANY_SUCCESS";
export const SETTINGS_ADD_COMPANY_ERROR = "SETTINGS_ADD_COMPANY_ERROR";
export const SETTINGS_COMPANIES_CLEAR_SNACKBAR =
  "SETTINGS_COMPANIES_CLEAR_SNACKBAR";
export const SETTINGS_DELETE_COMPANY_SUCCESS =
  "SETTINGS_DELETE_COMPANY_SUCCESS";

export const PROJECT_GET_WARRANTIES_LOADING = "PROJECT_GET_WARRANTIES_LOADING";
export const PROJECT_GET_WARRANTIES = "PROJECT_GET_WARRANTIES";
export const PROJECT_WARRANTY_STATUS_ERROR = "PROJECT_WARRANTY_STATUS_ERROR";
export const PROJECT_ADD_WARRANTY = "PROJECT_ADD_WARRANTY";
export const PROJECT_ADD_WARRANTY_SUCCESS = "PROJECT_ADD_WARRANTY_SUCCESS";
export const PROJECT_ADD_WARRANTY_ERROR = "PROJECT_ADD_WARRANTY_ERROR";
export const PROJECT_WARRANTY_CLEAR_SNACKBAR =
  "PROJECT_WARRANTY_CLEAR_SNACKBAR";
export const PROJECT_DELETE_WARRANTY_SUCCESS =
  "PROJECT_DELETE_WARRANTY_SUCCESS";

export const PROJECT_GET_ADS = "PROJECT_GET_ADS";
export const PROJECT_GET_ADS_LOADING = "PROJECT_GET_ADS_LOADING";
export const PROJECT_ADS_STATUS_ERROR = "PROJECT_ADS_STATUS_ERROR";
export const PROJECT_ADD_ADS = "PROJECT_ADD_ADS";
export const PROJECT_ADD_ADS_SUCCESS = "PROJECT_ADD_ADS_SUCCESS";
export const PROJECT_ADD_ADS_ERROR = "PROJECT_ADD_ADS_ERROR";
export const PROJECT_ADS_CLEAR_SNACKBAR = "PROJECT_ADS_CLEAR_SNACKBAR";
export const PROJECT_DELETE_ADS_SUCCESS = "PROJECT_DELETE_ADS_SUCCESS";
export const PROJECT_DELETE_ADS = "PROJECT_DELETE_ADS";
export const PROJECT_ADS_AUTOCOMPLETE_LOADING =
  "PROJECT_ADS_AUTOCOMPLETE_LOADING";
export const PROJECT_GET_ADS_AUTOCOMPLETE = "PROJECT_GET_ADS_AUTOCOMPLETE";
export const PROJECT_ADS_AUTOCOMPLETE_STATUS_ERROR =
  "PROJECT_ADS_AUTOCOMPLETE_STATUS_ERROR";

export const SETTINGS_GET_PROJECT_STATUS = "SETTINGS_GET_PROJECT_STATUS";
export const SETTINGS_GET_PROJECT_STATUS_LOADING =
  "SETTINGS_GET_PROJECT_STATUS_LOADING ";
export const SETTINGS_PROJECT_STATUS_ERROR = "SETTINGS_PROJECT_STATUS_ERROR";
export const SETTINGS_ADD_PROJECT_STATUS = "SETTINGS_ADD_PROJECT_STATUS";
export const SETTINGS_ADD_PROJECT_STATUS_SUCCESS =
  "SETTINGS_ADD_PROJECT_STATUS_SUCCESS";
export const SETTINGS_ADD_PROJECT_STATUS_ERROR =
  "SETTINGS_ADD_PROJECT_STATUS_ERROR";
export const SETTINGS_PROJECT_STATUS_CLEAR_SNACKBAR =
  "SETTINGS_PROJECT_STATUS_CLEAR_SNACKBAR";
export const SETTINGS_DELETE_PROJECT_STATUS_SUCCESS =
  "SETTINGS_DELETE_PROJECT_STATUS_SUCCESS";
export const SETTINGS_DELETE_PROJECT_STATUS = "SETTINGS_DELETE_PROJECT_STATUS";

export const SETTINGS_GET_JOBTYPE = "SETTINGS_GET_JOBTYPE";
export const SETTINGS_GET_JOBTYPE_LOADING = "SETTINGS_GET_JOBTYPE_LOADING ";
export const SETTINGS_JOBTYPE_ERROR = "SETTINGS_JOBTYPE_ERROR";
export const SETTINGS_ADD_JOBTYPE = "SETTINGS_ADD_JOBTYPE";
export const SETTINGS_ADD_JOBTYPE_SUCCESS = "SETTINGS_ADD_JOBTYPE_SUCCESS";
export const SETTINGS_ADD_JOBTYPE_ERROR = "SETTINGS_ADD_JOBTYPE_ERROR";
export const SETTINGS_JOBTYPE_CLEAR_SNACKBAR =
  "SETTINGS_JOBTYPE_CLEAR_SNACKBAR";
export const SETTINGS_DELETE_JOBTYPE_SUCCESS =
  "SETTINGS_DELETE_JOBTYPE_SUCCESS";
export const SETTINGS_DELETE_JOBTYPE = "SETTINGS_DELETE_JOBTYPE";

export const SETTINGS_GET_JOB_STATUS = "SETTINGS_GET_JOB_STATUS";
export const SETTINGS_GET_JOB_STATUS_LOADING =
  "SETTINGS_GET_JOB_STATUS_LOADING ";
export const SETTINGS_JOB_STATUS_ERROR = "SETTINGS_JOB_STATUS_ERROR";
export const SETTINGS_ADD_JOB_STATUS = "SETTINGS_ADD_JOB_STATUS";
export const SETTINGS_ADD_JOB_STATUS_SUCCESS =
  "SETTINGS_ADD_JOB_STATUS_SUCCESS";
export const SETTINGS_ADD_JOB_STATUS_ERROR = "SETTINGS_ADD_JOB_STATUS_ERROR";
export const SETTINGS_JOB_STATUS_CLEAR_SNACKBAR =
  "SETTINGS_JOB_STATUS_CLEAR_SNACKBAR";
export const SETTINGS_DELETE_JOB_STATUS_SUCCESS =
  "SETTINGS_DELETE_JOB_STATUS_SUCCESS";
export const SETTINGS_DELETE_JOB_STATUS = "SETTINGS_DELETE_JOB_STATUS";

export const SETTINGS_GET_SUBSCRIPTION = "SETTINGS_GET_SUBSCRIPTION";
export const SETTINGS_GET_SUBSCRIPTION_LOADING =
  "SETTINGS_GET_SUBSCRIPTION_LOADING ";
export const SETTINGS_SUBSCRIPTION_ERROR = "SETTINGS_SUBSCRIPTION_ERROR";
export const SETTINGS_ADD_SUBSCRIPTION = "SETTINGS_ADD_SUBSCRIPTION";
export const SETTINGS_ADD_SUBSCRIPTION_SUCCESS =
  "SETTINGS_ADD_SUBSCRIPTION_SUCCESS";
export const SETTINGS_ADD_SUBSCRIPTION_ERROR =
  "SETTINGS_ADD_SUBSCRIPTION_ERROR";
export const SETTINGS_SUBSCRIPTION_CLEAR_SNACKBAR =
  "SETTINGS_SUBSCRIPTION_CLEAR_SNACKBAR";
export const SETTINGS_DELETE_SUBSCRIPTION_SUCCESS =
  "SETTINGS_DELETE_SUBSCRIPTION_SUCCESS";
export const SETTINGS_DELETE_SUBSCRIPTION = "SETTINGS_DELETE_SUBSCRIPTION";

export const SETTINGS_GET_DOCUMENT_TYPE = "SETTINGS_GET_DOCUMENT_TYPE";
export const SETTINGS_GET_DOCUMENT_TYPE_LOADING =
  "SETTINGS_GET_DOCUMENT_TYPE_LOADING ";
export const SETTINGS_DOCUMENT_TYPE_ERROR = "SETTINGS_DOCUMENT_TYPE_ERROR";
export const SETTINGS_ADD_DOCUMENT_TYPE = "SETTINGS_ADD_DOCUMENT_TYPE";
export const SETTINGS_ADD_DOCUMENT_TYPE_SUCCESS =
  "SETTINGS_ADD_DOCUMENT_TYPE_SUCCESS";
export const SETTINGS_ADD_DOCUMENT_TYPE_ERROR =
  "SETTINGS_ADD_DOCUMENT_TYPE_ERROR";
export const SETTINGS_DOCUMENT_TYPE_CLEAR_SNACKBAR =
  "SETTINGS_DOCUMENT_TYPE_CLEAR_SNACKBAR";
export const SETTINGS_DELETE_DOCUMENT_TYPE_SUCCESS =
  "SETTINGS_DELETE_DOCUMENT_TYPE_SUCCESS";
export const SETTINGS_DELETE_DOCUMENT_TYPE = "SETTINGS_DELETE_DOCUMENT_TYPE";

export const SETTINGS_GET_COMPANY_TYPE = "SETTINGS_GET_COMPANY_TYPE";
export const SETTINGS_GET_COMPANY_TYPE_LOADING =
  "SETTINGS_GET_COMPANY_TYPE_LOADING ";
export const SETTINGS_COMPANY_TYPE_ERROR = "SETTINGS_COMPANY_TYPE_ERROR";
export const SETTINGS_ADD_COMPANY_TYPE = "SETTINGS_ADD_COMPANY_TYPE";
export const SETTINGS_ADD_COMPANY_TYPE_SUCCESS =
  "SETTINGS_ADD_COMPANY_TYPE_SUCCESS";
export const SETTINGS_ADD_COMPANY_TYPE_ERROR =
  "SETTINGS_ADD_COMPANY_TYPE_ERROR";
export const SETTINGS_COMPANY_TYPE_CLEAR_SNACKBAR =
  "SETTINGS_COMPANY_TYPE_CLEAR_SNACKBAR";
export const SETTINGS_DELETE_COMPANY_TYPE_SUCCESS =
  "SETTINGS_DELETE_COMPANY_TYPE_SUCCESS";
export const SETTINGS_DELETE_COMPANY_TYPE = "SETTINGS_DELETE_COMPANY_TYPE";

export const SETTINGS_GET_MARKET_SECTOR = "SETTINGS_GET_MARKET_SECTOR";
export const SETTINGS_GET_MARKET_SECTOR_LOADING =
  "SETTINGS_GET_MARKET_SECTOR_LOADING ";
export const SETTINGS_MARKET_SECTOR_ERROR = "SETTINGS_MARKET_SECTOR_ERROR";
export const SETTINGS_ADD_MARKET_SECTOR = "SETTINGS_ADD_MARKET_SECTOR";
export const SETTINGS_ADD_MARKET_SECTOR_SUCCESS =
  "SETTINGS_ADD_MARKET_SECTOR_SUCCESS";
export const SETTINGS_ADD_MARKET_SECTOR_ERROR =
  "SETTINGS_ADD_MARKET_SECTOR_ERROR";
export const SETTINGS_MARKET_SECTOR_CLEAR_SNACKBAR =
  "SETTINGS_MARKET_SECTOR_CLEAR_SNACKBAR";
export const SETTINGS_DELETE_MARKET_SECTOR_SUCCESS =
  "SETTINGS_DELETE_MARKET_SECTOR_SUCCESS";
export const SETTINGS_DELETE_MARKET_SECTOR = "SETTINGS_DELETE_MARKET_SECTOR";

export const SETTINGS_GET_PUNCH_LIST_TYPE = "SETTINGS_GET_PUNCH_LIST_TYPE";
export const SETTINGS_GET_PUNCH_LIST_TYPE_LOADING =
  "SETTINGS_GET_PUNCH_LIST_TYPE_LOADING ";
export const SETTINGS_PUNCH_LIST_TYPE_ERROR = "SETTINGS_PUNCH_LIST_TYPE_ERROR";
export const SETTINGS_ADD_PUNCH_LIST_TYPE = "SETTINGS_ADD_PUNCH_LIST_TYPE";
export const SETTINGS_ADD_PUNCH_LIST_TYPE_SUCCESS =
  "SETTINGS_ADD_PUNCH_LIST_TYPE_SUCCESS";
export const SETTINGS_ADD_PUNCH_LIST_TYPE_ERROR =
  "SETTINGS_ADD_PUNCH_LIST_TYPE_ERROR";
export const SETTINGS_PUNCH_LIST_TYPE_CLEAR_SNACKBAR =
  "SETTINGS_PUNCH_LIST_TYPE_CLEAR_SNACKBAR";
export const SETTINGS_DELETE_PUNCH_LIST_TYPE_SUCCESS =
  "SETTINGS_DELETE_PUNCH_LIST_TYPE_SUCCESS";
export const SETTINGS_DELETE_PUNCH_LIST_TYPE =
  "SETTINGS_DELETE_PUNCH_LIST_TYPE";

export const SETTINGS_GET_PUNCH_LIST_STATUS_TYPE =
  "SETTINGS_GET_PUNCH_LIST_STATUS_TYPE";
export const SETTINGS_GET_PUNCH_LIST_STATUS_TYPE_LOADING =
  "SETTINGS_GET_PUNCH_LIST_STATUS_TYPE_LOADING ";
export const SETTINGS_PUNCH_LIST_STATUS_TYPE_ERROR =
  "SETTINGS_PUNCH_LIST_STATUS_TYPE_ERROR";
export const SETTINGS_ADD_PUNCH_LIST_STATUS_TYPE =
  "SETTINGS_ADD_PUNCH_LIST_STATUS_TYPE";
export const SETTINGS_ADD_PUNCH_LIST_STATUS_TYPE_SUCCESS =
  "SETTINGS_ADD_PUNCH_LIST_STATUS_TYPE_SUCCESS";
export const SETTINGS_ADD_PUNCH_LIST_STATUS_TYPE_ERROR =
  "SETTINGS_ADD_PUNCH_LIST_STATUS_TYPE_ERROR";
export const SETTINGS_PUNCH_LIST_STATUS_TYPE_CLEAR_SNACKBAR =
  "SETTINGS_PUNCH_LIST_STATUS_TYPE_CLEAR_SNACKBAR";
export const SETTINGS_DELETE_PUNCH_LIST_STATUS_TYPE_SUCCESS =
  "SETTINGS_DELETE_PUNCH_LIST_STATUS_TYPE_SUCCESS";
export const SETTINGS_DELETE_PUNCH_LIST_STATUS_TYPE =
  "SETTINGS_DELETE_PUNCH_LIST_STATUS_TYPE";

export const SETTINGS_GET_TASK_STATUS = "SETTINGS_GET_TASK_STATUS";
export const SETTINGS_GET_TASK_STATUS_LOADING =
  "SETTINGS_GET_TASK_STATUS_LOADING ";
export const SETTINGS_TASK_STATUS_ERROR = "SETTINGS_TASK_STATUS_ERROR";
export const SETTINGS_ADD_TASK_STATUS = "SETTINGS_ADD_TASK_STATUS";
export const SETTINGS_ADD_TASK_STATUS_SUCCESS =
  "SETTINGS_ADD_TASK_STATUS_SUCCESS";
export const SETTINGS_ADD_TASK_STATUS_ERROR = "SETTINGS_ADD_TASK_STATUS_ERROR";
export const SETTINGS_TASK_STATUS_CLEAR_SNACKBAR =
  "SETTINGS_TASK_STATUS_CLEAR_SNACKBAR";
export const SETTINGS_DELETE_TASK_STATUS_SUCCESS =
  "SETTINGS_DELETE_TASK_STATUS_SUCCESS";
export const SETTINGS_DELETE_TASK_STATUS = "SETTINGS_DELETE_TASK_STATUS";

export const SETTINGS_GET_PUNCH_LIST_PRIORITY = "SETTINGS_GET_PUNCH_LIST_PRIORITY";
export const SETTINGS_GET_PUNCH_LIST_PRIORITY_LOADING =
  "SETTINGS_GET_PUNCH_LIST_PRIORITY_LOADING ";
export const SETTINGS_PUNCH_LIST_PRIORITY_ERROR = "SETTINGS_PUNCH_LIST_PRIORITY_ERROR";
export const SETTINGS_ADD_PUNCH_LIST_PRIORITY = "SETTINGS_ADD_PUNCH_LIST_PRIORITY";
export const SETTINGS_ADD_PUNCH_LIST_PRIORITY_SUCCESS =
  "SETTINGS_ADD_PUNCH_LIST_PRIORITY_SUCCESS";
export const SETTINGS_ADD_PUNCH_LIST_PRIORITY_ERROR = "SETTINGS_ADD_PUNCH_LIST_PRIORITY_ERROR";
export const SETTINGS_PUNCH_LIST_PRIORITY_CLEAR_SNACKBAR =
  "SETTINGS_PUNCH_LIST_PRIORITY_CLEAR_SNACKBAR";
export const SETTINGS_DELETE_PUNCH_LIST_PRIORITY_SUCCESS =
  "SETTINGS_DELETE_PUNCH_LIST_PRIORITY_SUCCESS";
export const SETTINGS_DELETE_PUNCH_LIST_PRIORITY = "SETTINGS_DELETE_PUNCH_LIST_PRIORITY";

export const SETTINGS_GET_CONNECTION_STATUS = "SETTINGS_GET_CONNECTION_STATUS";
export const SETTINGS_GET_CONNECTION_STATUS_LOADING =
  "SETTINGS_GET_CONNECTION_STATUS_LOADING ";
export const SETTINGS_CONNECTION_STATUS_ERROR = "SETTINGS_CONNECTION_STATUS_ERROR";
export const SETTINGS_ADD_CONNECTION_STATUS = "SETTINGS_ADD_CONNECTION_STATUS";
export const SETTINGS_ADD_CONNECTION_STATUS_SUCCESS =
  "SETTINGS_ADD_CONNECTION_STATUS_SUCCESS";
export const SETTINGS_ADD_CONNECTION_STATUS_ERROR = "SETTINGS_ADD_CONNECTION_STATUS_ERROR";
export const SETTINGS_CONNECTION_STATUS_CLEAR_SNACKBAR =
  "SETTINGS_CONNECTION_STATUS_CLEAR_SNACKBAR";
export const SETTINGS_DELETE_CONNECTION_STATUS_SUCCESS =
  "SETTINGS_DELETE_CONNECTION_STATUS_SUCCESS";
export const SETTINGS_DELETE_CONNECTION_STATUS = "SETTINGS_DELETE_CONNECTION_STATUS";


export const getEnvUrl = () => {
  const env = process.env.REACT_APP_ENVIRONMENT || "develop";
  if (env === "develop") {
    return "https://devbuildercopilotapi.azurewebsites.net/api";
  } else if (env === "staging") {
    return "https://corewebapi20240513161440.azurewebsites.net/api";
  } else if (env === "production") {
    return "https://corewebapi20240513161440.azurewebsites.net/api";
  }
  return "https://corewebapi20240513161440.azurewebsites.net/api";
};
export const getAppUrl = () => {
  const env = process.env.REACT_APP_ENVIRONMENT || "develop";
  if (env === "develop") {
    return "https://dev.buildercopilot.net";
  } else if (env === "staging") {
    return "https://app.buildercopilot.net";
  } else if (env === "production") {
    return "https://app.buildercopilot.net";
  }
  return "https://dev.buildercopilot.net";
};
export const getServerUrl = () => {
  const env = process.env.REACT_APP_ENVIRONMENT || "develop";
  if (env === "develop") {
    return "https://devbuildercopilotapi.azurewebsites.net";
  } else if (env === "staging") {
    return "https://corewebapi20240513161440.azurewebsites.net";
  } else if (env === "production") {
    return "https://corewebapi20240513161440.azurewebsites.net";
  }
  return "https://corewebapi20240513161440.azurewebsites.net";
};
export const server_url = getEnvUrl();

export const server_builder_ai_url =
  "https://devbuilderai.azurewebsites.net/api";
export const app_url = getAppUrl();

// export const server_url = "https://localhost:44342/api";
