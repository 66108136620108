import React, { useState, useEffect, useRef, useCallback } from "react";
import {
	GoogleMap,
	Marker,
	// withGoogleMap,
	// withScriptjs,
	InfoWindow,
	Autocomplete,
	// LoadScript,
} from "@react-google-maps/api";
import { fromLatLng, setDefaults, fromAddress } from "react-geocode";
import { parseGoogleAddressComponents } from "./locationUtils";

export const MAPS_API_KEY = "AIzaSyBB_ou3GdFq8n3oCoVpB8fNPQaMndh-_WI";

// Geocode.setApiKey(MAPS_API_KEY);

setDefaults({
	key: MAPS_API_KEY, // Your API key here.
	language: "en", // Default language for responses.
	region: "us", // Default region for responses.
});
export const LocationField = ({
	placeholder = "Search Location",
	inputClass = "primaryInput",
	onSelect,
	defaultAddress = "",
}) => {
	// eslint-disable-next-line
	const [address, setAddress] = useState("");
	// eslint-disable-next-line
	const [location, setLocation] = useState({ lat: 0, lng: 0 });
	const autocompleteRef = useRef(null);

	const handlePlaceSelect = useCallback(() => {
		const place = autocompleteRef.current.getPlace();
		//  place.name + " " +
		const address = place.formatted_address;
		setAddress(address);

		fromAddress(address).then(
			(response) => {
				const { lat, lng } = response.results[0].geometry.location;
				setLocation({ lat, lng });
				onSelect({
					latLng: { lat, lng },
					address,
					locationUrl: place.url,
					autocompletePayload: place,
					addressPayload: response,
					addressObj: parseGoogleAddressComponents(
						place.address_components,
						place
					),
				});
			},
			(error) => {
				console.error(error);
			}
		);
	}, [onSelect]);

	return (
		<div className={inputClass}>
			{/* <LoadScript googleMapsApiKey={MAPS_API_KEY} libraries={["places"]}> */}
			<Autocomplete
				onLoad={(autocomplete) => {
					return (autocompleteRef.current = autocomplete);
				}}
				onPlaceChanged={handlePlaceSelect}
			>
				<input
					type="text"
					placeholder={placeholder}
					// value={address}
					defaultValue={defaultAddress}
					onChange={(e) => {
						console.log("On Change : ", e.target.value);
						setAddress(e.target.value);
					}}
				/>
			</Autocomplete>
			{/* </LoadScript> */}
		</div>
	);
};
const MapComponent = ({ onMarkerClick }) => {
	const [markerPosition, setMarkerPosition] = useState({
		lat: 0,
		lng: 0,
	});
	const [selectedLocationAddress, setSelectedLocationAddress] = useState("");
	const [defaultLocation, setDefaultLocation] = useState(null);

	const generateShareLink = (lat, lng, address) => {
		const encodedAddress = encodeURIComponent(address);
		const encodedCoords = `${lat},${lng}`;
		return `https://maps.app.goo.gl/?link=https://www.google.com/maps/place/${encodedAddress}/@${encodedCoords}`;
	};

	useEffect(() => {
		// Fetch user's current location using Geolocation API
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					setDefaultLocation({ lat: latitude, lng: longitude });
					setMarkerPosition({ lat: latitude, lng: longitude });

					fromLatLng(latitude, longitude).then(
						(response) => {
							const address = response.results[0].formatted_address;
							setSelectedLocationAddress(address);
							onMarkerClick(generateShareLink(latitude, longitude, address));
						},
						(error) => {
							console.error("Error fetching address:", error);
						}
					);
				},
				(error) => {
					console.error("Error getting user location:", error);
				}
			);
		} else {
			console.error("Geolocation is not supported by this browser.");
		}
	}, [onMarkerClick]);

	// Function to handle click on the map
	const handleMapClick = (event) => {
		const clickedLatLng = {
			lat: event.latLng.lat(),
			lng: event.latLng.lng(),
		};
		setMarkerPosition(clickedLatLng);

		// Use reverse geocoding to get address from coordinates
		fromLatLng(clickedLatLng.lat, clickedLatLng.lng).then(
			(response) => {
				const address = response.results[0].formatted_address;
				setSelectedLocationAddress(address);
				onMarkerClick(
					generateShareLink(clickedLatLng.lat, clickedLatLng.lng, address)
				);
			},
			(error) => {
				console.error("Error fetching address:", error);
			}
		);
	};

	return (
		<GoogleMap
			defaultZoom={20}
			defaultCenter={defaultLocation}
			center={markerPosition}
			onClick={handleMapClick}
		>
			{markerPosition && (
				<Marker position={markerPosition}>
					{selectedLocationAddress && (
						<InfoWindow>
							<div>{selectedLocationAddress}</div>
						</InfoWindow>
					)}
				</Marker>
			)}
		</GoogleMap>
	);
};

const WrappedMap = (props) => <MapComponent {...props} />;

export const GeolocationField = ({ handleMarkerClick }) => {
	return (
		<div>
			<WrappedMap
				// googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
				// loadingElement={<div style={{ height: "100%" }} />}
				// containerElement={<div style={{ height: "20rem" }} />}
				// mapElement={<div style={{ height: "100%" }} />}
				onMarkerClick={handleMarkerClick}
			/>
		</div>
	);
};

// const WrappedMap = withScriptjs(withGoogleMap(MapComponent));

// export const GoogleLocation = withScriptjs(withGoogleMap(LocationField));
export default GeolocationField;
