import React from "react";
import { Box, Typography, Avatar, InputAdornment } from "@mui/material";
import { Edit, PhotoCamera } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileDetails } from "selectors/appSelector";
import RenderDataFields from "components/DataFields/RenderDataFields";
import { getMomentDate } from "core/utils/appUtils";
import FormInputField from "components/TextFields/FormInputField";
import { useFormik } from "formik";
import * as yup from "yup";
import { phoneRegExp } from "constants/appConstants";
import {
	formatNumberChange,
	formatNumberKeyDown,
} from "components/TextFields/MobileUtils";
import { get } from "lodash";
import { updateUserProfileDetailsAction } from "actions/userActions";
import ProfilePictureUpload from "./ProfilePicture";
import { ModelDialog } from "components/Model";

const dataFields = [
	{
		key: "email",
		label: "Email",
		dataPath: "email",
		formatter: "",
		placeholder: "Enter Email",
		type: "email",
		width: "95%",
		textFieldWidth: "95%",
		required: true,
		disabled: true,
		showInEdit: true,
	},
	{
		key: "firstName",
		label: "First Name",
		dataPath: "firstName",
		formatter: "",
		placeholder: "Enter First Name",
		type: "text",
		width: "45%",
		textFieldWidth: "95%",
		required: true,
		showInEdit: true,
	},
	{
		key: "lastName",
		label: "Last Name",
		dataPath: "lastName",
		formatter: "",
		placeholder: "Enter Last Name",
		type: "text",
		width: "45%",
		textFieldWidth: "95%",
		required: true,
		showInEdit: true,
	},

	{
		key: "mobileNumber",
		label: "Contact Number",
		dataPath: "mobileNumber",
		formatter: "",
		placeholder: "Enter Contact Number",
		width: "45%",
		textFieldWidth: "95%",
		required: true,
		showInEdit: true,
		type: "phone",
		extraInputProps: (formik) => ({
			onChange: (e) => {
				const onValidation = (validatedValue) => {
					formik.setFieldValue("mobileNumber", validatedValue);
				};
				formatNumberChange(e, onValidation);
			},
			onKeyDown: (e) => {
				const onValidation = (validatedValue) => {
					formik.setFieldValue("mobileNumber", validatedValue);
				};
				formatNumberKeyDown(e, onValidation);
			},
			InputProps: {
				startAdornment: (
					<InputAdornment position="start">
						<span class="flag-icon flag-icon-us"></span> +1
					</InputAdornment>
				),
			},
		}),
	},
	{
		key: "landPhone",
		label: "Home/Office Number",
		dataPath: "landPhone",
		formatter: "",
		placeholder: "Enter Home/Office Number",
		width: "45%",
		textFieldWidth: "95%",
		showInEdit: true,
		type: "phone",
		extraInputProps: (formik) => ({
			onChange: (e) => {
				const onValidation = (validatedValue) => {
					formik.setFieldValue("landPhone", validatedValue);
				};
				formatNumberChange(e, onValidation);
			},
			onKeyDown: (e) => {
				const onValidation = (validatedValue) => {
					formik.setFieldValue("landPhone", validatedValue);
				};
				formatNumberKeyDown(e, onValidation);
			},
			InputProps: {
				startAdornment: (
					<InputAdornment position="start">
						<span class="flag-icon flag-icon-us"></span> +1
					</InputAdornment>
				),
			},
		}),
	},

	{
		key: "startDate",
		label: "Account Created On",
		dataPath: "startDate",
		formatter: (data) => getMomentDate(data, "ll"),
		width: "45%",
	},
	{
		key: "accountCategory",
		label: "Account Category",
		dataPath: "role.applUserCategory",
		width: "45%",
	},
	{
		key: "accountRole",
		label: "Role Name",
		dataPath: "role.applUserType",
		width: "45%",
	},
];

export const MyAccount = () => {
	const userDetails = useSelector(getUserProfileDetails);
	const [allowEdit, setAllowEdit] = React.useState(false);

	const [showPicUpload, setShowPicUpload] = React.useState(false);

	return allowEdit ? (
		<div className="p-2">
			<EditAccount userDetails={userDetails} setAllowEdit={setAllowEdit} />
		</div>
	) : (
		<>
			<ModelDialog
				open={showPicUpload}
				handleClose={() => setShowPicUpload(false)}
				dialogTitle="Change Profile Picture"
				dialogContent={<ProfilePictureUpload />}
			/>
			<Box className="space-y-6 p-2">
				<Box className="d-flex mb-3">
					<div className="profile-avatar profile-avatar--md">
						<div className="profile-avatar__container">
							<Avatar
								src={userDetails.avatar}
								alt={userDetails.name}
								sx={{ width: 120, height: 120 }}
								className="profile-avatar__image"
							/>
							<div
								class="profile-avatar__overlay"
								onClick={() => {
									console.log("On Image Click");
									setShowPicUpload(true);
								}}
							>
								<PhotoCamera style={{ fontSize: "14px" }} />
							</div>
						</div>
					</div>
					<div className="d-flex">
						{/* <ProfilePictureUpload /> */}
						{/* <button
							className="secondaryButton w-auto"
							style={{ alignSelf: "end", width: "auto" }}
						>
							<PhotoCamera style={{ fontSize: "14px" }} /> Change Photo
						</button> */}
						<button
							className="primaryButton w-auto ms-2"
							style={{ alignSelf: "end" }}
							onClick={() => setAllowEdit(true)}
						>
							<Edit style={{ fontSize: "14px" }} /> Edit Details
						</button>
					</div>
				</Box>
				<Box>
					<Typography className="h5 mb-2">{userDetails.name}</Typography>
				</Box>

				<div className="mb-3">
					<RenderDataFields data={userDetails} fields={dataFields} />
				</div>
			</Box>
		</>
	);
};

export const EditAccount = ({ userDetails, setAllowEdit }) => {
	const dispatch = useDispatch();
	const validationSchema = yup.object({
		firstName: yup
			.string("Enter First Name")
			.required("First Name is required"),
		lastName: yup.string("Enter Last Name").required("Last Name is required"),
		email: yup
			.string("Enter your email")
			.email("Enter a valid email")
			.required("Email is required"),

		mobileNumber: yup
			.string("Enter your Mobile")
			.required("Mobile is required")
			.matches(phoneRegExp, "Phone number is not valid"),
		landPhone: yup
			.string("Enter your landline")
			.matches(phoneRegExp, "Phone number is not valid"),
	});
	const filteredFields = dataFields.filter((field) => field.showInEdit);

	const formik = useFormik({
		initialValues: userDetails,
		validationSchema: validationSchema,
		onSubmit: () => {
			console.log("Formik Values", formik.values);
			dispatch(
				updateUserProfileDetailsAction({
					formData: { ...formik.values },
					onSuccess: (payload) => {
						setAllowEdit(false);
					},
				})
			);
		},
	});
	return (
		<>
			<form onSubmit={formik.handleSubmit}>
				{filteredFields.map((field, index) => {
					const extraInputProps = get(field, "extraInputProps", {});

					const extraProps =
						typeof extraInputProps === "function"
							? extraInputProps(formik)
							: extraInputProps;
					return (
						<FormInputField
							key={index}
							value={formik.values[field.key]}
							id={field.key}
							field={{ ...field, width: field.textFieldWidth }}
							className={`registration-formField my-account-edit-${field.key}`}
							formik={formik}
							{...extraProps}
						/>
					);
				})}
				<div className="d-flex justify-content-center  mt-3 mb-2">
					<button
						className="secondaryButton col-2"
						onClick={() => setAllowEdit(false)}
					>
						Cancel
					</button>
					<button className="primaryButton ms-2 col-2" type="submit">
						Save Changes
					</button>
				</div>
			</form>
		</>
	);
};

export default MyAccount;
