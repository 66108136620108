import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.punchListStatusType;

export const getPunchListStatusType = createSelector(
  selectState,
  (punchListStatusType) => ({
    punchListStatusType: get(punchListStatusType, "punchListStatusType", []),
    totalCount: get(punchListStatusType, "totalCount", 0),
    loading: get(punchListStatusType, "loading", false),
    showSnackbar: get(punchListStatusType, "showSnackbar", false),
    status: get(punchListStatusType, "status", "success"),
    statusText: get(punchListStatusType, "text", ""),
  })
);
