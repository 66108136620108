import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.companyType;

export const getCompanyType = createSelector(selectState, (companyType) => ({
  companyType: get(companyType, "companyType", []),
  totalCount: get(companyType, "totalCount", 0),
  loading: get(companyType, "loading", false),
  showSnackbar: get(companyType, "showSnackbar", false),
  status: get(companyType, "status", "success"),
  statusText: get(companyType, "text", ""),
}));
