export const emailRegex =
	/^([A-Za-z0-9](\.|_)?)+@[A-Za-z0-9]+(\.[A-Za-z0-9]+)*\.[A-Za-z]{2,}$/;

export const phoneRegExp = /^\(\d{3}\) \d{3}-\d{4}$/;

export const phoneRegExp1 =
	/^\+?\d{1,3}[-.\s]?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

export const PASSWORD_REGEX =
	/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

export const DATE_FORMAT = "MM-DD-YYYY";

export const getConnType = {
	newConnections: "Notconnected",
	pendingConnections: "waiting",
	connected: "connected",
};

export const APP_ACTION_CONSTANTS = {
	GET_DASHBOARD_DATA: "GET_DASHBOARD_DATA",
	GET_DASHBOARD_DATA_SUCCESS: "GET_DASHBOARD_DATA_SUCCESS",
	GET_DASHBOARD_DATA_ERROR: "GET_DASHBOARD_DATA_ERROR",
	LOADING_APP: "LOADING_APP",
	LOADING_APP_SUCCESS: "LOADING_APP_SUCCESS",
	LOADING_APP_ERROR: "LOADING_APP_ERROR",
	LOGIN_EXTERNAL: "LOGIN_EXTERNAL",
	LOGIN_EXTERNAL_SUCCESS: "LOGIN_EXTERNAL_SUCCESS",
	LOGIN_EXTERNAL_ERROR: "LOGIN_EXTERNAL_ERROR",
	GET_GLOBAL_SEARCH: "GET_GLOBAL_SEARCH",
	GET_GLOBAL_SEARCH_SUCCESS: "GET_GLOBAL_SEARCH_SUCCESS",
	GET_GLOBAL_SEARCH_ERROR: "GET_GLOBAL_SEARCH_ERROR",
	RECEIVE_ALERT_NOTIFICATIONS: "RECEIVE_ALERT_NOTIFICATIONS",
	APP_CONFIG: "APP_CONFIG",
	APP_CONFIG_SUCCESS: "APP_CONFIG_SUCCESS",
	APP_CONFIG_ERROR: "APP_CONFIG_ERROR",
	APP_LOADING_START: "APP_LOADING_START",
	APP_LOADING_STOP: "APP_LOADING_STOP",
	CREATE_ALERT: "CREATE_ALERT",
	CREATE_ALERT_SUCCESS: "CREATE_ALERT_SUCCESS",
	CREATE_ALERT_ERROR: "CREATE_ALERT_ERROR",
	USER_COMPANY_DETAILS: "USER_COMPANY_DETAILS",
	USER_COMPANY_DETAILS_SUCCESS: "USER_COMPANY_DETAILS_SUCCESS",
	USER_COMPANY_DETAILS_ERROR: "USER_COMPANY_DETAILS_ERROR",
	SHOW_APP_API_ALERT: "SHOW_APP_API_ALERT",
	FETCH_ALERT_NOTIFICATIONS_REQUEST: "FETCH_ALERT_NOTIFICATIONS_REQUEST",
	FETCH_ALERT_NOTIFICATIONS_SUCCESS: "FETCH_ALERT_NOTIFICATIONS_SUCCESS",
	FETCH_ALERT_NOTIFICATIONS_ERROR: "FETCH_ALERT_NOTIFICATIONS_ERROR",
	FETCH_SUBSCRIPTION_PLANS_REQUEST: "FETCH_SUBSCRIPTION_PLANS_REQUEST",
	FETCH_SUBSCRIPTION_PLANS_SUCCESS: "FETCH_SUBSCRIPTION_PLANS_SUCCESS",
	FETCH_SUBSCRIPTION_PLANS_ERROR: "FETCH_SUBSCRIPTION_PLANS_ERROR",
	FETCH_BILLING_HISTORY_REQUEST: "FETCH_BILLING_HISTORY_REQUEST",
	FETCH_BILLING_HISTORY_SUCCESS: "FETCH_BILLING_HISTORY_SUCCESS",
	FETCH_BILLING_HISTORY_ERROR: "FETCH_BILLING_HISTORY_ERROR",
	UPDATE_SUBSCRIPTION_PLAN_REQUEST: "UPDATE_SUBSCRIPTION_PLAN_REQUEST",
	UPDATE_SUBSCRIPTION_PLAN_SUCCESS: "UPDATE_SUBSCRIPTION_PLAN_SUCCESS",
	UPDATE_SUBSCRIPTION_PLAN_ERROR: "UPDATE_SUBSCRIPTION_PLAN_ERROR",
};

export const MOBILE_MAX_WIDTH_768 = "(max-width:787px)";

export const SIGNOUT_TEXT = "Signout";

export const voiceAssistantPath = "voiceAssistant";

export const GUID_ERROR_MESSAGE = "Verification Link is not Valid or expired";

export const MOBILE_MAX_LISTED_RESULT = 5;

export const INVITE_NEW_CONNECTION_TEXT =
	"Easily grow your network by inviting new connections to join Builder Copilot. They’ll receive an invitation link to create an account. Once their account is created, they’ll be directly connected to your network, helping you collaborate and build together seamlessly.";

export const HOMEOWNER_INVITE_TEXT =
	"Invite new connections to join Builder Copilot. They’ll receive an invitation link to create an account. Once their account is created, you’ll be notified and you can add to your network, helping you collaborate and build together seamlessly.";
