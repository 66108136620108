export const INITIAL_FLOW_STATE = {
  commandId: null,
  command: null,
  entityName: null,
  currentStep: {
    stepName: null,
    stepCount: 0,
    fieldName: null,
  },
  satisfiedRequirements: false,
  isWaitingForInput: false,
  requirements: [],
  dependencies :{
    customer: false,
    property: false,
    project: false,
    job: false,
    task: false,
  },
  nextStep: null,
  dataCollected: {}
};

export const PROPERTY_STEPS_ENUM = {
  VALIDATE_PROPERTY: "VALIDATE_PROPERTY",
  PROPERTY_NAME: "PROPERTY_NAME",
  PROPERTY_ADDRESS: "PROPERTY_ADDRESS",
  PROPERTY_CITY: "PROPERTY_CITY",
  PROPERTY_STATE: "PROPERTY_STATE",
  PROPERTY_ZIP: "PROPERTY_ZIP",
  HOMEOWNER_NAME: "HOMEOWNER_NAME",
  API_CALL: "API_CALL",
};

export const PROPERTY_STEPS = {
  VALIDATE_PROPERTY: {
    name: "VALIDATE_PROPERTY",
    nextStep: "PROPERTY_NAME",
    dataCollected: {
      propertyName: null,
      address: null,
      city: null,
      state: null,
      zip: null,
      homeownerName: null,
      homeOwnerMobile: "(xxx) xxx-xxxx",
    },
    commandData: {}
  },
  PROPERTY_NAME: {
    name: "PROPERTY_NAME",
    nextStep: "PROPERTY_ADDRESS",
  },
  PROPERTY_ADDRESS: {
    name: "PROPERTY_ADDRESS",
    nextStep: "PROPERTY_CITY",
  },
  PROPERTY_CITY: {
    name: "PROPERTY_CITY",
    nextStep: "PROPERTY_STATE",
  },
  PROPERTY_STATE: {
    name: "PROPERTY_STATE",
    nextStep: "PROPERTY_ZIP",
  },
  PROPERTY_ZIP: {
    name: "PROPERTY_ZIP",
    nextStep: "HOMEOWNER_NAME",
  },
  HOMEOWNER_NAME: {
    name: "HOMEOWNER_NAME",
    nextStep: "API_CALL",
  },
  API_CALL: {
    name: "API_CALL",
    nextStep: null,
  },
};

export const PROJECT_STEPS_ENUM = {
  VALIDATE_PROJECT: "VALIDATE_PROJECT",
  VALIDATE_JOB: "VALIDATE_JOB",
  VALIDATE_TASK: "VALIDATE_TASK",
  PROJECT_NAME: "PROJECT_NAME",
  PROJECT_ADDRESS: "PROJECT_ADDRESS",
  PROJECT_CITY: "PROJECT_CITY",
  PROJECT_STATE: "PROJECT_STATE",
  PROJECT_ZIP: "PROJECT_ZIP",
  HOMEOWNER_NAME: "HOMEOWNER_NAME",
  API_CALL: "API_CALL",
};