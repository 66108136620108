import * as Icons from "@mui/icons-material";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
// 	faShop,
// 	faFileInvoiceDollar,
// 	faDollarSign,
// } from "@fortawesome/free-solid-svg-icons";
import {
	hasSubscription,
	subscriptionConstants as plans,
} from "core/utils/roleUtils";

export const houseOwnerMenu = [
	{
		id: "menu_alerts",
		label: "Alerts",
		link: "/alerts",
		icon: <Icons.NotificationImportant />,
	},
	{
		id: `user1`,
		label: "Dashboard",
		link: "/dashboard",
		icon: <Icons.SpaceDashboardOutlined />,
		shouldHide: (profile) => hasSubscription(profile, plans.FREE_SUBSCRIPTION),
	},
	{
		id: `user5`,
		label: "Properties",
		link: "/properties",
		icon: <Icons.MapsHomeWorkOutlined />,
	},
	{
		id: `homeowner-builder-connections`,
		label: "Builders",
		link: "/buildersSearch",
		icon: <Icons.ConstructionOutlined />,
	},
	// {
	// 	id: `user7`,
	// 	label: "Orders History",
	// 	icon: <Icons.PaymentsOutlined />,
	// 	children: [
	// 		{
	// 			id: 211,
	// 			label: "Purchase Orders",
	// 			link: "/purchaseOrders",
	// 			icon: (
	// 				<FontAwesomeIcon
	// 					icon={faShop}
	// 					style={{ fontSize: "1.7rem", marginLeft: "1rem", color: "white" }}
	// 				/>
	// 			),
	// 		},
	// 		{
	// 			id: 212,
	// 			label: "Invoices",
	// 			link: "/invoiceOrders",
	// 			icon: (
	// 				<FontAwesomeIcon
	// 					icon={faFileInvoiceDollar}
	// 					style={{ fontSize: "1.7rem", marginLeft: "1rem", color: "white" }}
	// 				/>
	// 			),
	// 		},
	// 		{
	// 			id: 213,
	// 			label: "Payments",
	// 			link: "/payments",
	// 			icon: (
	// 				<FontAwesomeIcon
	// 					icon={faDollarSign}
	// 					style={{ fontSize: "1.7rem", marginLeft: "1rem", color: "white" }}
	// 				/>
	// 			),
	// 		},
	// 	],
	// },

	{
		id: `user12`,
		label: "Change Requests",
		// link: "/changeRequests",
		link: "/page/underDevelopment",
		icon: <Icons.RequestQuoteOutlined />,
		shouldHide: (profile) => hasSubscription(profile, plans.FREE_SUBSCRIPTION),
	},
	{
		id: `user13`,
		label: "Settings",
		// link: "/settings",
		link: "/page/underDevelopment",
		icon: <Icons.SettingsOutlined />,
		shouldHide: (profile) => hasSubscription(profile, plans.FREE_SUBSCRIPTION),
	},
	{
		id: `user14`,
		label: "Signout",
		link: "/login",
		icon: <Icons.LogoutOutlined />,
		onClick: () => {
			localStorage.removeItem("token");
		},
	},
];
