import React from "react";
import {
	Box,
	Typography,
	TextField,
	Button,
	Card,
	CardContent,
	InputAdornment,
	IconButton,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { SnackBarWithInfoAlert } from "components/Alerts/AlertSnackbar";
import { loginAction } from "actions/login_registrationActions";
import { getUserProfileDetails } from "selectors/appSelector";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { PASSWORD_REGEX } from "constants/appConstants";

const validationSchema = yup.object({
	currentPassword: yup
		.string("Enter Current password")
		.required("Current Password is required"),
	password: yup
		.string("Enter password")
		.required("Password is required")
		.matches(
			PASSWORD_REGEX,
			"Password must be at least 8 characters long, include at least one uppercase letter, one special character, and one number"
		)
		.notOneOf(
			[yup.ref("currentPassword")],
			"Current Password and New Password should not be same"
		),
	// .matches(
	// 	/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
	// 	"Password must be at least 8 characters long, include at least one uppercase letter, one special character, and one number"
	// ),
	confirmPassword: yup
		.string("Enter password")
		.required("Confirm password is required")
		.oneOf([yup.ref("password")], "Passwords must match"),
});
export const SettingsContent = () => {
	const dispatch = useDispatch();

	const [showToast, setShowToast] = React.useState(false);
	const [toastMessage, setToastMessage] = React.useState({});

	const userDetails = useSelector(getUserProfileDetails);

	const [showPassword, setShowPassword] = React.useState(false);

	const handleTogglePassword = () => {
		setShowPassword((prev) => !prev);
	};

	return (
		<Box className="space-y-6 p-2 pe-4">
			<SnackBarWithInfoAlert
				open={showToast}
				autoHideDuration={2000}
				alertSeverity={toastMessage.type}
				alertMessage={toastMessage.message}
				onClose={() => {
					setShowToast(false);
					localStorage.removeItem("token");
					window.location.reload();
				}}
			/>
			<Typography variant="h6" className="mb-4">
				Password
			</Typography>
			<div className="pe-3">
				<Formik
					initialValues={{ password: "", confirmPassword: "" }}
					validationSchema={validationSchema}
					onSubmit={(values) => {
						dispatch(
							loginAction(
								{
									username: userDetails.email,
									password: values.currentPassword,
								},
								(payload) => {
									setToastMessage({
										type: "success",
										message: "Password updated successfully",
									});
									setShowToast(true);

									// localStorage.removeItem("token");
								},
								{
									isSettingsPage: true,
									...userDetails,
									password: values.password,
								}
							)
						);
					}}
				>
					{({ isSubmitting }) => (
						<Form>
							<div className="align-items-center">
								<Typography variant="body1" className="text-align-start">
									<strong>Current Password</strong>
								</Typography>
								<Field
									type="password"
									name="currentPassword"
									as={TextField}
									// value={password}
									// onChange={(e) => setPassword(e.target.value)}
									helperText={<ErrorMessage name="currentPassword" />}
									error={<ErrorMessage name="currentPassword" />}
									className="form-field formField col-12"
									style={{ padding: 0, width: "100%" }}
								/>
							</div>
							<div className="d-flex flex-wrap align-items-center mt-5">
								<Typography variant="body1" className="col-5 text-align-start">
									<strong>New Password :</strong>
								</Typography>
								<Field
									type={showPassword ? "text" : "password"}
									name="password"
									as={TextField}
									// value={password}
									// onChange={(e) => setPassword(e.target.value)}
									helperText={<ErrorMessage name="password" />}
									error={<ErrorMessage name="password" />}
									className="form-field formField col-7"
									style={{ padding: 0 }}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton onClick={handleTogglePassword} edge="end">
													{showPassword ? (
														<VisibilityOff style={{ fontSize: "20px" }} />
													) : (
														<Visibility style={{ fontSize: "20px" }} />
													)}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</div>
							<div className="d-flex flex-wrap align-items-center mt-2">
								<Typography variant="body1" className="col-5 text-align-start">
									<strong>Confirm Password :</strong>
								</Typography>
								<Field
									name="confirmPassword"
									type="password"
									as={TextField}
									className="form-field formField col-7"
									style={{ padding: 0 }}
									helperText={<ErrorMessage name="confirmPassword" />}
									error={<ErrorMessage name="confirmPassword" />}
								/>
							</div>
							<div style={{ justifySelf: "center", marginTop: "1rem" }}>
								<button
									className="primaryButton"
									type="submit"
									style={{ width: "auto" }}
								>
									Update Password
								</button>
							</div>
						</Form>
					)}
				</Formik>
			</div>

			<Card className="bg-red-50">
				<CardContent>
					<Typography variant="h6" className="mb-2">
						Danger Zone
					</Typography>
					<Typography variant="body2" className="mb-4">
						Once you delete your account, there is no going back. Please be
						certain.
					</Typography>
					<Button variant="outlined" color="error">
						Delete Account
					</Button>
				</CardContent>
			</Card>
		</Box>
	);
};

export default SettingsContent;
