import React, {
	useState,
	useRef,
	useCallback,
	useEffect,
	useContext,
} from "react";
import {
	Box,
	MobileStepper,
	Typography,
	Paper,
	IconButton,
	Modal,
	InputAdornment,
} from "@mui/material";
import {
	KeyboardArrowLeft,
	KeyboardArrowRight,
	PhotoCamera,
	Close,
	Room,
	Delete,
} from "@mui/icons-material";
import { DATE_FORMAT } from "constants/appConstants";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import { useFormik } from "formik";
import {
	addUpdatePunchListAction,
	fetchPunchPriorityListAction,
	fetchPunchStatusListAction,
	fetchPunchTypeListAction,
} from "actions/punchListActions";
import {
	selectAllFiles,
	selectIsUploading,
} from "selectors/documentsSelectors";
import { getDynamicPunchOptions } from "selectors/punchListSelector";
import { getAssigneeList } from "selectors/jobSelectors";
import { isHomeOwner, isSystemAdmin } from "core/utils/roleUtils";
import { fetchFiles } from "actions/documentsActions";
import { fetchJobAssigneesListAction } from "actions/jobActions";

import FileUpload from "components/FileUpload/FileUpload";
import { startCase } from "lodash";
import CameraCapture from "core/Camera/CameraCapture";
import AppRoleContext from "context/AppRoleContext";
import { addPunchListValidationSchema } from "./addPunchListUtils";
import FormInputField from "components/TextFields/FormInputField";

const CreatePunchList_Mobile = ({
	selectedPunchItem,
	title,
	onCancel,
	projectId,
	propertyId,
	params,
}) => {
	const dispatch = useDispatch();
	const appRoleDetails = useContext(AppRoleContext);
	const punchItemId = get(selectedPunchItem, "data.punchListId", null);

	const [activeStep, setActiveStep] = useState(0);

	const { validationSchema } = mobile_add_punch_form;

	const initialState = mobile_add_punch_form.defaultState(
		get(selectedPunchItem, "data", null)
	);

	//Files Upload and Retrival
	const [filesState, setFilesState] = React.useState([]);
	const [capturedImages, setCapturedImages] = React.useState([]);
	const [fileLabelsState, setFileLabelsState] = React.useState({});
	const [documentTypeState, setDocumentTypeState] = React.useState("");

	const existingFiles = useSelector(selectAllFiles);

	const isUploading = useSelector(selectIsUploading);

	const formik = useFormik({
		initialValues: initialState,
		validationSchema: validationSchema,
		validateOnMount: false,
		validateOnChange: true,
		validateOnBlur: true,
		onSubmit: (event) => {
			const files = [
				...filesState,
				...capturedImages.map((image) => image.file),
			];
			const filesLengthIndex = filesState.length;
			const imagesLabelIndex = {};
			capturedImages.forEach(
				(image, index) =>
					(imagesLabelIndex[filesLengthIndex + index] = image.file.name)
			);
			const fileLabels = { ...fileLabelsState, ...imagesLabelIndex };
			dispatch(
				addUpdatePunchListAction({
					formData: {
						...formik.values,
						projectId: projectId,
						propertyId: propertyId,
					},
					filesData: {
						filesState: files,
						fileLabelsState: fileLabels,
						documentTypeState,
					},
					onSuccess: onCancel,
					params,
				})
			);
		},
		// initialTouched: get(selectedProject, "data", null),
	});

	const punchOptions = useSelector(getDynamicPunchOptions);

	const { assignedTo } = useSelector(getAssigneeList);

	const dynamicOptions = {
		...punchOptions,
		companyId: isSystemAdmin(appRoleDetails)
			? []
			: assignedTo.map((company) => ({
					key: company.companyId,
					text: company.companyName,
			  })),
		// companyId: assignedTo,
	};

	const fetchExistingDocs = useCallback(() => {
		if (punchItemId) {
			dispatch(fetchFiles("PunchList", punchItemId));
		}
	}, [dispatch, punchItemId]);

	useEffect(() => {
		fetchExistingDocs();
	}, [fetchExistingDocs]);

	useEffect(() => {
		dispatch(fetchPunchTypeListAction());
		dispatch(fetchPunchPriorityListAction());
		dispatch(fetchPunchStatusListAction());
		dispatch(fetchJobAssigneesListAction());
	}, [dispatch, projectId]);

	const handleBack = () => {
		setActiveStep((prevStep) => prevStep - 1);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (activeStep === 2) {
			formik.handleSubmit();
			// Touch all fields on submit to show validation errors
			const allFields = Object.values(mobile_addPunchItemFields).reduce(
				(acc, field) => ({
					...acc,
					[field.key]: true,
				}),
				{}
			);
			formik.setTouched(allFields, true);
		}
	};

	const getStepContent = (step) => {
		switch (step) {
			case 0:
			case 2:
				return (
					<RenderPunchFormFields
						step={step}
						formik={formik}
						dynamicOptions={dynamicOptions}
						onCancel={onCancel}
						projectId={projectId}
						propertyId={propertyId}
						appRoleDetails={appRoleDetails}
					/>
				);

			case 1:
				return (
					<UploadDocs
						setFilesState={setFilesState}
						filesState={filesState}
						setFileLabelsState={setFileLabelsState}
						setDocumentTypeState={setDocumentTypeState}
						capturedImages={capturedImages}
						setCapturedImages={setCapturedImages}
					/>
				);
			default:
				return null;
		}
	};
	const formRef = useRef(null);

	return (
		<Box sx={{ maxWidth: 400, margin: "0 auto" }}>
			<Paper sx={{ p: 2 }}>
				<div className="d-flex justify-content-between">
					<Typography variant="h6" gutterBottom align="center">
						New Punch List Item
					</Typography>
					<div>
						<Close onClick={onCancel} size="small" />
					</div>
				</div>
				<form
					ref={formRef}
					id="punchListForm"
					onKeyDown={(e) => {
						if (e.key === "Enter") e.preventDefault(); // Prevent "Enter" key submission
					}}
					onSubmit={handleSubmit}
				>
					{getStepContent(activeStep)}
				</form>

				<MobileStepper
					variant="dots"
					steps={3}
					position="static"
					activeStep={activeStep}
					sx={{ flexGrow: 1, mt: 2 }}
					nextButton={
						activeStep === 2 ? (
							<button
								onClick={() => formRef.current?.requestSubmit()}
								className="primaryButton"
								size="small"
								type="submit"
							>
								Add Item
							</button>
						) : (
							<button
								className="primaryButton"
								type="button"
								onClick={() => {
									setActiveStep((prevStep) => prevStep + 1);
								}}
								form="punchListForm" // Connect to form by id
							>
								Next
								<KeyboardArrowRight />
							</button>
						)
					}
					backButton={
						<button
							size="small"
							type="button"
							className="outlinedButton"
							onClick={handleBack}
							disabled={activeStep === 0}
						>
							<KeyboardArrowLeft />
							Back
						</button>
					}
				/>
			</Paper>
		</Box>
	);
};

const UploadDocs = ({
	setFilesState,
	capturedImages,
	setCapturedImages,
	setFileLabelsState,
	setDocumentTypeState,
	filesState,
}) => {
	const [isCameraOpen, setIsCameraOpen] = useState(false);

	const handleOpenCamera = () => {
		setIsCameraOpen(true);
	};

	const handleCloseCamera = () => {
		setIsCameraOpen(false);
	};

	return (
		<>
			<Modal
				open={isCameraOpen}
				onClose={handleCloseCamera}
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<CameraCapture
					onClose={handleCloseCamera}
					onImageCapture={(imagePayload) => {
						setCapturedImages((prev) => [...prev, imagePayload]);
					}}
				/>
			</Modal>

			<Box sx={{ p: 2 }}>
				<Box sx={{ p: 2 }}>
					<Paper
						variant="outlined"
						sx={{
							p: 2,
							mb: 2,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<IconButton
							color="primary"
							onClick={handleOpenCamera}
							aria-label="take picture"
						>
							<PhotoCamera />
						</IconButton>
						<Typography variant="body2">Take Photo</Typography>
					</Paper>
				</Box>

				<FileUpload
					setFilesState={setFilesState}
					setFileLabelsState={setFileLabelsState}
					setDocumentTypeState={setDocumentTypeState}
					filesState={filesState}
				/>

				{capturedImages.length > 0 && (
					<Box sx={{ mt: 2 }}>
						<Typography variant="subtitle2" gutterBottom>
							Captured Images:
						</Typography>
						{capturedImages.map((fileObj, index) => (
							<Box key={index}>
								<Typography variant="body2">
									{fileObj.file.name}{" "}
									<Delete
										className="pointer fs-5"
										size="small"
										onClick={() => {
											setCapturedImages((prev) =>
												prev.filter((_, i) => i !== index)
											);
										}}
									/>
								</Typography>
								<img
									src={fileObj.imagePreviewUrl}
									alt={fileObj.file.name}
									style={{ maxWidth: "100%", marginTop: "8px" }}
								/>
							</Box>
						))}
					</Box>
				)}
			</Box>
		</>
	);
};

const RenderPunchFormFields = ({
	step,
	formik,
	dynamicOptions,
	onCancel,
	projectId,
	propertyId,
	appRoleDetails,
}) => {
	return (
		<>
			<div id="container row">
				<div id="section-1 mt-3">
					<div className="form-fields col-12 ">
						<div className="container row justify-content-between">
							{mobile_addPunchItemFields[`step${step}`].map((field) => {
								const extraInputProps = get(field, "extraInputProps", {});

								const extraProps =
									typeof extraInputProps === "function"
										? extraInputProps(formik)
										: extraInputProps;

								return (
									<FormInputField
										field={{
											...field,
											options: dynamicOptions[field.key] || field.options,
										}}
										formik={formik}
										value={formik.values[field.key]}
										key={field.key}
										id={field.id}
										appRoleDetails={appRoleDetails}
										dynamicOptions={dynamicOptions}
										{...extraProps}
									/>
								);
							})}
						</div>
					</div>
				</div>
				<div>
					<Typography variant="body1" className="mt-1 ">
						<sup style={{ color: "red", fontSize: "1em", top: 0 }}>*</sup>
						<strong>Required Fields</strong>
					</Typography>
					{Object.keys(formik.errors).length > 0 && (
						<Typography variant="body1" className="mt-1 ">
							<span style={{ fontSize: "12px", color: "red" }}>
								{`Please fill the Required fields - ${Object.keys(
									formik.errors
								).map((key) => startCase(key))}`}{" "}
							</span>
						</Typography>
					)}
				</div>
			</div>
		</>
	);
};

const mobile_addPunchItemFields = {
	step0: [
		{
			key: "punchListTypeId",
			label: "Type",
			placeholder: "Select Type",
			type: "dynamicField",
			value: "",
			select: true,
			options: [""],
			width: "100%",
		},
		{
			key: "location",
			label: "Location",
			placeholder: "Enter Location of Punch",
			type: "text",
			required: true,
			width: "100%",
			extraInputProps: {
				InputProps: {
					startAdornment: (
						<InputAdornment position="start">
							<Room style={{ fontSize: "16px" }} />
						</InputAdornment>
					),
				},
			},
		},
		{
			key: "punchListStatusTypeId",
			label: "Status",
			placeholder: "Select Status",
			type: "select",
			width: "100%",
			select: true,
			required: true,
			options: [""],
		},
		{
			key: "dueDate",
			placeholder: DATE_FORMAT,
			label: "Due Date",
			type: "dateField",
			required: true,
			width: "100%",
		},

		{
			key: "punchListPriorityId",
			label: "Priority",
			placeholder: "Select Priority",
			type: "select",
			select: true,
			options: ["New Build", "Renovation", "Remodel", "Tear Down Rebuild"],
			required: true,
			width: "100%",
		},
		{
			key: "companyId",
			label: "Assigned To",
			type: "select",
			dynamicField: true,
			select: true,
			options: [],
			required: true,
			width: "100%",
			shouldHide: ({ appRoleDetails }) => {
				return isHomeOwner(appRoleDetails);
			},
		},
		{
			key: "assignedTeamMember",
			label: "Assigned Team Member",
			type: "text",
			width: "100%",
			shouldHide: ({ appRoleDetails }) => isHomeOwner(appRoleDetails),
		},
	],
	step1: [],
	step2: [
		{
			key: "title",
			label: "Title",
			placeholder: "Punch List Name",
			type: "text",
			width: "100%",
		},
		{
			key: "scheduleImpact",
			label: "Schedule Impact",
			placeholder: "Enter Schedule impact details here",
			type: "text",
			width: "50%",
		},
		{
			key: "costImpact",
			placeholder: "Enter Cost impact details here",
			label: "Cost Impact",
			type: "text",
			width: "50%",
		},
		{
			key: "description",
			placeholder: "Enter Punch Description here",
			label: "Description",
			type: "textArea",
			width: "100%",
			extraInputProps: {
				multiline: true,
				className:
					"registration-formField project-notes property-details-textarea",
			},
		},
	],
};

const mobile_add_punch_form = {
	validationSchema: addPunchListValidationSchema,
	defaultState: (defaultState) => {
		const initialState = Object.fromEntries(
			Object.keys(addPunchListValidationSchema.fields).map((key) => {
				return [key, ""];
			})
		);
		if (defaultState) {
			return { ...initialState, ...defaultState };
		}
		return {
			...initialState,
		};
	},
};

export default CreatePunchList_Mobile;
